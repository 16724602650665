export interface HeaderItems {
  logo?: boolean,
  login?: boolean,
  search?: boolean,
  searchOpen?: boolean,
  bag?: boolean,
  user?: boolean,
  help?: boolean,
  back?: boolean,
  send?: boolean,
  prescribe?: boolean,
  prescribeLabel?: string,
  history?: boolean,
  showMenu?: boolean,
  menuItems?: MenuItems,
  home?: boolean,
  navList?: NavList,
  menuBar?: boolean,
  title?: string,
  titleSup?: string,
  subTitle?: string,
  bagTemplate?: any,
  info?: boolean,
  ecrfNext?: boolean,
  complete?: boolean,
  iqviaLogo?:boolean
}

interface MenuItems {
  formulary?: boolean,
  help?: boolean,
  signOut?: boolean,
  changePassword?: boolean,
  about?: boolean,
  passwordExpiry?: string
}

interface NavList {
  home?: {
    active: boolean
  },
  history?: {
    active: boolean
  },
  bag?: {
    active: boolean
  }
}

export enum HeaderActions {
  logIn = "logIn",
  logOut = "logOut",
  navigateToHome = "navigateToHome",
  navigateToHistory = "navigateToHistory",
  navigateToSearch = "navigateToSearch",
  aboutModal = "aboutModal",
  back = "back",
  changePasswordModel = "changePasswordModel",
  navigateToPrescribe = "navigateToPrescribe",
  sendToPatient = "sendToPatient",
  prescribe = "prescribe",
  infoModal = "infoModal",
  postToEcrfFrame = 'postToEcrfFrame'
};
