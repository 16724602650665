<div id="register">
  <section id="section-0">

    <div class="row">
      <div class="col-lg-12 deliver">
        <h4>Discover, Deliver & Track the most effective Patient Engagement Tools</h4>
      </div>
    </div>
  </section>
  <section id="section-1">
    <div>
      <div class="row">
        <div class="col-xl-6 col-lg-12 preview-img">
          <img class="img-responsive" [src]="'/assets/images/AppScript_preview_right.png' | cdnurl"  alt="panel3" />
        </div>
        <div class="col-xl-6 col-lg-12 register-form">
          <h3>GET STARTED TODAY</h3>
          <a name="top"></a>
          <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="form-group">
              <div class="error" *ngIf="ShowErrorMessage"> <strong><span>Error! </span></strong>{{ErrorMessage}}</div>
            </div>
            <div class="row">
              <div class="col-md-6 col-sm-12">
                <div class="form-group">
                  <label for="first">First Name <span class="required">*</span></label>
                  <input type="text" placeholder="John" formControlName="firstName" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" />
                  <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                    <div *ngIf="f.firstName.errors.required">First Name is required</div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="form-group">
                  <label for="last">Last Name <span class="required">*</span></label>
                  <input type="text" placeholder="Cena" formControlName="lastName" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" />
                  <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                    <div *ngIf="f.lastName.errors.required">Last Name is required</div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="form-group">
                  <label for="email">Email <span class="required">*</span></label>
                  <input type="email" placeholder="john@yahoo.com" formControlName="email" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                  <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                    <div *ngIf="f.email.errors.required">Email is required</div>
                    <div *ngIf="f.email.errors.pattern">Enter a valid email.</div>
                    <div *ngIf="f.email.errors.restrictedDomain">Sorry, you can’t register with any IQVIA Email address, please
                      <a [href]="ssolink" target='_blank' data-toggle='tooltip' title="{{ssoLink}}">click here</a>
                      to Single Sign On to the IQVIA Digital Health Library
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="form-group">
                  <label for="password">Password <span class="required">*</span></label>
                  <input type="password" placeholder="**********" formControlName="password" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                  <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                    <div *ngIf="f.password.errors.required">Password is required</div>
                    <div *ngIf="f.password.errors.pattern">Your password must contain 8 characters at least (1)
                      lowercase (1) uppercase letter (1) special character and (1) number.</div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="form-group">
                  <div class="input-group dropdown-Container">
                    <div ngbDropdown class="d-inline-block">
                      <button type="button" class="form-control countrydropdown" id="countrylistdropdown"
                        ngbDropdownToggle>{{ countryLabel[countrycode] }}
                        <i class="fas fa-angle-down"></i>
                      </button>
                      <div ngbDropdownMenu aria-labelledby="countrylistdropdown">
                        <button type="button" class="countrylist" value="{{region.Code}}" (click)="regionlist($event)"
                          ngbDropdownItem *ngFor="let region of regions">{{region.Country}}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 text-center">
                <div class="form-group">
                  <div class="checkbox">
                    <label>
                      <input type="checkbox" formControlName="tos" id="tos"
                        [ngClass]="{ 'is-invalid': submitted && f.tos.errors }" /> <span>&nbsp; </span>
                      I agree to AppScript's <a id="register-tos-link" (click)="termsofservice()">Terms of Service</a>
                      and the <a id="register-privacy-link" (click)="privaypolicy()">Privacy Policy</a>
                      <div *ngIf="submitted && f.tos.errors" class="invalid-feedback">
                        <div *ngIf="f.tos.errors.pattern">Please agree to the Terms of Service & Privacy Policy.</div>
                      </div>
                    </label>
                    <app-tos #tos></app-tos>
                    <app-privacy-policy #privacypolicy></app-privacy-policy>
                  </div>
                </div>
                <div class="form-group">
                  <button type="submit" class="btn btn-primary">SUBMIT</button>
                </div>
                <div class="form-group">
                  <label>
                    Do you have an account? <a (click)="logIn()"> Log In</a>
                  </label>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
  <section id="section-5">
      <div class="row">
            <div class="col-md-7 col-xs-12 preview-img">
              <img class="img-responsive" [src]="'/assets/images/report_3pages.jpg' | cdnurl" loading="lazy" alt="panel3" />
            </div>

        <div class="col-md-5 col-xs-12 ">
          <div class="vertical-center">
            <h4>The new report leveraging AppScript's data has been published by IQVIA Institute
            </h4>
            <a href="https://www.iqvia.com/insights/the-iqvia-institute/reports/digital-health-trends-2021" value="get report"><button type="button" class="btn btn-primary">Get Report</button></a>

          </div>
        </div>
      </div>
  </section>


  <section id="section-2">
    <div class="">
      <div class="row">
        <div class="col-md-6 col-xs-12">
          <div class="vertical-center">
            <img class="" [src]="'/assets/images/Discover_icon.svg'| cdnurl" loading="lazy" alt="panel2" /><br />
            <b> Discover</b>
            <h4>Discover the value of Apps, Connected Devices, & Educational Content for your patients
              <a href="https://www.appscript.net/score-details" value="Learn More">Learn More</a>
              about our AppScript Score
            </h4>
          </div>
        </div>
        <div class="col-md-6 col-xs-12 h4">
          <img class=" img-responsive" [src]="'/assets/images/donut-chart.jpg' | cdnurl" loading="lazy" alt="panel2" />
        </div>
      </div>
    </div>
  </section>

  <section id="section-3">
    <div class="">
      <div class="row">
        <div class="col-md-6 order-2 order-md-1">
          <img class="img-responsive deliver-hand" [src]="'/assets/images/AppScript_Deliver_hand.jpg' | cdnurl" loading="lazy"
            alt="panel3" />
        </div>
        <div class="col-md-6 order-1 order-md-2">
          <div class="vertical-center">
            <img class="" [src]="'/assets/images/Deliver_icon.svg'| cdnurl" alt="panel2" loading="lazy" /><br />
            <b>Deliver</b>
            <h4>Deliver tools for a better Patient Experience & Outcomes</h4>
          </div>
        </div>

      </div>
    </div>
  </section>

  <section id="section-4">
    <div class="">
      <div class="row">
        <div class="col-md-6 col-xs-12">
          <div class="vertical-center">
            <img class="" [src]="'/assets/images/Track_icon.svg'| cdnurl" loading="lazy" alt="panel2" /><br />
            <b>Track</b>
            <h4>Track Patient Engagement & Feedback automatically</h4>
          </div>
        </div>
        <div class="col-md-6 col-xs-12">
          <img class="img-responsive" [src]="'/assets/images/AppScript_Track.jpg' | cdnurl" loading="lazy" alt="panel4" />
        </div>
      </div>
    </div>
  </section>

</div>
