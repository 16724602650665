import { Action, createReducer, on } from '@ngrx/store';
import { PrescribeActions } from '../actions/prescribe.actions';


export const prescribeFeatureKey = 'prescribe';
export const maxItemsAllowedInBag = 9;

export interface PrescribeState {
  itemsInBag,
  totalCount,
  attemptedCount,
  labelForPrescribe
}

export const prescribeInitialState: PrescribeState = {
  itemsInBag: null,
  totalCount: 0,
  attemptedCount: 0,
  labelForPrescribe: 'Prescribe'
};

export const prescribeReducer = createReducer(
  prescribeInitialState,
  on(PrescribeActions.addToBag, (state, action) => {
    if (dataExists(state.itemsInBag, action.item)) {
      return { ...state };
    } else {
      if (state.itemsInBag && state.itemsInBag.length === maxItemsAllowedInBag) {
        return {
          ...state,
          itemsInBag: [...state.itemsInBag],
          attemptedCount: state.attemptedCount + 1
        };
      } else {
        return {
          ...state,
          itemsInBag: state.itemsInBag ? [...state.itemsInBag, action.item] : [action.item],
          attemptedCount: state.itemsInBag ? state.itemsInBag.length + 1 : 1
        };
      }
    }
  }),
  on(PrescribeActions.clearBag, (state, action) => {
    return {
      ...state,
      ...prescribeInitialState
    };
  }),
  on(PrescribeActions.removeItem, (state, action) => {
    const items = removeItem(state.itemsInBag, action.id)
    return {
      ...state,
      itemsInBag: items,
      attemptedCount: items.length
    };
  }),
  on(PrescribeActions.setPrescribeLabel, (state, action) => {
    return {
      ...state,
      labelForPrescribe: action.label
    };
  })
);

const dataExists = (existingData, data) => {
  return existingData && existingData.some(existing => existing.id === data.id);
}

const removeItem = (items, id) => {
  return items.filter(item => item.id !== id);
}

