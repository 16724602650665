import { Action, createReducer, on } from '@ngrx/store';
import { ContentActions } from '../actions/content.actions';


export const contentFeatureKey = 'content';

export interface ContentState {
  featuredContent,
  favoriteContent,
  remainingIds,
  selectedIds,
  recommendedContent
}

export const contentInitialState: ContentState = {
  featuredContent: null,
  favoriteContent: null,
  remainingIds: null,
  selectedIds: null,
  recommendedContent: null
};


export const contentReducer = createReducer(
  contentInitialState,
  on(ContentActions.featuredContentLoaded, (state, action) => {
    return {
      ...state,
      featuredContent: action.featuredContent
    }
  }),
  on(ContentActions.favoriteContentLoaded, (state, action) => {
    return {
      ...state,
      favoriteContent: action.favoriteContent
    }
  }),
  on(ContentActions.remainingIds, (state, action) => {
    return {
      ...state,
      remainingIds: action.remainingIds
    }
  }),
  on(ContentActions.selectedIds, (state, action) => {
    return {
      ...state,
      remainingIds: state.selectedIds ? [...state.selectedIds, ...action.selectedIds] : [...action.selectedIds]
    }
  }),
  on(ContentActions.recommendedContentLoaded, (state, action) => {
    return {
      ...state,
      recommendedContent: [...action.recommendedContentGroup]
    }
  }),
  on(ContentActions.moreRecommendedContentLoaded, (state, action) => {
    return {
      ...state,
      recommendedContent: action.moreRecommendedContentGroup ? [...state.recommendedContent, ...action.moreRecommendedContentGroup] : [...state.recommendedContent]
    }
  }),
  on(ContentActions.addFavorite, (state, action) => {
    return {
      ...state,
      favoriteContent: [...state.favoriteContent, action.content]
    }
  }),
  on(ContentActions.removeFavorite, (state, action) => {
    return {
      ...state,
      favoriteContent: state.favoriteContent.filter(content => content.id !== action.content.id)
    }
  }),
  on(ContentActions.resetContent, (state, action) => {
    return {
      ...state,
      featuredContent: null,
      favoriteContent: null,
      recommendedContent: null
    }
  })
);

