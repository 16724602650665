import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../core/services/auth.service';
import { HeaderActions, HeaderItems } from '../../shared/models/header-items';

@Component({
  selector: 'app-footer-header',
  templateUrl: './footer-header.component.html',
  styleUrls: ['./footer-header.component.scss']
})
export class FooterHeaderComponent implements OnInit {

  isLoggedIn!: boolean;
  headerItems: HeaderItems = {
    back: true,
    title: 'About AppScript'
  };
  constructor(private authService: AuthService, private location: Location) { }

  ngOnInit(): void {
    this.isLoggedIn = this.authService.isLoggedIn;
  }

  back() {
    const bodyElement = document.body;
    this.location.back();
  }

  handleUserAction(event) {
    if (HeaderActions[event]) {
      this[HeaderActions[event]]();
    }
  }
}
