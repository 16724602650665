import { createAction, props } from '@ngrx/store';

export const loadFeaturedContent = createAction(
  '[Dashboard Content] Load Featured Content'
);

export const featuredContentLoaded = createAction(
  '[Dashboard Content] Featured Content Loaded',
  props<{ featuredContent: Array<any> }>()
);

export const loadFavoriteContent = createAction(
  '[Dashboard Content] Load Favorite Content'
);

export const favoriteContentLoaded = createAction(
  '[Dashboard Content] Favorite Content Loaded',
  props<{ favoriteContent: Array<any> }>()
);

export const remainingIds = createAction(
  '[Dashboard Content] Remaining Ids',
  props<{ remainingIds: Array<any> }>()
);

export const selectedIds = createAction(
  '[Dashboard Content] Selected Ids',
  props<{ selectedIds: Array<any> }>()
);

export const loadRecommendedContent = createAction(
  '[Dashboard Content] Load Recommended Content',
  props<{ count: number }>()
);

export const loadMoreRecommendedContent = createAction(
  '[Dashboard Content] Load More Recommended Content',
  props<{ count: number }>()
);

export const recommendedContentLoaded = createAction(
  '[Dashboard Content] Recommended Content Loaded',
  props<{ recommendedContentGroup: Array<any> }>()
);

export const moreRecommendedContentLoaded = createAction(
  '[Dashboard Content] More Recommended Content Loaded',
  props<{ moreRecommendedContentGroup: Array<any> }>()
);

export const addFavorite = createAction(
  '[Content Details] Add to Favorite',
  props<{ content: any }>()
);

export const removeFavorite = createAction(
  '[Content Details] Remove Favorite',
  props<{ content: any }>()
);

export const resetContent = createAction(
  '[Prescribe] Reset Content'
);

export const ContentActions = {
  loadFeaturedContent, featuredContentLoaded, loadFavoriteContent, favoriteContentLoaded, remainingIds, selectedIds,
  loadRecommendedContent, loadMoreRecommendedContent, recommendedContentLoaded, moreRecommendedContentLoaded, addFavorite, removeFavorite, resetContent
}




