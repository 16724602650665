import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '../../core/services/config.service';

@Injectable({
  providedIn: 'root'
})
export class ContentService {

  private contentUrl = 'content';

  constructor(private http: HttpClient, private configService: ConfigService) { }

  getFeaturedContent(patientId?) {
    let params = new HttpParams();
    if (patientId) {
      params = params.append('patientId', patientId);
    }
    return this.http.get(this.configService.appconfig.apiurl + this.configService.getFacilityUrl() + this.contentUrl + '/featured', {
      params
    });
  }

  getFavoriteContent(patientId?) {
    let params = new HttpParams();
    if (patientId) {
      params = params.append('patientId', patientId);
    }
    return this.http.get(this.configService.appconfig.apiurl + this.configService.getFacilityUrl() + this.contentUrl + '/favorites', {
      params
    });
  }

  getRecommendedCategories(ids, patientId?) {
    let params = new HttpParams();
    ids.forEach(id => {
      params = params.append('categoryId', id.id.toString())
    });
    if (patientId) {
      params = params.append('patientId', patientId);
    }
    return this.http.get(this.configService.appconfig.apiurl + this.configService.getFacilityUrl() + this.contentUrl + '/recommendedCategories', {
      params: params
    });
  }
}
