import { createAction, props } from '@ngrx/store';

export const appFilterState = createAction(
  '[Dashboard - Apps] Apps Filter State',
  props<{ appFilter: any }>()
);

export const deviceFilterState = createAction(
  '[Dashboard - Devices] Devices Filter State',
  props<{ deviceFilter: any }>()
);

export const contentFilterState = createAction(
  '[Dashboard - Content] Content Filter State',
  props<{ contentFilter: any }>()
);

export const showFilter = createAction(
  '[Filter in Slider] ShowFilter State',
  props<{ showFilter: boolean }>()
);

export const setInitialState = createAction(
  '[Search Header] Set Initial State'
);

export const updateCategory = createAction(
  '[Search Box] Update Category Id',
  props<{ category: { categoryId?: number, searchQuery?: string,  type: string} }>()
)

export const FilterActions = { appFilterState, deviceFilterState, contentFilterState, showFilter, setInitialState, updateCategory }




