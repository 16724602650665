import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-emis-accepted-tos',
  templateUrl: './emis-accepted-tos.component.html',
  styleUrls: ['./emis-accepted-tos.component.scss']
})
export class EmisAcceptedTosComponent implements OnInit, AfterViewInit {
  @ViewChild('emisTOS') private accepetedEmisTOS;
  constructor() { }

  ngOnInit(): void {
    
  }

  ngAfterViewInit(){
    this.termsofservice();
  }

  termsofservice(){
    this.accepetedEmisTOS.open()
  }

}
