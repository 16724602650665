import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbAlertModule, NgbCollapseModule, NgbDropdownModule, NgbToastModule, NgbTypeaheadModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap'
import { AlertComponent } from './components/alert/alert.component';
import { PaginatorPipe } from './pipes/paginator.pipe';
import { TileComponent } from './components/tile/tile.component';
import { ModalComponent } from './components/modal/modal.component';
import { RatingComponent } from './components/rating/rating.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { PatientListComponent } from './components/patient-list/patient-list.component';
import { OrderByPipe } from './pipes/order-by.pipe';
import { SideBarComponent } from './components/side-bar/side-bar.component';
import { HeaderComponent } from './components/header/header.component';
import { SlideOverComponent } from './components/slide-over/slide-over.component';
import { SubHeaderComponent } from './components/sub-header/sub-header.component';
import { AboutAppComponent } from './components/about-app/about-app.component';
import { FilterContainerComponent } from './components/filter-container/filter-container.component';
import { TosComponent } from './components/tos/tos.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { TransformTileDataPipe } from './pipes/transform-tile-data.pipe';
import { AboutModalComponent } from './components/about-modal/about-modal.component';
import { BagComponent } from './components/bag/bag.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastComponent } from './components/toast/toast.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { PasswordStrengthComponent } from './components/password-strength/password-strength.component';
import { SearchBoxComponent } from './components/search-box/search-box.component';
import { TimeAgoPipe } from './pipes/time-ago.pipe';
import { SortTableDirective } from './directives/sort-table.directive';
import { PatientTableComponent } from './components/patient-table/patient-table.component';
import { NumbersOnlyDirective } from './directives/numbers-only.directive';
import { BasicPaginatorComponent } from './components/basic-paginator/basic-paginator.component';
import { PanelComponent } from './components/panel/panel.component';
import { CollapsiblePanelComponent } from './components/collapsible-panel/collapsible-panel.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { AgePipe } from './pipes/age.pipe';
import { TabContentComponent } from './components/tab-content/tab-content.component';
import { TabItemComponent } from './components/tab-item/tab-item.component';
import { TabTitleComponent } from './components/tab-title/tab-title.component';
import { StudyBrochureComponent } from './components/study-brochure/study-brochure.component';
import { CopyCodeComponent } from './components/copy-code/copy-code.component';
import { CopyClipboardDirective } from './directives/copy-clipboard.directive';
import { StatusTrackerComponent } from './components/status-tracker/status-tracker.component';
import { SortByPipe } from './pipes/sort-by.pipe';
import { InfoModalComponent } from './components/info-modal/info-modal.component';
import { ExternalLinkComponent } from './components/external-link/external-link.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { CoachMarksComponent } from './components/coach-marks/coach-marks.component';
import { StudyInviteComponent } from './components/study-invite/study-invite.component';
import { ParterapiInstructionsModalComponent } from './components/parterapi-instructions-modal/parterapi-instructions-modal.component';
import { EcrfModalComponent } from './components/ecrf-modal/ecrf-modal.component';
import { SplicePipe } from './pipes/splice.pipe';
import { EmisAcceptedTosComponent } from './components/emis-accepted-tos/emis-accepted-tos.component';
import { AcceptedTosComponent } from './components/accepted-tos/accepted-tos.component';
import { CdnurlPipe } from './pipes/cdnurl.pipe';
import { NewUserTosComponent } from './components/new-user-tos/new-user-tos.component';
import { StagesComponent } from './components/stages/stages.component';
import { FirstRunModalComponent } from './components/first-run-modal/first-run-modal.component';
import { PartnerApiWarningComponent } from './components/partner-api-warning/partner-api-warning.component';
import { ScoreIndicatorComponent } from './components/score-indicator/score-indicator.component';
import { ImageValidatorDirective } from './directives/image-validator.directive';
import { DistributionChartComponent } from './components/distribution-chart/distribution-chart.component';
import { BarChartComponent } from './components/bar-chart/bar-chart.component';
import { CustomDatepickerComponent } from './components/custom-datepicker/custom-datepicker.component';


@NgModule({
  declarations: [
    AlertComponent,
    PaginatorPipe,
    TileComponent,
    ModalComponent,
    RatingComponent,
    PaginatorComponent,
    PatientListComponent,
    OrderByPipe,
    SideBarComponent,
    HeaderComponent,
    SlideOverComponent,
    SubHeaderComponent,
    AboutAppComponent,
    FilterContainerComponent,
    TosComponent,
    PrivacyPolicyComponent,
    AboutModalComponent,
    TransformTileDataPipe,
    BagComponent,
    ForgotPasswordComponent,
    ToastComponent,
    ChangePasswordComponent,
    PasswordStrengthComponent,
    SearchBoxComponent,
    TimeAgoPipe,
    SortTableDirective,
    PatientTableComponent,
    NumbersOnlyDirective,
    BasicPaginatorComponent,
    PanelComponent,
    CollapsiblePanelComponent,
    TabsComponent,
    AgePipe,
    TabContentComponent,
    TabItemComponent,
    TabTitleComponent,
    StudyBrochureComponent,
    CopyCodeComponent,
    CopyClipboardDirective,
    StatusTrackerComponent,
    SortByPipe,
    InfoModalComponent,
    ExternalLinkComponent,
    SafeHtmlPipe,
    StudyInviteComponent,
    CoachMarksComponent,
    ParterapiInstructionsModalComponent,
    EcrfModalComponent,
    SplicePipe,
    EmisAcceptedTosComponent,
    AcceptedTosComponent,
    CdnurlPipe,
    NewUserTosComponent,
    ScoreIndicatorComponent,
    FirstRunModalComponent,
    PartnerApiWarningComponent,
    StagesComponent,
    ImageValidatorDirective,
    DistributionChartComponent,
    BarChartComponent,
    CustomDatepickerComponent,
  ],
  imports: [
    CommonModule,
    NgbAlertModule,
    NgbCollapseModule,
    NgbDropdownModule,
    ReactiveFormsModule,
    NgbToastModule,
    FormsModule,
    NgbTypeaheadModule,
    NgbTooltipModule
  ],
  exports: [
    AlertComponent,
    PaginatorPipe,
    TileComponent,
    SideBarComponent,
    HeaderComponent,
    SlideOverComponent,
    SubHeaderComponent,
    ModalComponent,
    AboutAppComponent,
    FilterContainerComponent,
    TosComponent,
    PrivacyPolicyComponent,
    AboutModalComponent,
    TransformTileDataPipe,
    BagComponent,
    ForgotPasswordComponent,
    ToastComponent,
    ChangePasswordComponent,
    PasswordStrengthComponent,
    InfoModalComponent,
    NgbDropdownModule,
    TimeAgoPipe,
    SortTableDirective,
    PatientTableComponent,
    PatientListComponent,
    PaginatorComponent,
    NumbersOnlyDirective,
    BasicPaginatorComponent,
    PanelComponent,
    CollapsiblePanelComponent,
    AgePipe,
    TabsComponent,
    TabContentComponent,
    TabItemComponent,
    TabTitleComponent,
    StudyBrochureComponent,
    CopyCodeComponent,
    StatusTrackerComponent,
    SortByPipe,
    ExternalLinkComponent,
    StudyInviteComponent,
    CoachMarksComponent,
    ParterapiInstructionsModalComponent,
    SplicePipe,
    CdnurlPipe,
    NewUserTosComponent,
    ScoreIndicatorComponent,
    PartnerApiWarningComponent,
    StagesComponent,
    ImageValidatorDirective,
    DistributionChartComponent,
    SafeHtmlPipe,
    BarChartComponent,
    CustomDatepickerComponent
  ],
  entryComponents: [ModalComponent]
})
export class SharedModule { }
