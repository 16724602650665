import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { AuthService } from "./auth.service";
import { ConfigService } from "./config.service";

@Injectable()
export class HttpserviceInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private configService: ConfigService) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let requestHeaders: HttpHeaders = new HttpHeaders();
    const authToken = this.authService.IsJsonString(this.authService.getToken()) ? JSON.parse(this.authService.getToken()) : sessionStorage.clear();
    let region: string = sessionStorage.getItem('countrycode');
    let currentHeaders: HttpHeaders = req.headers;

    if (authToken) {
      requestHeaders = requestHeaders.append('Authorization', `Bearer ${authToken.value}`);
    }
    if (!currentHeaders.has('Content-Type')) {
      requestHeaders = requestHeaders.append('Content-Type', 'application/json');
    }
    if (!region) {
      region = this.configService.getRegion() ?? 'US';
    }
    requestHeaders = requestHeaders.append('X-CountryCode', region);

    if (currentHeaders) {
      currentHeaders.keys().forEach((item: string) => {
        if (item === 'noCountryCode') {
          requestHeaders = requestHeaders.delete('X-CountryCode');
        } else if (item === 'Authorization') {
          requestHeaders = requestHeaders.delete('Authorization');
          let currentHeaderValue: any = currentHeaders.get(item) ? currentHeaders.get(item) : '';
          requestHeaders = requestHeaders.append(item, currentHeaderValue);
        } else if (!requestHeaders.has(item)) {
          let currentHeaderValue: any = currentHeaders.get(item) ? currentHeaders.get(item) : '';
          requestHeaders = requestHeaders.append(item, currentHeaderValue);
        }
      });
    }

    req = req.clone({ headers: requestHeaders });

    return next.handle(req);
  }
}


