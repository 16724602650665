import { Action, createReducer, on } from '@ngrx/store';
import { SearchActions } from '../actions/search.actions';


export const searchFeatureKey = 'search';

export interface SearchState {
  searchConfig,
  sortOption
}

export const searchInitialState: SearchState = {
  searchConfig: {
    backUrl: "dashboard/apps",
    title: "Apps",
    type: "apps",
    categoryType: 'Apps Categories > ',
    searchQuery: ''
  },
  sortOption: '0'
};

export const searchReducer = createReducer(
  searchInitialState,
  on(SearchActions.setSortOption, (state, action) => {
    return {
      ...state,
      sortOption: action.sortOption
    }
  }),
  on(SearchActions.appSearchConfig, (state, action) => {
    return {
      ...state,
      searchConfig: {
        type: 'apps',
        title: 'Apps',
        backUrl: 'dashboard/apps',
        categoryType: 'Apps Categories > ',
        searchQuery: ''
      }
    }
  }),
  on(SearchActions.deviceSearchConfig, (state, action) => {
    return {
      ...state,
      searchConfig: {
        type: 'devices',
        title: 'Devices',
        backUrl: 'dashboard/devices',
        categoryType: 'Devices Categories > ',
        searchQuery: ''
      }
    }
  }),
  on(SearchActions.contentSearchConfig, (state, action) => {
    return {
      ...state,
      searchConfig: {
        type: 'content',
        title: 'Digital Content',
        backUrl: 'dashboard/content',
        categoryType: 'Content Categories > ',
        searchQuery: ''
      }
    }
  }),
  on(SearchActions.setSearcQuery, (state, action) => {
    return {
      ...state,
      searchConfig: { ...state.searchConfig, searchQuery: action.searchQuery }
    }
  })
);

