import { Component, OnDestroy } from '@angular/core';
import { HeaderItems } from '../../shared/models/header-items';
import { HeaderHelperService } from '../../shared/services/header-helper.service';
import { Subject } from 'rxjs';
import { Location } from '@angular/common';

@Component({
  selector: 'app-partner-api-header',
  templateUrl: './partner-api-header.component.html',
  styleUrls: ['./partner-api-header.component.scss']
})
export class PartnerApiHeaderComponent implements OnDestroy {
  headerItems: HeaderItems = {
    back: true,
    title: 'Partner API instruction',
    ecrfNext: false
  }
  private readonly destroy$ = new Subject;
  constructor(public headerHelperService: HeaderHelperService, private location: Location) {
    
  }
 
  handleUserAction(event) {
    if (event === 'back') {
     
      this.location.back();
    } else {
      this.headerHelperService.handleUserAction(event);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
