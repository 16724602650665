import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  GroupTitle,
  GroupType,
} from '../../modules/dashboard/models/dashboard.model';
import { PrescribeActions } from '../../store/actions/prescribe.actions';
import { TileType, TileData } from '../models/tile-data';
import { ConfigService } from '../../core/services/config.service';
import { UserDataService } from '../../core/services/user-data.service';
import { showExtLink } from '../../store/actions/app-data.actions';
import { environment } from 'projects/prescriber/src/environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FirstRunModalComponent } from '../components/first-run-modal/first-run-modal.component';
import { AppsService } from './apps.service';
import { error } from 'console';
import { AppDataService } from '../../core/services/app-data.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DashboardHelperService {
  isEmis: any;
  private readonly destroy$ = new Subject();
  isSmartlaunch: any;
  constructor(
    private userservice: UserDataService,
    private router: Router,
    private store: Store,
    private config: ConfigService,
    private modalService: NgbModal,
    private appsService: AppsService,
    private appdataservice: AppDataService
  ) {
    this.appdataservice.isEmis().pipe(
      takeUntil(this.destroy$)
    ).subscribe(data => {
      if (data) {
        this.isEmis = data;
      }
    });
    this.appdataservice.isSmartlaunch().pipe(
      takeUntil(this.destroy$)
    ).subscribe(data => {
      if (data) {
        this.isSmartlaunch = data;
      }
    });
  }

  featuredGroup: GroupTitle = {
    type: GroupType.featured,
    longDescription: true,
    viewAll: true,
  };
  favoriteGroup: GroupTitle = {
    type: GroupType.favorites,
  };
  recommendedGroup: GroupTitle = {
    type: GroupType.recommended,
    subText: 'Suggested',
  };
  carePointGroup: GroupTitle = {
    type: GroupType.carePoint,
  };

  generateCatIds(appCategories) {
    let ids = [];
    appCategories &&
      appCategories.forEach((item) => {
        ids.push(...this.getId(item));
      });
    return ids;
  }

  getId(item) {
    let ids = [];
    if (item.children && item.children.length > 0) {
      item.children.forEach((child) => {
        if (child.children && child.children.length > 0) {
          ids.push(...this.getId(child));
        } else {
          ids.push({ id: child.id, parentCode: child.title });
        }
      });
    } else {
      ids.push({ id: item.id, parentCode: item.title });
    }
    return ids;
  }

  generateRecomAppsGroup(ids, recommendedApps) {
    let recommendedGroup = ids.reduce((a, v) => {
      a[v.id.toString()] = {
        parentCode: v.parentCode,
        items: [],
      };
      return a;
    }, {});
    recommendedApps.forEach((app) => {
      recommendedGroup[app.searchCategoryId]?.items.push(app);
    });

    return Object.keys(recommendedGroup).map((e) => {
      return recommendedGroup[e];
    });
  }

  getRandomIds(idsArray, count) {
    let ids = [];
    while (count > 0) {
      ids.push(
        ...idsArray.splice(Math.floor(Math.random() * idsArray.length), 1)
      );
      count--;
    }
    return {
      ids: [...ids],
      idsArray: [...idsArray],
    };
  }

  navigateToDetail(tileData: TileData, type: number) {
     //Check if the tile is from Emis or Smartlaunch and if it is a Otuska study
     if((this.isEmis || this.isSmartlaunch) && tileData.otsukastudy){
      return;
    }
    if (type == TileType.apps) {
      this.router.navigate(
        [
          `/detail/${TileType[type]}/${tileData.resourceId}/${tileData.platform}`,
        ],
        { state: { data: tileData } }
      );
    } else {
      this.router.navigate(
        [`/detail/${TileType[type]}/${tileData.resourceId}`],
        { state: { data: tileData } }
      );
    }
    (<any>window).gtag('config', environment.ga, {
      page_title: 'Detail',
      page_path: '/detail',
    });
  }

  navigateToPrescribe(tileData: TileData, type: number) {
    //Check if the tile is from Emis or Smartlaunch and if it is a Otuska study
    if((this.isEmis || this.isSmartlaunch) && tileData.otsukastudy){
      return;
    }
    if (tileData.isCdstApp) {
      if (tileData.locked) {
        this.router.navigate(['/PartnerApiInstruction']);
        return;
      }
      if (tileData.warningAcceptance) {
        this.router.navigate(['/cpApps'], { state: { appData: tileData } });
      } else {
        this.openFirstRunModal(tileData);
      }
    } else if (tileData.isStudy) {
      this.store.dispatch(PrescribeActions.clearBag());
      this.router.navigate([`/prescribe/${TileType[type]}/${tileData.id}`], {
        queryParams: { type: 'studyapp' },
      });
    } else if (tileData.isCustomApp) {
      this.store.dispatch(PrescribeActions.clearBag());
      this.router.navigate([`/prescribe/${TileType[type]}/${tileData.id}`], {
        queryParams: { type: 'customApp', platform: tileData.platform },
      });
    } else {
      this.store.dispatch(PrescribeActions.addToBag({ item: { ...tileData } }));
      this.router.navigate([`/prescribe/${TileType[type]}/${tileData.id}`], {
        queryParams: { type: TileType[type], platform: tileData.platform },
      });
    }
  }

  openFirstRunModal(tileData) {
    let modalRef = this.modalService.open(FirstRunModalComponent, {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      windowClass: 'first-run-modal',
    });
    modalRef.componentInstance.appData = tileData;
  }

  goToHistory(patientInfo, resource?) {
    this.router.navigate(['patient', patientInfo.patientFHIRID], {
      state: { data: resource ?? null },
    });
  }

  gethomeurl() {
    return this.config.appconfig.homeUrl;
  }

  patientGet(tileData: TileData) {
    this.trackuser(tileData, 'get');
    this.userservice.getExternalLink = tileData.url;
    this.store.dispatch(showExtLink({ showExtLink: true }));
  }

  patientShare(tileData: TileData) {
    this.trackuser(tileData, 'share');
    let type;
    let message;
    const title = tileData.title;
    if (tileData.isApp) {
      type = 'apps';
      message =
        'Have you been to the IQVIA Digital Health Library? Check out this cool app I found.\n\
    	\n\
    	App: ' +
        tileData.title +
        '\n \
    	Link: ';
    } else if (tileData.isDevice) {
      type = 'devices';
      message =
        'Have you been to the IQVIA Digital Health Library? Check out this cool device I found.\n\
    	\n\
    	Device: ' +
        tileData.title +
        '\n \
    	Link: ';
    } else if (tileData.isContent) {
      type = 'content';
      message =
        'Have you been to the IQVIA Digital Health Library? Check out this cool content I found.\n\
    	\n\
    	Content: ' +
        tileData.title +
        '\n \
    	Link: ';
    }
    const linkUrl =
      message +
      this.config.appconfig.homeUrl +
      '/SAMLSSO/iqviassologin?returnUrl=' +
      this.config.appconfig.homeUrl +
      '/detail/' +
      type +
      '/' +
      tileData.resourceId +
      '/' +
      tileData.platform +
      '&marketUrl=' +
      tileData.url +
      '&facilityShortName=' +
      this.config.getFacilityShortCode();
    let link =
      'mailto:?subject=' +
      encodeURIComponent(
        'Check out this ' +
          type +
          ': ' +
          title +
          ' on IQVIA Digital Health Library'
      ) +
      '&body=' +
      encodeURIComponent(linkUrl);
    window.location.href = link;
  }

  trackuser(tileData, accesstype) {
    let facilityId = this.config.getFacilityID();
    let countryCode = sessionStorage.getItem('countrycode').toLowerCase();
    let getshareParams = {
      resourceId: tileData.resourceId,
      resourceType: 0,
      facilityID: facilityId,
      countryCode: countryCode,
      entryType: 1,
      clientApp: 1,
      category: null,
      appPlatform: tileData.platform,
      appPrice: '',
      devicePrice: '',
      deviceStore: '',
      contentType: '',
      contentSource: '',
    };
    if (tileData.isApp) {
      let platform = tileData.platform;
      let priceType = tileData.priceType;
      platform === 3
        ? (getshareParams.resourceType = 4)
        : (getshareParams.resourceType = 0);
      priceType === 1 ? (getshareParams.appPrice = 'Free') : 'All';
      priceType === 2 ? (getshareParams.appPrice = 'Freemium') : 'All';
      priceType === 3 ? (getshareParams.appPrice = tileData.price) : 'All';
    } else if (tileData.isDevice) {
      getshareParams.deviceStore = tileData.url;
      getshareParams.devicePrice = tileData.price;
      getshareParams.resourceType = 1;
    } else if (tileData.isContent) {
      getshareParams.contentSource = tileData.url;
      getshareParams.contentType = tileData.type;
      getshareParams.resourceType = 2;
    }
    accesstype == 'get' ? (getshareParams.entryType = 2) : null;
    accesstype == 'share' ? (getshareParams.entryType = 3) : null;
    const params = JSON.stringify(getshareParams);
    this.userservice.getUserAuditData(params).subscribe();
  }
}