import { Action, createReducer, on } from '@ngrx/store';
import { UserActions } from '../actions/user.actions';


export const userFeatureKey = 'user';

export interface UserState {
  userData,
  userDataLoaded: boolean,
  userFacilityData,
  userFacilityDataLoaded: boolean
}

export const userInitialState: UserState = {
  userData: null,
  userDataLoaded: false,
  userFacilityData: null,
  userFacilityDataLoaded: false
};


export const userReducer = createReducer(
  userInitialState,
  on(UserActions.userDataLoaded, (state, action) => {
    return {
      ...state,
      userData: action.userData,
      userDataLoaded: action.userDataLoaded
    }
  }),
  on(UserActions.userFacilityDataLoaded, (state, action) => {
    return {
      ...state,
      userFacilityData: action.userFacilityData,
      userFacilityDataLoaded: action.userFacilityDataLoaded
    }
  })
);

export function reducer(state: UserState | undefined, action: Action): any {
  return userReducer(state, action);
}

