import { Injectable } from "@angular/core";
import { GlobalConstants } from "../constants/global.constants";

@Injectable({
  providedIn: "root",
})
export class UtilsService {
  isoLang = GlobalConstants["isoLangs"];
  constructor() { }

  getLanguageName(this: any, key: string) {
    let lang = this.isoLang[key.toLowerCase()];
    return lang ? lang.name : key;
  }
  getLanguageNativeName(this: any, key: string) {
    var lang = this.isoLang[key];
    return lang ? lang.nativveName : key;
  }
  ifEqual(val1: any, val2: any, ifOption: any, elseOption: any) {
    if (val1 === val2) {
      return ifOption.fn(this);
    } else if (elseOption) {
      return elseOption.fn(this);
    }
  }
  truncate(str: string, len: number, hideMore: boolean) {
    if (str.length > len && str.length > 0) {
      var new_str = str + " ";
      new_str = str.substr(0, len);
      new_str = str.substr(0, new_str.lastIndexOf(" "));
      new_str = (new_str.length > 0) ? new_str : str.substr(0, len);

      if (hideMore) {
        return new_str + ' ...';
      } else {
        return new_str + '<a href="#" class="">...</a>';
      }
    }
    return str;
  }

  isEmptyObj(obj) {
    try {
      return JSON.stringify(obj) === '{}';
    } catch {
      return false;
    }
  }

  compareSimpleObj(obj1, obj2) {
    try {
      return JSON.stringify(obj1) === JSON.stringify(obj2)
    } catch {
      return false;
    }
  }

  groupBy(list, key) {
    return list.reduce(function (a, v) {
      (a[v[key]] = a[v[key]] || []).push(v);
      return a;
    }, {});
  }

  generateCategoryTree(categories, tree = {}, parents = []): any {
    for (const category of categories) {
      let idTree = [...parents].reverse().reduce((prev, cur) => {
        return { [cur]: prev }
      }, category.id);
      tree[category.id] = {
        type: 'category',
        id: category.id,
        name: category.title,
        idTree
      };
      if (category.children && category.children.length > 0) {
        parents.push(category.id);
        this.generateCategoryTree(category.children, tree, [...parents]);
        parents.pop();
      }
    }
    return tree;
  }

  replaceAll(statement: string, search: string, replacement: string) {
    return statement.replace(new RegExp(search, 'g'), replacement);
  }

  detectIE() {
    const userAgent = window.navigator.userAgent;
    return userAgent.indexOf('MSIE') > -1 || userAgent.indexOf('Trident/') > -1;
  }
}
