import { createAction, props } from '@ngrx/store';

const loadUserData = createAction(
  '[UserData Resolver] Load UserData'
);

const userDataLoaded = createAction(
  '[UserData Effect] UserData Loaded',
  props<{ userData: any, userDataLoaded: boolean }>()
);

const loadUserFacilityData = createAction(
  '[UserData Resolver] Load UserFacilityData',
  props<{ facilityId: string, patientID: string }>()
)



const userFacilityDataLoaded = createAction(
  '[UserData Effect] UserFacilityData Loaded',
  props<{ userFacilityData: any, userFacilityDataLoaded: boolean }>()
)

export const UserActions = { loadUserData, userDataLoaded, loadUserFacilityData, userFacilityDataLoaded }




