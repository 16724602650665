import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GlobalConstants } from 'projects/prescriber/src/app/core/constants/global.constants';
import { LoginService } from '../../core/services/login.service';
import { AuthService } from 'projects/prescriber/src/app/core/services/auth.service';
import { mergeMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppDataActions } from '../../store/actions/app-data.actions';
import { environment } from 'projects/prescriber/src/environments/environment';
import { ConfigService } from '../../core/services/config.service';
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, OnDestroy {
  form: FormGroup;
  regions: any;
  email!: String;
  password!: string;
  firstname: string;
  lastname: string;
  countrycode: string;
  Errormessage: string;
  submitted = false;
  user: any;
  countryLabel = {
    'US': 'United States',
    'GB': 'United Kingdom',
    'AE': 'United Arab Emirates'
  }
  ssolink: string;
  private restrictedDomains =["159solutions", "amundsen.imshealth", "ar.imshealth", "avacare", "br.imshealth", "ca.imsbrogan",
    "es.imshealth", "imscg", "imshealth", "in.imshealth", "iqvia", "quintilesims.com", "ironshorepharmaceuticals",
    "msllnurse", "mx.imshealth","novellaclinical","pl.imshealth","q2labsolutions","quintiles",
    "uk.imshealth","us.imshealth","valuecentric"]; 
  @ViewChild('tos') private tosContent;
  @ViewChild('privacypolicy') private privacyContent;
  constructor(private router: Router,
    private formbuilder: FormBuilder,
    private loginService: LoginService,
    private authService: AuthService,private apiconfig: ConfigService,
    private store: Store) {
    this.user = {
      username: "",
      password: "",
      rememberMe: false
    }
    this.countrycode = this.apiconfig.getRegion();    
  }
  ngOnInit(): void {
    this.regions = GlobalConstants.regions;
    this.countrycode ? this.countrycode : this.countrycode = this.regions[0].Code;
    sessionStorage.setItem("countrycode", this.countrycode);
    this.form = this.formbuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      tos: [false, Validators.pattern('true')],
      email: ['', [Validators.required, Validators.email,this.emailDomainValidator.bind(this)]],
      password: ['', [Validators.required,
      Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')

      ]],
    });
  }

  ngOnDestroy(): void {
    this.authService.showErrorMessage(false, "");
    this.authService.showSuccessMessage(false, "");
  }

  emailDomainValidator(control) {
    const emailDomain = control.value.substring(control.value.lastIndexOf('@') + 1);
    if(control.value && this.restrictedDomains.some(domain => emailDomain.includes(domain))){
      this.ssolink = this.apiconfig.appconfig.homeUrl + "/SAMLSSO/iqviassologin";
      return {
        'restrictedDomain' : true
      };
    }
    return null;
  }
    
  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }
  get ErrorMessage() {
    return this.authService.errorMessage;
  }

  get ShowErrorMessage() {
    return this.authService.showError;
  }
  logIn() {
    this.router.navigate(['/login']);
    let countryCode = sessionStorage.getItem('countrycode');
    (<any>window).gtag('config', environment.ga, {
      'page_title': 'Login',
      'page_path': countryCode + '/login'
    });
  }
  regionlist(event) {   
    this.countrycode = event.target.value;
    sessionStorage.setItem("countrycode", this.countrycode);
  }
  termsofservice() {
    this.tosContent.open();
  }

  privaypolicy() {
    this.privacyContent.open();
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid

    if (this.form.invalid) {
      return;
    }
    this.form.value.role = 3;
    this.form.value.confirmPassword = this.form.value.password;
    this.form.value.facilityId = 12;
    this.user.username = this.form.value.email;
    this.user.password = this.form.value.password

    this.loginService.register(this.form.value).pipe(
      mergeMap(() => this.loginService.login(this.user))
    ).subscribe((response) => {
      let responsedata = JSON.parse(JSON.stringify(response));
      if (responsedata && responsedata.access_token) {
        this.authService.setToken(responsedata.access_token, responsedata.expires_in);
        this.authService.setRefreshToken(responsedata.refresh_token);
      }
      this.store.dispatch(AppDataActions.reset());
      this.router.navigate(["/dashboard"]);
      this.authService.isLoggedIn = true;
      let countryCode = sessionStorage.getItem('countrycode');
      (<any>window).gtag('event', 'Completed Registration', {
        'event_category': 'Registration',
        'country': countryCode + 'register'
      });
    }, error => {
      if (error && error.message) {
        this.authService.showErrorMessage(true, error.message);
      }
    });

  }
}
