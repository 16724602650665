import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map } from 'rxjs/operators';
import { UserDataService } from '../../core/services/user-data.service';
import { UserActions } from '../actions/user.actions';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { AuthService } from '../../core/services/auth.service';
import { AppDataService } from '../../core/services/app-data.service';



@Injectable()
export class UserDataEffects {

    loadUserData$ = createEffect(
      () => this.actions$.pipe(
        ofType(UserActions.loadUserData),
        concatMap(action => this.userDataService.getUserData().pipe(
          catchError(error => this.errorHandler(error))
        )),
        map(userData => UserActions.userDataLoaded({ userData, userDataLoaded: true })),
      )
    );

  loadUserFacilityData$ = createEffect(
    () => this.actions$.pipe(
      ofType(UserActions.loadUserFacilityData),
      concatMap(action => this.userDataService.getUserFacilityData(action.facilityId, action.patientID).pipe(
        catchError(error => this.errorHandler(error))
      )),
      map(userFacilityData => UserActions.userFacilityDataLoaded({ userFacilityData, userFacilityDataLoaded: true })),
    )
  )

  errorHandler(error) {
    return this.appDataService.isIntegration().pipe(
      concatMap(data => {
        if (data) {
          this.authService.resetGlobal();
          this.authService.isTokenExpired = true;
          this.router.navigateByUrl('sessionTimeout');
          return of(error);
        } else {
          this.authService.resetGlobal();
          this.authService.showErrorMessage(true, 'Your session has expired, please login again.');
          this.authService.isTokenExpired = true;
          this.router.navigateByUrl('login');
          return of(error);
        }
      })
    );
  }

  constructor(private actions$: Actions, private userDataService: UserDataService, private router: Router, private authService: AuthService, private appDataService: AppDataService) { }

}
