import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { mergeMap, take, tap } from 'rxjs/operators';
import { PrescribeActions } from '../actions/prescribe.actions';
import { selectFirstItem } from '../selectors/prescribe.selectors';



@Injectable()
export class PrescribeEffects {

  navigateToPrescribe$ = createEffect(
    () => this.actions$.pipe(
      ofType(PrescribeActions.navigateToPrescribe),
      mergeMap(action => {
        return this.store.select(selectFirstItem).pipe(
          tap(firstItem => {
            let type;
            if (firstItem.isApp) {
              type = 'apps';
            } else if (firstItem.isDevice) {
              type = 'devices'
            } else if (firstItem.isContent) {
              type = 'content';
            }
            this.router.navigate([`/prescribe/${type}/${firstItem.id}`]);
          }),
          take(1)
        );
      }),
    ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private store: Store, private router: Router) { }

}
