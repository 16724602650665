import { createAction, props } from '@ngrx/store';

export const loadBuildVersion = createAction(
  '[Footer] Load BuildVersion'
);

export const buildVersionLoaded = createAction(
  '[Footer] BuildVersion Loaded',
  props<{ buildVersion: string }>()
);

export const showInfoModal = createAction(
  '[Header User Dropdown] Show/Hide info Modal',
  props<{ InfoModal: boolean }>()
);

export const showChangePasswordModal = createAction(
  '[Header User Dropdown] Show/Hide changePassword Modal',
  props<{ changePasswordModal: boolean }>()
);

export const reset = createAction(
  '[login/Logout] Reset'
);

export const setEmis = createAction(
  '[IntegrationGuard] Set EMIS'
);

export const setSso = createAction(
  '[IntegrationGuard] Set SSO'
)

export const setSmartLaunch = createAction(
  '[IntegrationGuard] Set SmartLaunch'
);

export const setPatientInfo = createAction(
  '[IntegrationGuard] Set PatientInfo',
  props<{ patientInfo: string }>()
);

export const setTitle = createAction(
  '[Detail Page] Set Detail Header',
  props<{ pageHeader: {} }>()
);

export const showExtLink = createAction(
  '[Welcom Popup] Show/Hide Coach Mark',
  props<{ showExtLink: boolean }>()
);

export const partnerAPI = createAction(
  '[IntegrationGuard] enable partnerAPI',
  props<{ partnerApiEnabled: boolean }>()
);

export const setPasswordExpiry = createAction(
  '[Login] Password Expiry',
  props<{ passwordExpiry: string }>()
);


export const AppDataActions = {
  loadBuildVersion, buildVersionLoaded, showInfoModal, showChangePasswordModal,
  reset, setEmis, setSmartLaunch, setSso, setPatientInfo, setTitle, showExtLink, partnerAPI, setPasswordExpiry
}




