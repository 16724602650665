
<div class="input-group align-items-center">
  <div class="input-group-prepend d-none d-md-flex">
    <span class="input-group-text"><i class="fas fa-search"></i></span>
  </div>
  <ng-template #rt let-r="result" let-t="term">
    <div [ngClass]="r.label ? 'label': 'item pe-auto px-2'" class="truncate" [title]="r.name">
      <i *ngIf="r.hasIosApp" class="fab fa-apple pe-2"></i>
      <i *ngIf="r.hasAndroidApp" class="fab fa-android pe-2"></i>
      <i *ngIf="r.hasWebApp" class="fas fa-desktop pe-2"></i>
      <span *ngIf="r.type === 'category'">{{type.categoryType}}</span>
      <ngb-highlight [result]="r.name" [term]="r.label ? '': t"></ngb-highlight>
    </div>
  </ng-template>
  <input type="text" class="form-control" placeholder="Search AppScript" (selectItem)="selectedItem($event)" [ngbTypeahead]="search" [resultTemplate]="rt" [value]="searchText"
    [inputFormatter]="formatter" aria-label="Search" aria-describedby="Search" (keypress)="onEnter($event)">

  <div ngbDropdown class="custom-dropdown d-none d-md-block">
    <button type="button" class="btn btn-outline-secondary" id="typeaheadDropdown" ngbDropdownToggle>{{type.value}}
      <i class="far fa-chevron-down"></i>
    </button>
    <div ngbDropdownMenu aria-labelledby="typeaheadDropdown">
      <button ngbDropdownItem *ngFor="let type of types" (click)="selectType(type)">{{type.value}}</button>
    </div>
  </div>
  <div class="input-group-append" (click)="close.emit()">
    <span class="input-group-text"><i class="fas fa-times"></i></span>
  </div>

</div>

