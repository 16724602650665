import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatMap, map } from 'rxjs/operators';
import { AppDataService } from '../../core/services/app-data.service';
import { AppDataActions } from '../actions/app-data.actions';



@Injectable()
export class AppDataEffects {

  loadUserData$ = createEffect(
    () => this.actions$.pipe(
      ofType(AppDataActions.loadBuildVersion),
      concatMap(action => this.appDataService.getBuildVersion()),
      map((buildVersion) => AppDataActions.buildVersionLoaded({ buildVersion: buildVersion[0].value })),
    )
  );

  constructor(private actions$: Actions, private appDataService: AppDataService) { }

}
