import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, OnDestroy, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { SlideOverData } from '../../models/slide-over-data';

@Component({
  selector: 'app-slide-over',
  templateUrl: './slide-over.component.html',
  styleUrls: ['./slide-over.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class SlideOverComponent implements AfterViewInit, OnDestroy {
  @Input() data!: SlideOverData;
  @ViewChild('slideOver', { static: false }) slideOver: ElementRef<HTMLElement>;

  constructor(private renderer: Renderer2) {
    this.renderer.addClass(document.body, 'slide-over-open');
  }

  ngAfterViewInit(): void {
    this.slideOver.nativeElement.style.setProperty('--width', `${this.data.width}vw`);
    this.slideOver.nativeElement.style.setProperty('--height', `${this.data.height}vh`);
    if (this.data.bottom !== undefined) {
      this.slideOver.nativeElement.style.setProperty('bottom', `${this.data.bottom}`);
    } else {
      this.slideOver.nativeElement.style.setProperty('top', `${this.data.top ?? 0}`);
    }
    if (this.data.right !== undefined) {
      this.slideOver.nativeElement.style.setProperty('right', `${this.data.right}`);
    } else {
      this.slideOver.nativeElement.style.setProperty('left', `${this.data.left ?? 0}`);
    }
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'slide-over-open');
  }
}
