import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfigService } from '../../core/services/config.service';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(private http: HttpClient, private configService: ConfigService) { }

  search(term: string, type: string, categories) {
    return combineLatest([this.searchCategories(term, type, categories), this.searchItems(term, type)]);
  }

  searchCategories(term: string, type: string, categories) {
    if (term === '') {
      return of([]);
    }
    return of(Object.keys(categories).map(key => categories[key]).filter((category: any) => category.name.toLowerCase().indexOf(term.toLowerCase()) !== -1));
    return this.http.get<[any, Object[]]>(this.configService.appconfig.apiurl + this.configService.getFacilityUrl() + type + '/typeahead/categories', {
      params: {
        q: term,
        platform: 'all'
      }
    }).pipe(
      map(data => data ? data : [])
    );
  }

  searchItems(term: string, type: string) {
    if (term === '') {
      return of([]);
    }
    const facilityId = this.configService.getFacilityUrl().split('/')[1];
    return this.http.get<[any, Object[]]>(this.configService.appconfig.apiurl + this.configService.getFacilityUrl() + type + '/typeahead', {
      params: {
        facilityId: facilityId,
        q: term
      }
    }).pipe(
      map(data => data ? data : [])
    );
  }
}
