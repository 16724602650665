<modal #changePassword [modalConfig]="modalConfig" (closed)="onClosePassword()">
    <div class="changepasswordclass">
        <div id="errorContainer"></div>
        <form [formGroup]="changePasswordForm" (ngSubmit)="onSubmit()">
            <div class="form-group">
                <div class="error" *ngIf="ShowErrorMessage"> <strong><span>Error! </span></strong>{{ErrorMessage}}</div>
            </div>
            <div class="form-group form-height">
                <label for="password">Current Password</label>

                    <input type="password"  formControlName="currentPassword" class="form-control"
                      [ngClass]="{ 'is-invalid': submitted && f.currentPassword.errors }" />
                    <div *ngIf="submitted && f.currentPassword.errors" class="invalid-feedback">
                      <div *ngIf="f.currentPassword.errors.required">Current Password is required.</div>
                    </div>


            </div>

            <div class="form-group form-height">
                <label for="confirm">New Password</label>
                <password-strength *ngIf="changePasswordForm.get('newPassword')?.value?.length && showPswrdStrength"
                [errors]="changePasswordForm.get('newPassword')?.errors?.passwordStrength"></password-strength>
            <input type="password"  class="form-control" [ngClass]="{ 'is-invalid': submitted && f.newPassword.errors }" name="newPassword"
            formControlName="newPassword" required (keyup)="showPswrdStrength = true" (focusout)="showPswrdStrength = false"/>

            <div *ngIf="submitted && f.newPassword.errors" class="invalid-feedback">
                <div *ngIf="f.newPassword.errors.required">New Password is required</div>
                <div *ngIf="f.newPassword.errors.passwordStrength">New password you have entered is not valid.</div>
              </div>
            </div>
            <div class="form-group form-height">
                <label for="confirm">Confirm New Password</label>
                <input type="password"  formControlName="confirmPassword" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }" />
              <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                <div *ngIf="f.confirmPassword.errors.required">Confirm Password is required.</div>
                <div *ngIf="f.confirmPassword.errors.confirmPasswordValidator">New Password and Confirm Password must match.</div>
              </div>
            </div>
            <div class="form-group text-center">
                <button type="button" class="btn btn-primary mx-1" (click)="onClosePassword()">Cancel</button>
                <button class="btn btn-primary mx-1 submit-change-password" id="submit">Change Password</button>
            </div>

        </form>
    </div>
</modal>
<ng-template #successchangepassword>
  <div class="toast-content">
      <div class="toastsuccess-header"><i class="fas fa-check"></i><span>Password Changed!</span> <i
              class="fas fa-times toast-close" (click)="close()"></i>
      </div>
      <div> Your Password has been changed successfully. </div>
  </div>
</ng-template>
