import { createAction, props } from '@ngrx/store';

export const setSortOption = createAction(
  '[Dashboard/Search] Sort Option',
  props<{ sortOption: string }>()
);

export const appSearchConfig = createAction(
  '[Dashboard/Search Box] App Search Config'
);

export const deviceSearchConfig = createAction(
  '[Dashboard/Search Box] Device Search Config'
);

export const contentSearchConfig = createAction(
  '[Dashboard/Search Box] Content Search Config'
);

export const setSearcQuery = createAction(
  '[Search Box] Search Query String',
  props<{ searchQuery: string }>()
);

export const SearchActions = { setSortOption, appSearchConfig, deviceSearchConfig, contentSearchConfig, setSearcQuery };




