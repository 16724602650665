import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfigService } from './core/services/config.service';
import { HttpserviceInterceptor } from './core/services/httpservice.interceptor';
import { RegisterHeaderComponent } from './layout/register-header/register-header.component';
import { NgxUiLoaderConfig, NgxUiLoaderHttpModule, NgxUiLoaderModule, NgxUiLoaderService } from 'ngx-ui-loader';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { FooterComponent } from './layout/footer/footer.component';
import { reducers, metaReducers } from './store/reducers';
import { EffectsModule } from '@ngrx/effects';
import { UserDataEffects } from './store/effects/user-data.effects';
import { AppDataEffects } from './store/effects/app-data.effects';
import { FooterHeaderComponent } from './layout/footer-header/footer-header.component';
import { ModalComponent } from './shared/components/modal/modal.component';
import { RegisterComponent } from './layout/register/register.component';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { AppsEffects } from './store/effects/apps.effects';
import { DevicesEffects } from './store/effects/devices.effects';
import { ContentEffects } from './store/effects/content.effects';
import { PrescribeEffects } from './store/effects/prescribe.effects';
import { ErrorInterceptorService } from './core/services/error-interceptor.service';
import { LearnmoreComponent } from './layout/learnmore/learnmore.component';
import { LearnmoreHeaderComponent } from './layout/learnmore-header/learnmore-header.component';
import { UnsubscribeComponent } from './layout/unsubscribe/unsubscribe.component';
import { PatientPrivacyComponent } from './layout/patient-privacy/patient-privacy.component';
import { PatientPrivacyHeaderComponent } from './layout/patient-privacy-header/patient-privacy-header.component';
import { PartnerApiInstructionComponent } from './shared/components/partner-api-instruction/partner-api-instruction.component';
import { PartnerApiHeaderComponent } from './layout/partner-api-header/partner-api-header.component';

export const appInitializer = (apploader: ConfigService) => {
  return () => apploader.init();
}

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  overlayColor: "rgba(1, 1, 1, 0.1)",
};

@NgModule({
    declarations: [
        AppComponent,
        RegisterHeaderComponent,
        FooterComponent,
        FooterHeaderComponent,
        RegisterComponent,
        LearnmoreComponent,
        LearnmoreHeaderComponent,
        UnsubscribeComponent,
        PatientPrivacyComponent,
        PatientPrivacyHeaderComponent,
        PartnerApiInstructionComponent,
        PartnerApiHeaderComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        SharedModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        NgbDropdownModule,
        NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
        NgxUiLoaderHttpModule.forRoot({ showForeground: true, excludeRegexp: ['\\brelatedbag\\b'] }),
        StoreModule.forRoot(reducers, {
            metaReducers,
            runtimeChecks: {
                strictStateImmutability: true
            }
        }),
        !environment.production ? StoreDevtoolsModule.instrument({
            maxAge: 25
        }) : [],
        EffectsModule.forRoot([UserDataEffects, AppDataEffects, AppsEffects, DevicesEffects, ContentEffects, PrescribeEffects])
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializer,
            deps: [ConfigService],
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpserviceInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptorService,
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})

export class AppModule { }
