import { Component, OnInit } from '@angular/core';
import { HeaderItems } from '../../shared/models/header-items';

@Component({
  selector: 'app-patient-privacy-header',
  templateUrl: './patient-privacy-header.component.html',
  styleUrls: ['./patient-privacy-header.component.scss']
})
export class PatientPrivacyHeaderComponent implements OnInit {

  
  headerItems: HeaderItems = {
    logo: true, 
    iqviaLogo: true
  };
  constructor() { }

  ngOnInit(): void {
  }
}
