import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { filter, finalize, first, tap } from 'rxjs/operators';
import { UserActions } from '../../store/actions/user.actions';
import { userDataLoaded } from '../../store/selectors/user-data.selectors';

@Injectable({
  providedIn: 'root'
})
export class UserDataResolver implements Resolve<any> {
  loading = false;
  facilityId: string;

  constructor(private store: Store) { }

  resolve(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {
    return this.store.pipe(
      select(userDataLoaded),
      tap((userDataLoaded) => {
        if (!this.loading && !userDataLoaded) {
          this.loading = true;
          this.store.dispatch(UserActions.loadUserData())
        }
      }),
      filter(userDataLoaded => userDataLoaded),
      first(),
      finalize(() => this.loading = false)
    );
  }

}
