import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import { ConfigService } from '../../core/services/config.service';
import { AppDataActions } from '../../store/actions/app-data.actions';
import { buildVersion } from '../../store/selectors/app-data.selectors';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  buildversion: string;
  year: number;
  @ViewChild('tos') tosContent;
  @ViewChild('privacypolicy') privacyContent;
  @Input() stickyFooter;
  constructor(private store: Store) {
    this.year = new Date().getFullYear();
    this.store.select(buildVersion).subscribe(buildVersion => {
      this.buildversion = buildVersion;
    });
  }

  ngOnInit(): void {
  }
  termsofservice() {
    this.tosContent.open();
  }
  privaypolicy() {
    this.privacyContent.open();
  }
}
