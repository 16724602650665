<div id="partner-api">
    <h3>Partner API Instruction</h3>
    <p>Complete the one-time process below to <b>unlock</b> all possibilities of <b>AppScript on EMIS</b>.
    <p>Until the <b>Partner API</b> is activated, you cannot access CarePoint Apps, automatic form completion, or file back to the
        EMR. </p>
    <p>However, you can always freely browse the <b>entire formulary</b> of AppScript resources, check apps details and
        discover its <b>AppScript Score</b>.</p>

    <div class="screen mt-4">
        <p><span class="badge badge-secondary me-4">1</span>From the main EMIS menu, navigate to System Tools and open EMAS Manager.</p>
        <img class="img-responsive ms-5 mb-4 mt-2" [src]="'/assets/images/PartnerInstruction/scr_01.png'| cdnurl" loading="lazy" alt="screen 01" />
    </div>

    <div class="screen mt-4">
        <p><span class="badge badge-secondary me-4">2</span>Select <b>Partner API</b> from the menu at the bottom left.</p>
        <img class="img-responsive ms-5 mb-4 mt-2" [src]="'/assets/images/PartnerInstruction/scr_02.png'| cdnurl" loading="lazy" alt="screen 02" />
    </div>

    <div class="screen mt-4">
        <p><span class="badge badge-secondary me-4">3</span>Select the <b>AppScript</b> entry.</p>
        <img class="img-responsive ms-5 mb-4 mt-2" [src]="'/assets/images/PartnerInstruction/scr_03.png'| cdnurl" loading="lazy" alt="screen 01" />
    </div>

    <div class="screen mt-4">
        <p><span class="badge badge-secondary me-4">4</span>Click the <b>Activate Application</b> button with the green checkmark icon on the left side of the command bar.</p>
        <img class="img-responsive ms-5 mb-4 mt-2" [src]="'/assets/images/PartnerInstruction/scr_04.png'| cdnurl" loading="lazy" alt="screen 02" />
    </div>

    <div class="screen mt-4">
        <p><span class="badge badge-secondary me-4">5</span>Click the <b>Login Access</b> button on the command bar. The <b>Application Login Access</b> window will open.</p>
        <img class="img-responsive ms-5 mb-4 mt-2" [src]="'/assets/images/PartnerInstruction/scr_05.png'| cdnurl" loading="lazy" alt="screen 01" />
    </div>

    <div class="screen mt-4">
        <p><span class="badge badge-secondary me-4">6</span>For the required Users tick both checkboxes - <b>Auto Login & Allow Login</b>. Then confirm your selection by clicking OK.</p>
        <img class="img-responsive ms-5 mb-4 mt-2" [src]="'/assets/images/PartnerInstruction/scr_06.png'| cdnurl" loading="lazy" alt="screen 02" />
    </div>

    <div class="screen mt-4">
        <p><span class="badge badge-secondary me-4">7</span>Click the <b>Edit Users</b> button on the command bar. The <b>User Configuration</b> window will open. .</p>
        <img class="img-responsive ms-5 mb-4 mt-2" [src]="'/assets/images/PartnerInstruction/scr_07.png'| cdnurl" loading="lazy" alt="screen 01" />
    </div>

    <div class="screen mt-4">
        <p><span class="badge badge-secondary me-4">8</span>Make sure that each required user has the <b>checkbox ticked</b>.</p>
        <img class="img-responsive ms-5 mb-4 mt-2" [src]="'/assets/images/PartnerInstruction/scr_08.png'| cdnurl" loading="lazy" alt="screen 02" />
    </div>

    <div class="screen mt-4">
        <p><span class="badge badge-secondary me-4">9</span>When asked for <b>password</b>, enter: <span class="d-none d-inline-block col-4">  <app-copy-code code="AppScriptbyIQVIA2024" btnText="Copy"></app-copy-code></span></p>
        <img class="img-responsive ms-5 mb-4 mt-2" [src]="'/assets/images/PartnerInstruction/scr_09.png'| cdnurl" loading="lazy" alt="screen 02" />
    </div>

    <div class="screen mt-4">
        <p><span class="badge badge-secondary me-4">10</span>Confirm by clicking <b>OK</b>.</p>
        <img class="img-responsive ms-5 mb-4 mt-2" [src]="'/assets/images/PartnerInstruction/scr_10.png'| cdnurl" loading="lazy" alt="screen 02" />
    </div>

    <div class="screen mt-4 pb-4">
        <h3>Notes</h3>
        <p>Not all users of <b>EMIS Web</b> have access to the <b>EMAS Manager</b>. If you do not see this tool, please contact your Practice Manager who's got <b>RBAC B0062 - Local System Administration</b> role. </p>
        <p>After completing the activation process, it may <b>be a while</b> before the change takes effect.</p>
    </div>

    <!-- <div class="mt-4 pb-4 text-center">
        <button type="button" class="btn btn-primary" (click)="downloadPartnerApiInstructionPDF()">Download PDF</button>
    </div> -->

</div>
