<div *ngIf="stickyFooter" class="footerdiv d-flex align-items-center justify-content-between">
  <a href="https://www.iqvia.com/" target="_blank">
    <img [src]="'/assets/images/AppScript_IQVIA_logo_login_WEB.Tablet.png' | cdnurl"  class="responsive-img footer-left"
      alt="IqviaLogo" />
  </a>
  <ul class="list-inline footer-right">
    <span class="divider px-2"></span>
    <li class="list-inline-item" (click)="termsofservice()">
      <a id="tos-link">Terms of Service <span class="divider pl-2"></span></a>
      <app-tos #tos></app-tos>
    </li>
    <li class="list-inline-item" (click)="privaypolicy()">
      <a id="privacy-link">Privacy Policy <span class="pl-2 hidden-xs"></span></a>
      <app-privacy-policy #privacypolicy></app-privacy-policy>
    </li>
   
  </ul>
</div>

<!-- For the Mobile Screen -->
<div  *ngIf="!stickyFooter" class="mobile-aboutContent">
  <div class="about-content">
    <div class="about-items">
      <div class="about-top">
        <img class="logo-img" [src]="'/assets/images/US_PRESCRIPTION.png' | cdnurl"  alt="AppScript" title="AppScript" />
        <div class="trademark-text">AppScript<span class="tm">TM</span> and AppScript logo are registered trademarks of
          IQVIA
        </div>
      </div>
      <div class="about-bottom">
        <ul class="list-inline li-items about-ul">          
          <div class="tos-div">
            <li class="list-inline-item li-tos" (click)="termsofservice()">
              <a id="tos-link">Terms of Service</a>
              <app-tos #tos></app-tos>
            </li>
            <span class="divider"></span>
            <li class="list-inline-item li-tos" (click)="privaypolicy()">
              <a id="privacy-link">Privacy Policy</a>
              <app-privacy-policy #privacypolicy></app-privacy-policy>
            </li>
          </div>
          <li class="version">
            <div> <i class="far fa-copyright"></i> {{year}} AppScript <span class="tm">TM</span> {{buildversion}}
            </div>
            <div> powered by </div>
          </li>
        </ul>
        <div><a href="https://www.iqvia.com/" target="_blank"><img
              [src]="'/assets/images/AppScript_IQVIA_logo_login_WEB.Tablet.png' | cdnurl"  alt="IqviaLogo"
              class="responsive-img about-logo"></a></div>
      </div>
    </div>
  </div>
</div>
