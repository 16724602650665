import { Action, createReducer, on } from '@ngrx/store';
import { FilterActions } from '../actions/filter.actions';


export const filterFeatureKey = 'filter';

export interface FilterState {
  appFilter: any,
  deviceFilter: any,
  contentFilter: any
  showFilter: boolean
}

export const filterInitialState: FilterState = {
  appFilter: {
    categoryId: 0,
    platform: 0,
    priceType: 0,
    searchQuery: ''
  },
  deviceFilter: {
    priceRange: 0,
    categoryId: 0
  },
  contentFilter: {
    contentPurpose: 0,
    categoryId: 0
  },
  showFilter: false
};


export const filterReducer = createReducer(
  filterInitialState,
  on(FilterActions.appFilterState, (state, action) => {
    return {
      ...state,
      appFilter: action.appFilter
    }
  }),
  on(FilterActions.deviceFilterState, (state, action) => {
    return {
      ...state,
      deviceFilter: action.deviceFilter
    }
  }),
  on(FilterActions.contentFilterState, (state, action) => {
    return {
      ...state,
      contentFilter: action.contentFilter
    }
  }),
  on(FilterActions.showFilter, (state, action) => {
    return {
      ...state,
      showFilter: action.showFilter
    }
  }),
  on(FilterActions.setInitialState, (state, action) => {
    return {
      ...state,
      ...filterInitialState
    }
  }),
  on(FilterActions.updateCategory, (state, action) => {
    return {
      ...state,
      [action.category.type]: { ...state[action.category.type], categoryId: action.category.categoryId, searchQuery: action.category.searchQuery }
    }
  })
);

