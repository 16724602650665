<nav class="navbar" [ngClass]="isFullScreen ? 'container-fluid' : 'container'">
  <!-- Left Menu -->
  <div class="left-menu d-none d-md-block">
    <img *ngIf="data.logo" class="logo-img" [src]="logoUrl" alt="AppScript"
      title="AppScript" (click)="userAction.emit(headerActions.navigateToHome)" role="button" />
    <div *ngIf="data.back" class="back" (click)="userAction.emit(headerActions.back)">
      <i class="fas fa-angle-left"></i><span> Back</span>
    </div>
  </div>
  <div *ngIf="!showSearch" class="left-menu d-block d-md-none">
    <img *ngIf="data.logo" class="logo-img" [src]="logoUrl" alt="AppScript"
      title="AppScript" [routerLink]="['/dashboard']" role="button" />
    <div *ngIf="data.back" class="back" (click)="userAction.emit(headerActions.back)">
      <i class="fas fa-angle-left"></i><span> Back</span>
    </div>
  </div>

  <div class="center-right d-flex">
    <!-- Center Menu - Web View -->
    <div class="center-menu d-flex">
      <ng-template [ngIf]="!showSearch">
        <div *ngIf="data.navList?.home" class="nav-menu d-none d-md-flex" [ngClass]="data.navList?.home?.active? 'active' : ''"
          role="button" (click)="userAction.emit(headerActions.navigateToHome)">
          <img class="d-inline home-logo" [src]="'/assets/images/grid_icon.svg' | cdnurl" /><span>Home</span>
        </div>
        <div *ngIf="data.navList?.history" class="nav-menu d-none d-md-flex" [ngClass]="data.navList?.history?.active? 'active' : ''"
          role="button" (click)="userAction.emit(headerActions.navigateToHistory)">
          <i class="fas fa-history history"></i><span class="px-2">History</span>
        </div>
        <div class="align-self-center">
          <div class="d-block d-sm-none">
            <span *ngIf="data.title" class="title text-nowrap">{{data.title}}</span>
            </div>

          <div class="d-none d-md-flex gap-3">
            <span *ngIf="data.title" class="title text-nowrap">{{data.title}}</span>
          <span *ngIf="data.titleSup" class="title-sup pl-3 mt-3">{{data.titleSup}}</span>
          <span *ngIf="data.subTitle" class="sub-title pl-3" [title]="data.subTitle">{{data.subTitle}}</span>
          </div>
        </div>
      </ng-template>
      <div class="w-100 align-self-center" [ngClass]="showSearch ? 'show' : 'hide'">
        <app-search-box *ngIf="showSearch" (close)="showSearch=false"></app-search-box>
      </div>
    </div>

    <!-- Right Menu -->
    <div *ngIf="data.iqviaLogo" class="iqviaLogo">
      <img [src]="'/assets/images/AppScript_IQVIA_logo_login_WEB.Tablet.png' | cdnurl"  class="responsive-img footer-left"
        alt="IqviaLogo" />
    </div>

    <div class="d-none d-md-flex menu-actions pe-3">
      <div *ngIf="data.prescribe" class="align-self-center" (click)="userAction.emit(headerActions.prescribe)">
        <button class="btn btn-primary wide" [class.completed]="data.complete">{{data.prescribeLabel ? data.prescribeLabel : 'Prescribe'}}</button>
      </div>
      <div *ngIf="data.send" class="align-self-center" (click)="userAction.emit(headerActions.sendToPatient)">
        <button class="btn btn-primary">{{data.prescribeLabel}}</button>
      </div>
      <i *ngIf="data.login" class="fas fa-sign-in-alt" role="button"
        (click)="userAction.emit(headerActions.logIn)" title="Login"></i>
      <a *ngIf="data.help" href="mailto:contact@imshealth.com"><i class="fas fa-question-circle"
          role="button" title="Help"></i></a>
      <i *ngIf="data.info" class="fas fa-info-circle" role="button" (click)="userAction.emit(headerActions.infoModal)"></i>
      <i *ngIf="data.search" class="fas fa-search" role="button" (click)="toggleSearch()"></i>
      <i *ngIf="data.bag" class="fas fa-medkit position-relative" #bagToggle [ngClass]="bagTotalCount ? 'circle-badge' : ''" role="button" (click)="toggleBag()" [attr.data-count]="bagTotalCount"></i>
      <i *ngIf="data.user" class="fas fa-user-circle" role="button" (click)="toggleUserPopup()" #userToggle></i>


      <ng-template [ngIf]="data.bagTemplate && showBag && bagTotalCount">
        <ng-template [ngTemplateOutlet]="data.bagTemplate" #bagContainer></ng-template>
      </ng-template>

      <div class="user-dropdown d-flex flex-column" *ngIf="showUserPopup && data.menuItems" #userPopup>
        <div class="container-arrow"></div>
        <div *ngIf="data.menuItems.formulary" class="menu-item px-3"
          (click)="userAction.emit(headerActions.navigateToSearch)">
          <a>
            <i class="fas fa-chart-bar"></i><span>Formulary</span>
          </a>
        </div>
        <div *ngIf="data.menuItems.changePassword" class="menu-item px-3 d-flex"
        (click)="userAction.emit(headerActions.changePasswordModel)">
          <a>
            <i class="fas fa-unlock"></i>
            <div class="d-flex flex-column">
              <span>Change Password</span>
              <span *ngIf="data.menuItems.passwordExpiry" class="sub">Your Password will expire in {{data.menuItems.passwordExpiry}} {{data.menuItems.passwordExpiry == 1 ? 'day.' : 'days.'}}</span>
            </div>
          </a>
        </div>
        <div *ngIf="data.menuItems.help" class="menu-item px-3">
          <a href="mailto:contact@imshealth.com">
            <i class="fas fa-question-circle"></i><span>Help</span>
          </a>
        </div>
        <div *ngIf="data.menuItems.about" class="menu-item px-3 d-flex"
          (click)="userAction.emit(headerActions.aboutModal)">
          <a>
            <i class="fas fa-info-circle"></i><span>About</span>
          </a>
        </div>
        <div *ngIf="data.menuItems.signOut" class="menu-item px-3" (click)="userAction.emit(headerActions.logOut)">
          <a>
            <i class="fas fa-sign-out-alt"></i><span>Sign Out</span>
          </a>
        </div>
      </div>
      <div class="d-flex">
        <button *ngIf="data.ecrfNext" id="formBtn" class="btn btn-primary m-auto wide"
          (click)="userAction.emit(headerActions.postToEcrfFrame)" disabled>Next</button>
      </div>
    </div>
  </div>



  <!-- Mobile View Start -->
  <div class="d-flex d-md-none menu-actions">
    <div [ngClass]="data.navList?.home?.active? 'active' : ''" (click)="userAction.emit(headerActions.navigateToHome)">
      <img *ngIf="data.navList?.home" class="d-inline home-logo" [src]="'/assets/images/grid_icon_small.svg'| cdnurl" />
    </div>
    <i *ngIf="data.navList?.history" class="fas fa-history"
      [ngClass]="data.navList?.history?.active? 'active' : ''"
      (click)="userAction.emit(headerActions.navigateToHistory)"></i>
    <i *ngIf="data.login" class="fas fa-sign-in-alt" role="button"
      (click)="userAction.emit(headerActions.logIn)"></i>
    <a *ngIf="data.help" href="mailto:contact@imshealth.com"><i class="fas fa-question-circle"
        role="button"></i></a>
    <i *ngIf="data.search" class="fas fa-search" role="button" (click)="toggleSearch()"></i>
    <i *ngIf="data.bag" class="fas fa-medkit position-relative" [ngClass]="[bagTotalCount ? 'circle-badge' : '', data.navList?.bag?.active? 'active' : '']" role="button" [attr.data-count]="bagTotalCount" (click)="bagTotalCount ? userAction.emit(headerActions.navigateToPrescribe) : ''"></i>
    <i *ngIf="data.user" class="fas fa-user-circle" role="button" (click)="toggleUserPopupMob()"></i>
  </div>

  <div class="d-block d-md-none" *ngIf="showUserPopupMob && data.menuItems">
    <app-slide-over [data]="{width: 50, height: 100, right: 0, top: 0}">
      <div>
        <div class="slide-header">
          <i class="fas fa-times-circle ms-3" (click)="toggleUserPopupMob()"></i>
        </div>
        <div *ngIf="data.menuItems.formulary" class="menu-item px-3"
          (click)="userAction.emit(headerActions.navigateToSearch)">
          <a>
            <i class="fas fa-chart-bar"
              (click)="userAction.emit(haederActions.navigateToSearch)"></i><span>Formulary</span>
          </a>
        </div>
        <div *ngIf="data.menuItems.changePassword" class="menu-item px-3"
      (click)="userAction.emit(headerActions.changePasswordModel)">
          <a>
            <i class="fas fa-unlock"></i><span>Change Password</span>
          </a>
        </div>
        <div *ngIf="data.menuItems.help" class="menu-item px-3">
          <a href="mailto:contact@imshealth.com">
            <i class="fas fa-question-circle"></i><span>Help</span>
          </a>
        </div>
        <div *ngIf="data.menuItems.about" class="menu-item px-3 d-flex"
          (click)="userAction.emit(headerActions.aboutModal)">
          <a>
            <i class="fas fa-info-circle"></i><span>About</span>
          </a>
        </div>
        <div *ngIf="data.menuItems.signOut" class="menu-item px-3" (click)="userAction.emit(headerActions.logOut)">
          <a>
            <i class="fas fa-sign-out-alt"></i><span>Sign Out</span>
          </a>
        </div>
      </div>
    </app-slide-over>
  </div>

  <!-- Mobile View End -->
</nav>
