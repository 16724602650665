import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'projects/prescriber/src/environments/environment';
import { filter, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  appconfig: any;
  private facilityUrl: string;
  private facilityID: number;
  private facilityShortCode: string;
  environmentName : string
  constructor(private http: HttpClient, private router: Router) {
    this.environmentName = environment.name;
  }

  init() {
    return this.http.get(environment.sourcePath+"/assets/configuration/config.json").pipe(
      tap((config:any) => {
        this.appconfig = config;
        this.setApiUrl();
      })
    ).toPromise();
  }

  setApiUrl() {
    const currentUrl = window.location.href;
    const exactPath = 'iqvia.thirdparty.nhs.uk';
    if (this.environmentName === "prod" && currentUrl.includes(exactPath)) {
    this.appconfig.apiurl = "https://api.appscript.iqvia.thirdparty.nhs.uk/api/v2/";
    } else if (this.environmentName === 'qa' && currentUrl.includes(exactPath)) {
    this.appconfig.apiurl = "https://qaapi.appscript.iqvia.thirdparty.nhs.uk/api/v2/";
    }
  }

  getFacilityUrl() {
    return this.facilityUrl;
  }

  getFacilityID() {
    return this.facilityID;
  }

  setFacilityUrl(facilityId: number) {
    this.facilityUrl = 'facilities/' + facilityId + '/';
  }

  getAuthUrl() {
    return this.appconfig.IDSAuthorityUrl;
  }

  setFacilityID(facilityId: number) {
    this.facilityID = facilityId;
  }

  setFacilityShortCode(facilityShortcode: string) {
    this.facilityShortCode = facilityShortcode;
  }
  getRegion() {
    return this.appconfig?.region;
  }

  getFacilityShortCode() {
    return this.facilityShortCode;
  }

  getPipelineUrl() {
    return this.appconfig.pipelineUrl;
  }

  getPipelineUrlV2() {
    return this.appconfig.pipelineUrlV2;
  }

  getDiePRUrl() {
    return this.appconfig.diePRUrl;
  }

  getDiePRUrlV2() {
    return this.appconfig.diePRUrlV2;
  }

  getIncludeReq() {
    return this.appconfig.include;
  }
  getCdnUrl() {
    return this.appconfig.homeUrl + '/prescriber';
  }
}
