import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Subject, combineLatest } from 'rxjs';
import { concatMap, filter, finalize, first, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { UserActions } from '../../store/actions/user.actions';
import { selectUserData, userState } from '../../store/selectors/user-data.selectors';
import { ConfigService } from '../services/config.service';
import { UserDataResolver } from './user-data-resolver';
import { AppDataService } from '../services/app-data.service';
import { environment } from 'projects/prescriber/src/environments/environment';
import { AppDataEffects } from '../../store/effects/app-data.effects';
import { partnerAPI } from '../../store/actions/app-data.actions';

@Injectable({
  providedIn: 'root'
})
export class UserFacilityDataResolver implements Resolve<any> {
  loading = false;
  isEmis : boolean;
  isSmartlaunch : boolean;
  type:any;
  private readonly destroy$ = new Subject();
  constructor(private appdataservice : AppDataService ,private store: Store, private userDataResolver: UserDataResolver, private configService: ConfigService, private appDataService: AppDataService) {}

  resolve(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {
    let that = this;
    let countryCode = sessionStorage.getItem('countrycode');

    this.appdataservice.isEmis().pipe(
      takeUntil(this.destroy$)
    ).subscribe(data => {
      if (data) {
        this.isEmis = data;
      }
    });

    this.appdataservice.isSmartlaunch().pipe(
      takeUntil(this.destroy$)
    ).subscribe(data => {
      if (data) {
        this.isSmartlaunch = data;
      }
    });

    return this.userDataResolver.resolve(route, state).pipe(
      concatMap(() => {
        return this.store.pipe(
          select(userState),
          tap((userState) => {
            if (!this.loading && userState && userState.userData && userState.userData.clientId) {
              if (!userState.userFacilityDataLoaded) {
                this.loading = true;
                this.store.dispatch(UserActions.loadUserFacilityData({
                  facilityId: userState.userData.clientId,
                  patientID: that.appDataService.getPatientInfo()?.patientFHIRID
                }));
              }
              this.configService.setFacilityUrl(userState.userData.clientId);
              this.configService.setFacilityID(userState.userData.clientId);
              this.configService.setFacilityShortCode(userState.userData.facilityShortName);
              (<any>window).gtag('config', environment.ga, {
                'campaign': {
                  'dimension1': 'country',
                  'dimension2': 'role',
                  'dimension3': 'facilityId',
                  'dimension4': 'facilityName',
                  'dimension6': 'EMR',
                }
              });
              if(this.isEmis){
                //GA for EMIS application
                (<any>window).gtag('event', 'Success', {'event_category': 'Appscript_Login','country': countryCode,'role':userState.userData.roles[0],'facilityId':userState.userData.clientId,'facilityName':userState.userData.name,'EMR':'EMIS Web'});
              }else if(this.isSmartlaunch){
                //GA for EPIC application
                (<any>window).gtag('event', 'Success', {'event_category': 'Appscript_Login','country': countryCode,'role':userState.userData.roles[0],'facilityId':userState.userData.clientId,'facilityName':userState.userData.name,'EMR':'Epic'});
              }else{
                //GA for Standalone application
                (<any>window).gtag('event', 'Success', {'event_category': 'Appscript_Login','country': countryCode,'role':userState.userData.roles[0],'facilityId':userState.userData.clientId,'facilityName':userState.userData.name,'EMR':'Stand Alone'});
              }
           }
           (<any>window).gtag('config', environment.ga, {
            'page_title': 'Dashboard',
            'page_path': 'dashboard'
          });
          }),
          filter(userState => userState.userFacilityDataLoaded),
          first(),
          finalize(() => this.loading = false)
        )
      })
    );
  }
}
