import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class ForgotpasswordService {

  private readonly forgotPassword = "account/password/forgot";
  private readonly resetPassword = "account/password/reset";
  private readonly changePassword = "account/password/change";
  //private readonly confirmPassword = "/account//password/confirm";


  //https://qaapi.appscript.net/api/v2/account/password/reset

  constructor(private http: HttpClient, private configureService: ConfigService) { }

  forgotpassword(email) {
    return this.http.post(this.configureService.appconfig.apiurl + this.forgotPassword, email);
  }

  resetpassword(reset,isBulkUser) { 
    if(isBulkUser){
      return this.http.post(this.configureService.appconfig.usapiUrl + "/"+ this.resetPassword, reset);
    }else{
      return this.http.post(this.configureService.appconfig.apiurl + this.resetPassword, reset);
    }
  }
  changepassword(change){
    return this.http.post(this.configureService.appconfig.apiurl + this.changePassword, change);
  }

}
