import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PrescribeState } from '../reducers/prescribe.reducer';

export const selectPrescribeState = createFeatureSelector<PrescribeState>('prescribe');

export const selectItemsInBag = createSelector(selectPrescribeState, state => state.itemsInBag);

export const selectFirstItem = createSelector(selectPrescribeState, state => state.itemsInBag ? state.itemsInBag[0] : null);

export const selectTotlaCount = createSelector(selectPrescribeState, state => state.itemsInBag ? state.itemsInBag.length : 0);

export const selectAttemptedCount = createSelector(selectPrescribeState, state => state.attemptedCount);

export const selectLabel = createSelector(selectPrescribeState, state => state.labelForPrescribe);
