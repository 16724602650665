import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function createPasswordStrengthValidator(namesToMatch?: string[]): ValidatorFn {
  return (control: AbstractControl) : ValidationErrors | null => {
    const value = control.value;
    if(!value) {
      return null;
    }

    const hasUpperCase = /[A-Z]+/.test(value);
    const hasLowerCase = /[a-z]+/.test(value);
    const hasNumeric = /[0-9]+/.test(value);
    const hasCommonWord = /\S*(\w+)(?=\1\1)\S*/.test(value);
    const hasSpecialChar =/[$&+,:;=?@#|'"<>.^*()%!-]/.test(value);
    let hasName = false;

    namesToMatch && namesToMatch.forEach(name => {
      if (value.toLowerCase().indexOf(name.toLowerCase()) >= 0) {
        hasName = true;
      }
    })

    let error: any = {};
    if(!hasUpperCase) {
      error['upperCase'] = true;
    }
    if(!hasLowerCase) {
      error['lowerCase'] = true;
    }
    if(!hasNumeric) {
      error['numeric'] = true;
    }
    if(hasCommonWord) {
      error['commonWord'] = true;
    }
    if(!hasSpecialChar) {
      error['specialChar'] = true;
    }
    if(hasName) {
      error['containUserName'] = true;
    }
    if(value.length < 8) {
      error['minlength'] = {
        actualLength: value.length,
        requiredLength: 8
      }
    }

    return JSON.stringify(error) !== '{}' ? { passwordStrength: error } : null;

  }
}
