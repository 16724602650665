import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalComponent } from '../modal/modal.component';
import { ModalConfig } from '../../models/modal.config';
import { NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { AppDataService } from '../../../core/services/app-data.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-accepted-tos',
  templateUrl: './accepted-tos.component.html',
  styleUrls: ['./accepted-tos.component.scss']
})
export class AcceptedTosComponent implements OnInit {

  isEmailError = false;
  sessionID;
  errorMessage: string = null;
  @ViewChild('accepetedtos') private accepetedTOS: ModalComponent;
  @ViewChild('tos') private tosContent;

  public modalConfig: ModalConfig = {
    modalTitle: 'Terms of Service',
    hideheader: () => {
      return true;
    },
    hideCloseButton: () => {
      return true
    },
  }


  constructor(config: NgbModalConfig, private appservice: AppDataService, private route: ActivatedRoute) {
    config.backdropClass = 'acceptedtos-fade-out';
    (<any>window).gtag('event', 'View EMIS Modal', {
      'event_category': 'Registration'
    });
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params.SessionID) {
        this.sessionID = params.SessionID.replace(/\//g, '')
      }
    });
  }


  onSubmit(email) {
    if (!this.validateEmail(email)) {
      this.isEmailError = true
      return;
    }
    this.errorMessage = null;
    this.isEmailError = false
    let params = {
      email: email,
      requestId: this.sessionID
    }
    this.appservice.emisAcceptedTermsofService(params).subscribe(
      (response: any) => {
        if (response.status == "success") {
          (<any>window).gtag('event', 'Completed EMIS Modal', {
            'event_category': 'Registration'
          });
          this.accepetedTOS.close();
          window.location.replace(response.message);
        }
        else if(response.status == "failed"){
          this.errorMessage = response.message
        }
      },
      error => {
        this.errorMessage = error.message
      })
  }

  validateEmail(email) {
    let RegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return RegExp.test(email)
  }

  open() {
    this.accepetedTOS.open("accepetedtos");
  }

  openTOSModal() {
    this.tosContent.open();
  }
}
