import { createAction, props } from '@ngrx/store';

export const loadFeaturedApps = createAction(
  '[Dashboard Apps] Load Featured Apps'
);

export const featuredAppsLoaded = createAction(
  '[Dashboard Apps] Featured Apps Loaded',
  props<{ featuredApps: Array<any> }>()
);

export const loadFavoriteApps = createAction(
  '[Dashboard Apps] Load Favorite Apps'
);

export const favoriteAppsLoaded = createAction(
  '[Dashboard Apps] Favorite Apps Loaded',
  props<{ favoriteApps: Array<any> }>()
);

export const remainingIds = createAction(
  '[Dashboard Apps] Remaining Ids',
  props<{ remainingIds: Array<any> }>()
);

export const selectedIds = createAction(
  '[Dashboard Apps] Selected Ids',
  props<{ selectedIds: Array<any> }>()
);

export const loadRecommendedApps = createAction(
  '[Dashboard Apps] Load Recommended Apps',
  props<{ count: number }>()
);

export const loadMoreRecommendedApps = createAction(
  '[Dashboard Apps] Load More Recommended Apps',
  props<{ count: number }>()
);

export const recommendedAppsLoaded = createAction(
  '[Dashboard Apps] Recommended Apps Loaded',
  props<{ recommendedAppsGroup: Array<any> }>()
);

export const moreRecommendedAppsLoaded = createAction(
  '[Dashboard Apps] More Recommended Apps Loaded',
  props<{ moreRecommendedAppsGroup: Array<any> }>()
);

export const addFavorite = createAction(
  '[App Details] Add to Favorite',
  props<{ app: any }>()
);

export const removeFavorite = createAction(
  '[App Details] Remove Favorite',
  props<{ app: any }>()
);

export const resetApps = createAction(
  '[Prescribe] Reset Apps'
)

export const AppsActions = {
  loadFeaturedApps, featuredAppsLoaded, loadFavoriteApps, favoriteAppsLoaded, remainingIds, selectedIds,
  loadRecommendedApps, loadMoreRecommendedApps, recommendedAppsLoaded, moreRecommendedAppsLoaded, addFavorite, removeFavorite, resetApps
}




