import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject, Subscription, throwError } from 'rxjs';
import { catchError, concatMap, first, flatMap, switchMap, tap } from 'rxjs/operators';
import { AppDataService } from './app-data.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptorService implements HttpInterceptor {

  private static accessTokenError$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private authService: AuthService, private router: Router, private appDataService: AppDataService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      const refreshToken = this.authService.getRefreshToken();
      if (err.status === 401) {
        if (!refreshToken) {
          return this.appDataService.isIntegration().pipe(
            first(),
            concatMap(data => {
              if (data) {
                return this.redirectToSessionExpiry(err);
              } else {
                return this.redirectToLogin(err);
              }
            })
          )
        }

        if (!ErrorInterceptorService.accessTokenError$.getValue()) {

          ErrorInterceptorService.accessTokenError$.next(true);

          return this.authService.refreshToken().pipe(
            switchMap((data: any) => {
              ErrorInterceptorService.accessTokenError$.next(false);
              let headers = request.headers.set('Authorization', `Bearer ${data.access_token}`);
              const clonedRequest = request.clone({ headers });
              return next.handle(clonedRequest);
            }),
            catchError(err => {
              return this.appDataService.isIntegration().pipe(
                first(),
                concatMap(data => {
                  if (data) {
                    return this.redirectToSessionExpiry(err);
                  } else {
                    return this.redirectToLogin(err);
                  }
                })
              )
            })
          )

        } else {
          // If it's not the firt error, it has to wait until get the access/refresh token
          return this.waitNewTokens().pipe(
            switchMap((event: any) => {
              // Clone the request with new Access Token
              const token = JSON.parse(this.authService.getToken());
              let headers = request.headers.set('Authorization', `Bearer ${token.value}`);
              const clonedRequest = request.clone({ headers });
              return next.handle(clonedRequest);
            })
          );
        }
      }

      return throwError(this.getErrorObj(err));
    }));
  }

  getErrorObj(error) {
    let errorObject: { message: any; } | string;
    if (error.error) {
      if (typeof error.error === 'string') {
        try {
          errorObject = JSON.parse(error.error)
        } catch {
          errorObject = error.error
        }
      }
      else {
        errorObject = error.error
      }
    }
    return errorObject;
  }

  // Wait until get the new access/refresh token
  private waitNewTokens(): Observable<any> {
    const subject = new Subject<any>();
    const waitToken$: Subscription = ErrorInterceptorService.accessTokenError$.subscribe((error: boolean) => {
      if (!error) {
        subject.next();
        subject.complete();
        waitToken$.unsubscribe();
      }
    });
    return subject.asObservable();
  }

  redirectToLogin(err) {
    this.authService.resetGlobal();
    this.authService.showErrorMessage(true, 'Your session has expired, please login again.');
    this.authService.isTokenExpired = true;
    this.router.navigateByUrl('login');
    return throwError(this.getErrorObj(err));
  }

  redirectToSessionExpiry(err) {
    this.authService.resetGlobal();
    this.authService.isTokenExpired = true;
    this.router.navigateByUrl('sessionTimeout');
    return throwError(this.getErrorObj(err));
  }
}
