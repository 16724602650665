import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserState } from '../reducers/user.reducer';

export const selectUserState = createFeatureSelector<UserState>('user');

export const userState = createSelector(selectUserState, state => state);

export const selectUserData = createSelector(selectUserState, state => state.userData);

export const userDataLoaded = createSelector(selectUserState, state => state.userDataLoaded);

export const userFacilityData = createSelector(selectUserState, state => state.userFacilityData);

export const selectLogoUrl = createSelector(selectUserState, state => state.userFacilityData?.logoUrl);

