import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class UnsubscribeService {

  constructor(private http: HttpClient, private configService: ConfigService) { }

  unsubscribe(unsubscribe, id, prescriber) {
    let type = prescriber ? '/prescriber/' : '/patients/';
    return this.http.post(this.configService.appconfig.apiurl + type + id + '/unsubscribe', {}, {
      params: {
        response: unsubscribe
      }
    });
  }
}
