import { Action, createReducer, on } from '@ngrx/store';
import { AppDataActions } from '../actions/app-data.actions';


export const appDataFeatureKey = 'appData';

export interface AppDataState {
  buildVersion,
  changePasswordModal,
  InfoModal,
  emis,
  smartLaunch,
  patientInfo,
  detailPageHeader,
  sso,
  showExtLink,
  partnerAPI,
  passwordExpiry
}

export const appDataInitialState: AppDataState = {
  buildVersion: null,
  changePasswordModal: false,
  InfoModal: false,
  emis: false,
  smartLaunch: false,
  patientInfo: {},
  detailPageHeader: null,
  sso: false,
  showExtLink: false,
  partnerAPI: null,
  passwordExpiry: null
};


export const AppDataReducer = createReducer(
  appDataInitialState,
  on(AppDataActions.buildVersionLoaded, (state, action) => {
    return {
      ...state,
      buildVersion: action.buildVersion
    }
  }),
  on(AppDataActions.showInfoModal, (state, action) => {
    return {
      ...state,
      InfoModal: action.InfoModal
    }
  }),
  on(AppDataActions.showChangePasswordModal, (state, action) => {
    return {
      ...state,
      changePasswordModal: action.changePasswordModal
    }
  }),
  on(AppDataActions.setEmis, (state, action) => {
    return {
      ...state,
      emis: true
    }
  }),
  on(AppDataActions.setSmartLaunch, (state, action) => {
    return {
      ...state,
      smartLaunch: true
    }
  }),
  on(AppDataActions.setSso, (state, action) => {
    return {
      ...state,
      sso: true
    }
  }),
  on(AppDataActions.setPatientInfo, (state, action) => {
    return {
      ...state,
      patientInfo: action.patientInfo
    }
  }),
  on(AppDataActions.setTitle, (state, action) => {
    return {
      ...state,
      detailPageHeader: action.pageHeader
    }
  }),
  on(AppDataActions.showExtLink, (state, action) => {
    return {
      ...state,
      showExtLink: action.showExtLink
    }
  }),
  on(AppDataActions.partnerAPI, (state, action) => {
    return {
      ...state,
      partnerAPI: action.partnerApiEnabled
    }
  }),
  on(AppDataActions.setPasswordExpiry, (state, action) => {
    return {
      ...state,
      passwordExpiry: action.passwordExpiry
    }
  })
);

