import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppDataState } from '../reducers/app-data.reducer';

export const selectAppData = createFeatureSelector<AppDataState>('appData');

export const getAppData = createSelector(selectAppData, state => state);

export const buildVersion = createSelector(selectAppData, state => state.buildVersion);

export const selectEmis = createSelector(selectAppData, state => state.emis);

export const selectSmartlaunch = createSelector(selectAppData, state => state.smartLaunch);

export const selectPartnerAPI = createSelector(selectAppData, state => state.partnerAPI);

export const selectChangePasswordModal = createSelector(selectAppData, state => state.changePasswordModal);

export const selectDetailPageHeader = createSelector(selectAppData, state => state.detailPageHeader);

export const selectInfoModal = createSelector(selectAppData, state => state.InfoModal);

export const selectExtLink = createSelector(selectAppData, state => state.showExtLink);

export const selectPasswordExpiry = createSelector(selectAppData, state => state.passwordExpiry);
