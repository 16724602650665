import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { GlobalConstants } from "./core/constants/global.constants";
import { AuthenticationGuard } from "./core/guards/auth.guard";
import { HistoryGuard } from "./core/guards/history.guard";
import { IntegrationGuard } from "./core/guards/integration.guard";
import { LoginResolver } from "./core/guards/login.resolver";
import { UserFacilityDataResolver } from "./core/guards/user-facility-data-resolver";
import { FooterComponent } from "./layout/footer/footer.component";
import { RegisterHeaderComponent } from "./layout/register-header/register-header.component";
import { RegisterComponent } from "./layout/register/register.component";
import { LearnmoreComponent } from "./layout/learnmore/learnmore.component";
import { EmisAcceptedTosComponent } from "./shared/components/emis-accepted-tos/emis-accepted-tos.component";
import { UnsubscribeComponent } from "./layout/unsubscribe/unsubscribe.component";
import { PatientPrivacyComponent } from "./layout/patient-privacy/patient-privacy.component";
import { PartnerApiInstructionComponent } from './shared/components/partner-api-instruction/partner-api-instruction.component';

const routes: Routes = [
  { path: "", redirectTo: "register", pathMatch: "full" },
  {
    path: "register",
    component: RegisterComponent,
    resolve: { resolvedData: LoginResolver },
  },
  {
    path: "learnmore",
    component: LearnmoreComponent,
  },
  {
    outlet: "header",
    path: "register",
    component: RegisterHeaderComponent,
  },
  {
    path: "footer",
    component: FooterComponent,
    canActivate: [AuthenticationGuard]
  },  
  {
    path: GlobalConstants.integrationPaths.acceptedtos,
    canActivate: [IntegrationGuard],
    component: EmisAcceptedTosComponent,
  },
  {
    path: GlobalConstants.integrationPaths.emis,
    canActivate: [IntegrationGuard],
    loadChildren: () =>
      import("./modules/dashboard/dashboard.module").then(
        (m) => m.DashboardModule
      ),
  },
  {
    path: GlobalConstants.integrationPaths.smartLaunch,
    canActivate: [IntegrationGuard],
    loadChildren: () =>
      import("./modules/dashboard/dashboard.module").then(
        (m) => m.DashboardModule
      ),
  },
  {
    path: GlobalConstants.integrationPaths.sso,
    canActivate: [IntegrationGuard],
    loadChildren: () =>
      import("./modules/dashboard/dashboard.module").then(
        (m) => m.DashboardModule
      ),
  },
  {
    path: "login",
    resolve: { resolvedData: LoginResolver },
    loadChildren: () =>
      import("./modules/login/login.module").then((m) => m.LoginModule),
  },
  {
    path: ":userType/unsubscribe/:id",
    component: UnsubscribeComponent
  },
  {
    path: "dashboard",
    loadChildren: () =>
      import("./modules/dashboard/dashboard.module").then((m) => m.DashboardModule),
    canActivate: [AuthenticationGuard],
    resolve: { UserFacilityDataResolver }
  },
  {
    path: "detail",
    loadChildren: () =>
      import("./modules/detail/detail.module").then((m) => m.DetailModule),
    canActivate: [AuthenticationGuard],
    resolve: { UserFacilityDataResolver }
  },
  {
    path: "patients",
    loadChildren: () =>
      import("./modules/history/history.module").then((m) => m.HistoryModule),
    canActivate: [AuthenticationGuard, HistoryGuard],
    resolve: { UserFacilityDataResolver }
  },
  {
    path: "patient/:id",
    loadChildren: () =>
      import("./modules/patient-history/patient-history.module").then((m) => m.PatientHistoryModule),
    canActivate: [AuthenticationGuard],
    resolve: { UserFacilityDataResolver }
  },
  {
    path: "search",
    loadChildren: () =>
      import("./modules/search/search.module").then((m) => m.SearchModule),
    canActivate: [AuthenticationGuard],
    resolve: { UserFacilityDataResolver }
  },
  {
    path: "prescribe/:type/:id",
    loadChildren: () =>
      import("./modules/prescribe/prescribe.module").then((m) => m.PrescribeModule),
    canActivate: [AuthenticationGuard],
    resolve: { UserFacilityDataResolver }
  },
  {
    path: "user/:id/reset",
    loadChildren: () =>
      import("./modules/resetpassword/reset.module").then(
        (m) => m.ResetModule
      ),
  },
  {
    path: "sessionTimeout",
    loadChildren: () =>
      import("./modules/session-timeout/session-timeout.module").then(
        (m) => m.SessionTimeoutModule
      ),
  },
  {
    path: 'questions',
    loadChildren: () =>
      import("./modules/ecrf/ecrf.module").then(
        (m) => m.EcrfModule
      ),
  },
  {
    path: 'Policies/:countrycode/patientprivacypolicy',
    component: PatientPrivacyComponent,
  },
  { path: 'cpApps', loadChildren: () => import('./modules/cp-apps-loader/cp-apps-loader.module').then(m => m.CpAppsLoaderModule) },
  {
    path: 'PartnerApiInstruction',
    component: PartnerApiInstructionComponent,
  },
   { path: "**", redirectTo: "register" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
