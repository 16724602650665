import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-partner-api-instruction',
  templateUrl: './partner-api-instruction.component.html',
  styleUrls: ['./partner-api-instruction.component.scss']
})
export class PartnerApiInstructionComponent implements OnInit {
  pdfURL: any;

  constructor(private domSanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.pdfURL = this.domSanitizer.bypassSecurityTrustResourceUrl("https://appscriptcdnqa.blob.core.windows.net/prescriber/assets/images/PartnerInstruction/PartnerAPI_Instruction.pdf");
  }

  downloadPartnerApiInstructionPDF() {
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', '/assets/images/PartnerInstruction/PartnerAPI_Instruction.pdf');
    link.setAttribute('download', 'PartnerAPI_Instruction.pdf');
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

}
