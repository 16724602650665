import { Action, createReducer, on } from '@ngrx/store';
import { AppsActions } from '../actions/apps.actions';


export const appsFeatureKey = 'apps';

export interface AppsState {
  featuredApps,
  favoriteApps,
  remainingIds,
  selectedIds,
  recommendedApps
}

export const appsInitialState: AppsState = {
  featuredApps: null,
  favoriteApps: null,
  remainingIds: null,
  selectedIds: null,
  recommendedApps: null
};


export const appsReducer = createReducer(
  appsInitialState,
  on(AppsActions.featuredAppsLoaded, (state, action) => {
    return {
      ...state,
      featuredApps: action.featuredApps
    }
  }),
  on(AppsActions.favoriteAppsLoaded, (state, action) => {
    return {
      ...state,
      favoriteApps: action.favoriteApps
    }
  }),
  on(AppsActions.remainingIds, (state, action) => {
    return {
      ...state,
      remainingIds: action.remainingIds
    }
  }),
  on(AppsActions.selectedIds, (state, action) => {
    return {
      ...state,
      selectedIds: state.selectedIds ? [...state.selectedIds, ...action.selectedIds] : [...action.selectedIds]
    }
  }),
  on(AppsActions.recommendedAppsLoaded, (state, action) => {
    return {
      ...state,
      recommendedApps: [...action.recommendedAppsGroup]
    }
  }),
  on(AppsActions.moreRecommendedAppsLoaded, (state, action) => {
    return {
      ...state,
      recommendedApps: action.moreRecommendedAppsGroup ? [...state.recommendedApps, ...action.moreRecommendedAppsGroup] : [...state.recommendedApps]
    }
  }),
  on(AppsActions.addFavorite, (state, action) => {
    return {
      ...state,
      favoriteApps: [...state.favoriteApps, action.app]
    }
  }),
  on(AppsActions.removeFavorite, (state, action) => {
    return {
      ...state,
      favoriteApps: state.favoriteApps.filter(app => app.id !== action.app.id)
    }
  }),
  on(AppsActions.resetApps, (state, action) => {
    return {
      ...state,
      featuredApps: null,
      favoriteApps: null,
      recommendedApps: null
    }
  })
);

