<header *ngIf="!isLogin">
  <app-register-header *ngIf="(isRegister) else header"></app-register-header>
  <app-patient-privacy-header *ngIf="patientPrivacyPolicy"></app-patient-privacy-header>
  <app-footer-header *ngIf="isFooter"></app-footer-header>
  <app-learnmore-header *ngIf="isLearnMore"></app-learnmore-header>
  <app-partner-api-header  *ngIf="isPartnerApi"></app-partner-api-header>
  <ng-template #header>
    <router-outlet name="header"> </router-outlet>
  </ng-template>
  <hr class="separator" />
  <ng-template [ngIf]="!isRegister">
    <router-outlet name="subheader"></router-outlet>
  </ng-template>
</header>

<main [ngClass]="(isRegister || isLearnMore || isEcrfFrame) ? 'container-fluid' : 'container'">
  <router-outlet></router-outlet>
</main>

<footer class="footer" *ngIf="isRegister || isSSOLogin">
  <app-footer *ngIf="(isRegister || isSSOLogin) else footer" [stickyFooter]="true"></app-footer>
  <ng-template #footer>
    <router-outlet name="footer"> </router-outlet>
  </ng-template>
</footer>

<ngx-ui-loader [fgsTemplate]="foregroundSpinner"></ngx-ui-loader>
<ng-template #foregroundSpinner>
  <div class="loader">
    <div class="clear-loading loading-effect-1">
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
</ng-template>

<app-change-password #changePassword></app-change-password>
<app-info-modal #infoModal></app-info-modal>
<app-toast aria-live="polite" aria-atomic="true"></app-toast>
