export const environment = {
  production: false,
  debugmode: false,
  apibaseurl: '',
  ga: 'G-ZNH3WNTCH7',
  sourcePath: "prescriber",
  cdnUrl: 'https://appscriptcdndev.blob.core.windows.net/prescriber',
  cpAppsEnabled: true,
  encryptionKey:"1A19DE91EC7AE72173C74822688710BE",
  name: 'dev'
};
