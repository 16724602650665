export interface GroupTitle {
  type: number,
  shortDescription?: string,
  longDescription?: boolean,
  subText?: string,
  viewAll?: boolean
}

export enum GroupType {
  featured,
  favorites,
  recommended,
  carePoint
}

export enum GroupIcon {
  'far fa-bookmark',
  'far fa-heart',
  'far fa-tag',
  'fas fa-user-md'
}

export enum ViewAllGroup {
  'Apps',
  'Devices',
  'Digital Content'
}


