export const GlobalConstants = {

  regions: [{ id: 1, 'Code': 'US', 'Country': 'United States', 'currencysymbol': "$", 'currencytext': "USD" },
  { id: 2, 'Code': 'GB', 'Country': 'United Kingdom', 'currencysymbol': "£", 'currencytext': "GBP" },
  { id: 3, 'Code': 'AE', 'Country': 'United Arab Emirates', 'currencysymbol': null, 'currencytext': "AED" }],
  languagecodesmap: [
    { "lang": "ab", "name": "Abkhaz", "nativeName": null },
    { "lang": "aa", "name": "Afar", "nativeName": "Afaraf" },
    { "lang": "af", "name": "Afrikaans", "nativeName": "Afrikaans" },
    { "lang": "ak", "name": "Akan", "nativeName": "Akan" },
    { "lang": "sq", "name": "Albanian", "nativeName": "Shqip" },
    { "lang": "am", "name": "Amharic", "nativeName": null },
    { "lang": "ar", "name": "Arabic", "nativeName": null },
    { "lang": "an", "name": "Aragonese", "nativeName": "Aragonés" },
    { "lang": "hy", "name": "Armenian", "nativeName": null },
    { "lang": "as", "name": "Assamese", "nativeName": null },
    { "lang": "av", "name": "Avaric", "nativeName": null },
    { "lang": "ae", "name": "Avestan", "nativeName": "avesta" },
    { "lang": "ay", "name": "Aymara", "nativeName": "aymar aru" },
    { "lang": "az", "name": "Azerbaijani", "nativeName": "azrbaycan dili" },
    { "lang": "bm", "name": "Bambara", "nativeName": "bamanankan" },
    { "lang": "ba", "name": "Bashkir", "nativeName": null },
    { "lang": "eu", "name": "Basque", "nativeName": "euskara, euskera" },
    { "lang": "be", "name": "Belarusian", "nativeName": null },
    { "lang": "bn", "name": "Bengali", "nativeName": null },
    { "lang": "bh", "name": "Bihari", "nativeName": null },
    { "lang": "bi", "name": "Bislama", "nativeName": "Bislama" },
    { "lang": "bs", "name": "Bosnian", "nativeName": "bosanski jezik" },
    { "lang": "br", "name": "Breton", "nativeName": "brezhoneg" },
    { "lang": "bg", "name": "Bulgarian", "nativeName": null },
    { "lang": "my", "name": "Burmese", "nativeName": null },
    { "lang": "ca", "name": "Catalan; Valencian", "nativeName": "Català" },
    { "lang": "ch", "name": "Chamorro", "nativeName": "Chamoru" },
    { "lang": "ce", "name": "Chechen", "nativeName": null },
    { "lang": "ny", "name": "Chichewa; Chewa; Nyanja", "nativeName": "chiChewa, chinyanja" },
    { "lang": "zh", "name": "Chinese", "nativeName": null },
    { "lang": "cv", "name": "Chuvash", "nativeName": null },
    { "lang": "kw", "name": "Cornish", "nativeName": "Kernewek" },
    { "lang": "co", "name": "Corsican", "nativeName": "corsu, lingua corsa" },
    { "lang": "cr", "name": "Cree", "nativeName": null },
    { "lang": "hr", "name": "Croatian", "nativeName": "hrvatski" },
    { "lang": "cs", "name": "Czech", "nativeName": "cesky, ceština" },
    { "lang": "da", "name": "Danish", "nativeName": "dansk" },
    { "lang": "dv", "name": "Divehi; Dhivehi; Maldivian;", "nativeName": null },
    { "lang": "nl", "name": "Dutch", "nativeName": "Nederlands, Vlaams" },
    { "lang": "en", "name": "English", "nativeName": "English" },
    { "lang": "eo", "name": "Esperanto", "nativeName": "Esperanto" },
    { "lang": "et", "name": "Estonian", "nativeName": "eesti, eesti keel" },
    { "lang": "ee", "name": "Ewe", "nativeName": null },
    { "lang": "fo", "name": "Faroese", "nativeName": "føroyskt" },
    { "lang": "fj", "name": "Fijian", "nativeName": "vosa Vakaviti" },
    { "lang": "fi", "name": "Finnish", "nativeName": "suomi, suomen kieli" },
    { "lang": "fr", "name": "French", "nativeName": "français, langue française" },
    { "lang": "ff", "name": "Fula; Fulah; Pulaar; Pular", "nativeName": "Fulfulde, Pulaar, Pular" },
    { "lang": "gl", "name": "Galician", "nativeName": "Galego" },
    { "lang": "ka", "name": "Georgian", "nativeName": null },
    { "lang": "de", "name": "German", "nativeName": "Deutsch" },
    { "lang": "el", "name": "Greek", "nativeName": null },
    { "lang": "gn", "name": "Guaraní", "nativeName": "Avañe" },
    { "lang": "gu", "name": "Gujarati", "nativeName": null },
    { "lang": "ht", "name": "Haitian; Haitian Creole", "nativeName": "Kreyòl ayisyen" },
    { "lang": "ha", "name": "Hausa", "nativeName": "Hausa, " },
    { "lang": "he", "name": "Hebrew", "nativeName": null },
    { "lang": "hz", "name": "Herero", "nativeName": "Otjiherero" },
    { "lang": "hi", "name": "Hindi", "nativeName": null },
    { "lang": "ho", "name": "Hiri Motu", "nativeName": "Hiri Motu" },
    { "lang": "hu", "name": "Hungarian", "nativeName": "Magyar" },
    { "lang": "ia", "name": "Interlingua", "nativeName": "Interlingua" },
    { "lang": "id", "name": "Indonesian", "nativeName": "Bahasa Indonesia" },
    { "lang": "ie", "name": "Interlingue", "nativeName": "Originally called Occidental; then Interlingue after WWII" },
    { "lang": "ga", "name": "Irish", "nativeName": "Gaeilge" },
    { "lang": "ig", "name": "Igbo", "nativeName": "Ass Igbo" },
    { "lang": "ik", "name": "Inupiaq", "nativeName": "Iñupiaq, Iñupiatun" },
    { "lang": "io", "name": "Ido", "nativeName": "Ido" },
    { "lang": "is", "name": "Icelandic", "nativeName": "Íslenska" },
    { "lang": "it", "name": "Italian", "nativeName": "Italiano" },
    { "lang": "iu", "name": "Inuktitut", "nativeName": null },
    { "lang": "ja", "name": "Japanese", "nativeName": null },
    { "lang": "jv", "name": "Javanese", "nativeName": "basa Jawa" },
    { "lang": "kl", "name": "Kalaallisut, Greenlandic", "nativeName": "kalaallisut, kalaallit oqaasii" },
    { "lang": "kn", "name": "Kannada", "nativeName": null },
    { "lang": "kr", "name": "Kanuri", "nativeName": "Kanuri" },
    { "lang": "ks", "name": "Kashmiri", "nativeName": null },
    { "lang": "kk", "name": "Kazakh", "nativeName": null },
    { "lang": "km", "name": "Khmer", "nativeName": null },
    { "lang": "ki", "name": "Kikuyu, Gikuyu", "nativeName": "Gikuyu" },
    { "lang": "rw", "name": "Kinyarwanda", "nativeName": "Ikinyarwanda" },
    { "lang": "ky", "name": "Kirghiz, Kyrgyz", "nativeName": " " },
    { "lang": "kv", "name": "Komi", "nativeName": null },
    { "lang": "kg", "name": "Kongo", "nativeName": "KiKongo" },
    { "lang": "ko", "name": "Korean", "nativeName": null },
    { "lang": "ku", "name": "Kurdish", "nativeName": "Kurdî" },
    { "lang": "kj", "name": "Kwanyama, Kuanyama", "nativeName": "Kuanyama" },
    { "lang": "la", "name": "Latin", "nativeName": "latine, lingua latina" },
    { "lang": "lb", "name": "Luxembourgish, Letzeburgesch", "nativeName": "Lëtzebuergesch" },
    { "lang": "lg", "name": "Luganda", "nativeName": "Luganda" },
    { "lang": "li", "name": "Limburgish, Limburgan, Limburger", "nativeName": "Limburgs" },
    { "lang": "ln", "name": "Lingala", "nativeName": "Lingála" },
    { "lang": "lo", "name": "Lao", "nativeName": null },
    { "lang": "lt", "name": "Lithuanian", "nativeName": "lietuviu kalba" },
    { "lang": "lu", "name": "Luba-Katanga", "nativeName": null },
    { "lang": "lv", "name": "Latvian", "nativeName": "latviešu valoda" },
    { "lang": "gv", "name": "Manx", "nativeName": "Gaelg, Gailck" },
    { "lang": "mk", "name": "Macedonian", "nativeName": null },
    { "lang": "mg", "name": "Malagasy", "nativeName": "Malagasy fiteny" },
    { "lang": "ms", "name": "Malay", "nativeName": "bahasa Melayu" },
    { "lang": "ml", "name": "Malayalam", "nativeName": null },
    { "lang": "mt", "name": "Maltese", "nativeName": "Malti" },
    { "lang": "mi", "name": "Maori", "nativeName": "te reo Maori" },
    { "lang": "mr", "name": "Marathi", "nativeName": null },
    { "lang": "mh", "name": "Marshallese", "nativeName": "Kajin M¸ajel" },
    { "lang": "mn", "name": "Mongolian", "nativeName": null },
    { "lang": "na", "name": "Nauru", "nativeName": "Ekakairu Naoero" },
    { "lang": "nv", "name": "Navajo, Navaho", "nativeName": "Diné bizaad, Dinék'ehjí" },
    { "lang": "nb", "name": "Norwegian Bokmål", "nativeName": "Norsk bokmål" },
    { "lang": "nd", "name": "North Ndebele", "nativeName": "isiNdebele" },
    { "lang": "ne", "name": "Nepali", "nativeName": null },
    { "lang": "ng", "name": "Ndonga", "nativeName": "Owambo" },
    { "lang": "nn", "name": "Norwegian Nynorsk", "nativeName": "Norsk nynorsk" },
    { "lang": "no", "name": "Norwegian", "nativeName": "Norsk" },
    { "lang": "ii", "name": "Nuosu", "nativeName": " Nuosuhxop" },
    { "lang": "nr", "name": "South Ndebele", "nativeName": "isiNdebele" },
    { "lang": "oc", "name": "Occitan", "nativeName": "Occitan" },
    { "lang": "oj", "name": "Ojibwe, Ojibwa", "nativeName": null },
    { "lang": "cu", "name": "Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic", "nativeName": null },
    { "lang": "om", "name": "Oromo", "nativeName": "Afaan Oromoo" },
    { "lang": "or", "name": "Oriya", "nativeName": null },
    { "lang": "os", "name": "Ossetian, Ossetic", "nativeName": null },
    { "lang": "pa", "name": "Panjabi, Punjabi", "nativeName": null },
    { "lang": "pi", "name": "Pali", "nativeName": null },
    { "lang": "fa", "name": "Persian", "nativeName": null },
    { "lang": "pl", "name": "Polish", "nativeName": "polski" },
    { "lang": "ps", "name": "Pashto, Pushto", "nativeName": null },
    { "lang": "pt", "name": "Portuguese", "nativeName": "Português" },
    { "lang": "qu", "name": "Quechua", "nativeName": "Runa Simi, Kichwa" },
    { "lang": "rm", "name": "Romansh", "nativeName": "rumantsch grischun" },
    { "lang": "rn", "name": "Kirundi", "nativeName": "kiRundi" },
    { "lang": "ro", "name": "Romanian, Moldavian, Moldovan", "nativeName": "româna" },
    { "lang": "ru", "name": "Russian", "nativeName": null },
    { "lang": "sa", "name": "Sanskrit", "nativeName": null },
    { "lang": "sc", "name": "Sardinian", "nativeName": "sardu" },
    { "lang": "sd", "name": "Sindhi", "nativeName": null },
    { "lang": "se", "name": "Northern Sami", "nativeName": "Davvisámegiella" },
    { "lang": "sm", "name": "Samoan", "nativeName": "gagana faa Samoa" },
    { "lang": "sg", "name": "Sango", "nativeName": "yângâ tî sängö" },
    { "lang": "sr", "name": "Serbian", "nativeName": null },
    { "lang": "gd", "name": "Scottish Gaelic; Gaelic", "nativeName": "Gàidhlig" },
    { "lang": "sn", "name": "Shona", "nativeName": "chiShona" },
    { "lang": "si", "name": "Sinhala, Sinhalese", "nativeName": null },
    { "lang": "sk", "name": "Slovak", "nativeName": "slovencina" },
    { "lang": "sl", "name": "Slovene", "nativeName": "slovenšcina" },
    { "lang": "so", "name": "Somali", "nativeName": "Soomaaliga, af Soomaali" },
    { "lang": "st", "name": "Southern Sotho", "nativeName": "Sesotho" },
    { "lang": "es", "name": "Spanish (Spain)", "nativeName": "español, castellano" },
    { "lang": "su", "name": "Sundanese", "nativeName": "Basa Sunda" },
    { "lang": "sw", "name": "Swahili", "nativeName": "Kiswahili" },
    { "lang": "ss", "name": "Swati", "nativeName": "SiSwati" },
    { "lang": "sv", "name": "Swedish", "nativeName": "svenska" },
    { "lang": "ta", "name": "Tamil", "nativeName": null },
    { "lang": "te", "name": "Telugu", "nativeName": null },
    { "lang": "tg", "name": "Tajik", "nativeName": ", togiki" },
    { "lang": "th", "name": "Thai", "nativeName": null },
    { "lang": "ti", "name": "Tigrinya", "nativeName": null },
    { "lang": "bo", "name": "Tibetan Standard, Tibetan, Central", "nativeName": null },
    { "lang": "tk", "name": "Turkmen", "nativeName": "Türkmen" },
    { "lang": "tl", "name": "Tagalog", "nativeName": "Wikang Tagalog" },
    { "lang": "tn", "name": "Tswana", "nativeName": "Setswana" },
    { "lang": "to", "name": "Tonga (Tonga Islands)", "nativeName": "faka Tonga" },
    { "lang": "tr", "name": "Turkish", "nativeName": "Türkçe" },
    { "lang": "ts", "name": "Tsonga", "nativeName": "Xitsonga" },
    { "lang": "tt", "name": "Tatar", "nativeName": ", tatarça" },
    { "lang": "tw", "name": "Twi", "nativeName": "Twi" },
    { "lang": "ty", "name": "Tahitian", "nativeName": "Reo Tahiti" },
    { "lang": "ug", "name": "Uighur, Uyghur", "nativeName": "Uyurq" },
    { "lang": "uk", "name": "Ukrainian", "nativeName": null },
    { "lang": "ur", "name": "Urdu", "nativeName": null },
    { "lang": "uz", "name": "Uzbek", "nativeName": "zbek" },
    { "lang": "ve", "name": "Venda", "nativeName": "Tshivena" },
    { "lang": "vi", "name": "Vietnamese", "nativeName": "Ting Vit" },
    { "lang": "vo", "name": "Volapük", "nativeName": "Volapük" },
    { "lang": "wa", "name": "Walloon", "nativeName": "Walon" },
    { "lang": "cy", "name": "Welsh", "nativeName": "Cymraeg" },
    { "lang": "wo", "name": "Wolof", "nativeName": "Wollof" },
    { "lang": "fy", "name": "Western Frisian", "nativeName": "Frysk" },
    { "lang": "xh", "name": "Xhosa", "nativeName": "isiXhosa" },
    { "lang": "yi", "name": "Yiddish", "nativeName": null },
    { "lang": "yo", "name": "Yoruba", "nativeName": "Yorùbá" },
    { "lang": "za", "name": "Zhuang, Chuang", "nativeName": "Sa cue, Saw cuengh" },
    { "lang": "ar-sa", "name": "Arabic (Saudi Arabia)", "nativeName": null },
    { "lang": "ar-iq", "name": "Arabic (Iraq)", "nativeName": null },
    { "lang": "ar-eg", "name": "Arabic (Egypt)", "nativeName": null },
    { "lang": "ar-ly", "name": "Arabic (Libya)", "nativeName": null },
    { "lang": "ar-dz", "name": "Arabic (Algeria)", "nativeName": null },
    { "lang": "ar-ma", "name": "Arabic (Morocco)", "nativeName": null },
    { "lang": "ar-tn", "name": "Arabic (Tunisia)", "nativeName": null },
    { "lang": "ar-om", "name": "Arabic (Oman)", "nativeName": null },
    { "lang": "ar-ye", "name": "Arabic (Yemen)", "nativeName": null },
    { "lang": "ar-sy", "name": "Arabic (Syria)", "nativeName": null },
    { "lang": "ar-jo", "name": "Arabic (Jordan)", "nativeName": null },
    { "lang": "ar-lb", "name": "Arabic (Lebanon)", "nativeName": null },
    { "lang": "ar-kw", "name": "Arabic (Kuwait)", "nativeName": null },
    { "lang": "ar-ae", "name": "Arabic (U.A.E.)", "nativeName": null },
    { "lang": "ar-bh", "name": "Arabic (Bahrain)", "nativeName": null },
    { "lang": "ar-qa", "name": "Arabic (Qatar)", "nativeName": null },
    { "lang": "zh-tw", "name": "Chinese (Taiwan)", "nativeName": " (Zhongwén)" },
    { "lang": "zh-cn", "name": "Chinese (PRC)", "nativeName": " (Zhongwén)" },
    { "lang": "zh-hk", "name": "Chinese (Hong Kong SAR)", "nativeName": " (Zhongwén)" },
    { "lang": "zh-sg", "name": "Chinese (Singapore)", "nativeName": " (Zhongwén)" },
    { "lang": "nl-be", "name": "Dutch (Belgium)", "nativeName": "Nederlands, Vlaams" },
    { "lang": "en-us", "name": "English (United States)", "nativeName": "English" },
    { "lang": "en-gb", "name": "English (United Kingdom)", "nativeName": "English" },
    { "lang": "en-au", "name": "English (Australia)", "nativeName": "English" },
    { "lang": "en-canull", "name": "English (Canada)", "nativeName": "English" },
    { "lang": "en-nz", "name": "English (New Zealand)", "nativeName": "English" },
    { "lang": "en-ie", "name": "English (Ireland)", "nativeName": "English" },
    { "lang": "en-za", "name": "English (South Africa)", "nativeName": "English" },
    { "lang": "en-jm", "name": "English (Jamaica)", "nativeName": "English" },
    { "lang": "en-bz", "name": "English (Belize)", "nativeName": "English" },
    { "lang": "en-tt", "name": "English (Trinidad)", "nativeName": "English" },
    { "lang": "fr-be", "name": "French (Belgium)", "nativeName": "français, langue française" },
    { "lang": "fr-ca", "name": "French (Canada)", "nativeName": "français, langue française" },
    { "lang": "fr-ch", "name": "French (Switzerland)", "nativeName": "français, langue française" },
    { "lang": "fr-lu", "name": "French (Luxembourg)", "nativeName": "français, langue française" },
    { "lang": "de-ch", "name": "German (Switzerland)", "nativeName": "Deutsch" },
    { "lang": "de-at", "name": "German (Austria)", "nativeName": "Deutsch" },
    { "lang": "de-lu", "name": "German (Luxembourg)", "nativeName": "Deutsch" },
    { "lang": "de-li", "name": "German (Liechtenstein)", "nativeName": "Deutsch" },
    { "lang": "it-ch", "name": "Italian (Switzerland)", "nativeName": "Italiano" },
    { "lang": "pt-br", "name": "Portuguese (Brazil)", "nativeName": "Português" },
    { "lang": "ro-mo", "name": "Romanian(Republic of Moldova)", "nativeName": null },
    { "lang": "ru-mo", "name": "Russian(Republic of Moldova)", "nativeName": null },
    { "lang": "es-mx", "name": "Spanish (Mexico)", "nativeName": "español, castellano" },
    { "lang": "es-gt", "name": "Spanish (Guatemala)", "nativeName": "español, castellano" },
    { "lang": "es-cr", "name": "Spanish (Costa Rica)", "nativeName": "español, castellano" },
    { "lang": "es-pa", "name": "Spanish (Panama)", "nativeName": "español, castellano" },
    { "lang": "es-do", "name": "Spanish (Dominican Republic)", "nativeName": "español, castellano" },
    { "lang": "es-ve", "name": "Spanish (Venezuela)", "nativeName": "español, castellano" },
    { "lang": "es-co", "name": "Spanish (Colombia)", "nativeName": "español, castellano" },
    { "lang": "es-pe", "name": "Spanish (Peru)", "nativeName": "español, castellano" },
    { "lang": "es-ar", "name": "Spanish (Argentina)", "nativeName": "español, castellano" },
    { "lang": "es-ec", "name": "Spanish (Ecuador)", "nativeName": "español, castellano" },
    { "lang": "es-cl", "name": "Spanish (Chile)", "nativeName": "español, castellano" },
    { "lang": "es-uy", "name": "Spanish (Uruguay)", "nativeName": "español, castellano" },
    { "lang": "es-py", "name": "Spanish (Paraguay)", "nativeName": "español, castellano" },
    { "lang": "es-bo", "name": "Spanish (Bolivia)", "nativeName": "español, castellano" },
    { "lang": "es-sv", "name": "Spanish (El Salvador)", "nativeName": "español, castellano" },
    { "lang": "es-hn", "name": "Spanish (Honduras)", "nativeName": "español, castellano" },
    { "lang": "es-ni", "name": "Spanish (Nicaragua)", "nativeName": "español, castellano" },
    { "lang": "es-pr", "name": "Spanish (Puerto Rico)", "nativeName": "español, castellano" },
    { "lang": "sv-fi", "name": "Swedish (Finland)", "nativeName": "svenska" }
  ],

  filterData: {
    platformWithWeb: {
      id: 'platform',
      label: 'platform',
      data: [{
        id: 0,
        title: 'All',
        parentId: 'platform'
      }, {
        id: 1,
        title: '<i class="fab fa-apple small-icon pe-2"></i><span>iOS</span>',
        parentId: 'platform'
      }, {
        id: 2,
        title: '<i class="fab fa-android small-icon pe-2"></i><span>Android</span>',
        parentId: 'platform'
      }, {
        id: 3,
        title: '<i class="fas fa-desktop small-icon pe-2"></i><span>WEB</span>',
        parentId: 'platform'
      }]
    },
    platformWithoutWeb: {
      id: 'platform',
      label: 'platform',
      data: [{
        id: 0,
        title: 'All',
        parentId: 'platform'
      }, {
        id: 1,
        title: '<i class="fab fa-apple small-icon pe-2"></i><span>iOS</span>',
        parentId: 'platform'
      }, {
        id: 2,
        title: '<i class="fab fa-android small-icon pe-2"></i><span>Android</span>',
        parentId: 'platform'
      }]
    },
    priceType: {
      id: 'priceType',
      label: 'price',
      data: [{
        id: 0,
        title: 'All',
        parentId: 'priceType',
      }, {
        id: 1,
        title: 'Free',
        parentId: 'priceType',
      }, {
        id: 2,
        title: 'Freemium',
        parentId: 'priceType',
      }, {
        id: 3,
        title: 'Paid',
        parentId: 'priceType',
      }]
    },
    categoryId: {
      id: 'categoryId',
      label: 'CATEGORIES',
      data: [{
        id: 0,
        title: 'All',
        parentId: 'categoryId'
      }]
    },
    priceRange: {
      id: 'priceRange',
      label: 'PRICE RANGE',
      data: [{
        id: 0,
        title: 'All',
        parentId: 'priceRange'
      }, {
        id: 1,
        title: '< $50',
        parentId: 'priceRange'
      }, {
        id: 2,
        title: '$50 - $100',
        parentId: 'priceRange'
      }, {
        id: 3,
        title: '$100 - $150',
        parentId: 'priceRange'
      }, {
        id: 4,
        title: '$150 - $200',
        parentId: 'priceRange'
      }, {
        id: 5,
        title: '$200 - $250',
        parentId: 'priceRange'
      }, {
        id: 6,
        title: '$250 - $500',
        parentId: 'priceRange'
      }, {
        id: 7,
        title: '> $500',
        parentId: 'priceRange'
      }]
    },
    contentPurpose: {
      id: 'contentPurpose',
      label: 'CONTENT PURPOSE',
      data: [{
        id: 0,
        title: 'All',
        parentId: 'contentPurpose'
      }, {
        id: 1,
        title: 'Disease Prevention',
        parentId: 'contentPurpose'
      }, {
        id: 2,
        title: 'Intervention',
        parentId: 'contentPurpose'
      }, {
        id: 3,
        title: 'Health Promotion',
        parentId: 'contentPurpose'
      }, {
        id: 4,
        title: 'Rehabilitation/Recovery',
        parentId: 'contentPurpose'
      }]
    }
  },

  sortOptions: {
    apps:{
      '0': {
        label: 'Score: <strong>Highest</strong>',
        sort: 1,
        ascending: false
      },
      '1': {
        label: 'Score: <strong>Lowest</strong>',
        sort: 1,
        ascending: true
      },
      '2': {
        label: 'Name: <strong>A-Z</strong>',
        sort: 0,
        ascending: true
      },
      '3': {
        label: 'Name: <strong>Z-A</strong>',
        sort: 0,
        ascending: false
      },
      '4': {
        label: 'Price: <strong>Highest</strong>',
        sort: 4,
        ascending: false
      },
      '5': {
        label: 'Price: <strong>Lowest</strong>',
        sort: 4,
        ascending: true
      }   
    },
    devices:{
      '0': {
        label: 'Name: <strong>A-Z</strong>',
        sort: 0,
        ascending: true
      },
      '1': {
        label: 'Name: <strong>Z-A</strong>',
        sort: 0,
        ascending: false
      },
      '2': {
        label: 'Price: <strong>Highest</strong>',
        sort: 4,
        ascending: false
      },
      '3': {
        label: 'Price: <strong>Lowest</strong>',
        sort: 4,
        ascending: true
      }
    },
    content:{
      '0': {
        label: 'Name: <strong>A-Z</strong>',
        sort: 0,
        ascending: true
      },
      '1': {
        label: 'Name: <strong>Z-A</strong>',
        sort: 0,
        ascending: false
      },
    }     
  },

  priceMinMax: {
    '1': {
      priceMin: 0,
      priceMax: 50
    }, '2': {
      priceMin: 50,
      priceMax: 100
    }, '3': {
      priceMin: 100,
      priceMax: 150
    }, '4': {
      priceMin: 150,
      priceMax: 200
    }, '5': {
      priceMin: 200,
      priceMax: 250
    }, '6': {
      priceMin: 250,
      priceMax: 500
    }, '7': {
      priceMin: 500,
      priceMax: 5000
    },
  },
  statusConfig: {
    0: {
      icon: 'fas fa-times-circle',
      color: 'failed',
      label: 'Failed'
    },
    1: {
      icon: 'far fa-envelope',
      color: '',
      label: 'Sent'
    },
    2: {
      icon: 'far fa-envelope',
      color: 'sent',
      label: 'Sent'
    },
    3: {
      icon: 'far fa-eye',
      color: 'accessed',
      label: 'Accessed'
    },
    4: {
      icon: 'fas fa-sign-in-alt',
      color: 'entered',
      label: 'Entered'
    },
    5: {
      icon: 'fas fa-clipboard-check',
      color: 'filled',
      label: 'Registered'
    },
    6: {
      icon: 'fas fa-minus-square',
      color: 'filled',
      label: 'Part Filled'
    },
    7: {
      icon: 'far fa-check-square',
      color: 'filled',
      label: 'Filled'
    },
    8: {
      icon: 'far fa-alarm-clock',
      color: 'reminder',
      label: 'Email reminder'
    },
    9: {
      icon: 'far fa-alarm-clock',
      color: 'reminder',
      label: 'SMS reminder'
    },
    'eProgress': {
      icon: 'far fa-circle',
      color: 'success',
      label: 'Study in Progress'
    },
    'eComplete': {
      icon: 'fas fa-check-circle',
      color: 'success',
      label: 'Study Completed'
    }
  },
  rscStatusConfig: {
    0: {
      icon: 'fas fa-times-circle',
      color: 'failed',
      label: 'Failed'
    },
    1: {
      icon: 'far fa-envelope',
      color: '',
      label: 'Sent'
    },
    2: {
      icon: 'far fa-envelope',
      color: 'sent',
      label: 'Sent'
    },
    3: {
      icon: 'far fa-eye',
      color: 'accessed',
      label: 'Accessed'
    },
    4: {
      icon: 'far fa-check-square',
      color: 'filled',
      label: 'Filled'
    },
    5: {
      icon: 'fas fa-sign-in-alt',
      color: 'entered',
      label: 'Entered'
    },
    6: {
      icon: 'fas fa-clipboard-check',
      color: 'filled',
      label: 'Registered'
    },
    8: {
      icon: 'far fa-alarm-clock',
      color: 'reminder',
      label: 'Email reminder'
    },
    9: {
      icon: 'far fa-alarm-clock',
      color: 'reminder',
      label: 'SMS reminder'
    },
  },
  statStatusConfig: {
    0: {
      icon: 'far fa-eye',
      color: 'accessed',
      label: 'Accessed'
    },
    1: {
      icon: 'far fa-envelope',
      color: 'sent',
      label: 'Sent'
    },
    2: {
      icon: 'fas fa-sign-in-alt',
      color: 'entered',
      label: 'Entered'
    },
    3: {
      icon: 'fas fa-clipboard-check',
      color: 'filled',
      label: 'Registered'
    },
    4: {
      icon: 'far fa-check-square',
      color: 'filled',
      label: 'Filled'
    },
  },
  patientSortOptions: {
    'DateAsc': {
      label: 'Date: <strong>latest</strong>',
      sortKey: 'Date',
      ascending: false
    },
    'DateDesc': {
      label: 'Date: <strong>eldest</strong>',
      sortKey: 'Date',
      ascending: true
    },
    'PatientAsc': {
      label: 'Patient: <strong>A-Z</strong>',
      sortKey: 'PatientInfo',
      ascending: true
    },
    'PatientDesc': {
      label: 'Patient: <strong>Z-A</strong>',
      sortKey: 'PatientInfo',
      ascending: false
    },
    'SentToAsc': {
      label: 'Sent To: <strong>A-Z</strong>',
      sortKey: 'SentTo',
      ascending: true
    },
    'SentToDesc': {
      label: 'Sent To: <strong>Z-A</strong>',
      sortKey: 'SentTo',
      ascending: false
    },
    'StatusAsc': {
      label: 'Status: <strong>A-Z</strong>',
      sortKey: 'Status',
      ascending: true
    },
    'StatusDesc': {
      label: 'Status: <strong>Z-A</strong>',
      sortKey: 'Status',
      ascending: false
    }
  },
  integrationPaths: {
    emis: 'emis',
    smartLaunch: 'smartlaunch',
    sso: 'sso',
    acceptedtos: 'acceptedtos'
  },
  cdnUrl: "https://appscriptcdndev.blob.core.windows.net/prescriber"
}
