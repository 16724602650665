<modal #accepetedtos [modalConfig]="modalConfig">
    <div class="accepetedtoscontent">
            <div class="Emis-tos-Container">
                <div class="Emis-tos-header">
                    <h4 class="hidden-xs">Terms of Service</h4>
                </div>
                <p class="invalid-feedback d-block mb-1" *ngIf="errorMessage">{{errorMessage}}</p>
                <div class="Emis-tos-content">
                    <div class="pb-3">
                        In order to use the AppScript, enter your e-mail address:
                    </div>
                    <div class="form-group">
                        <div class="emistos-email pb-3">
                            <input type="text" #titleInput class="form-control" placeholder="Enter your email address here"
                            (input)="isEmailError = false" (keydown.enter)="onSubmit(titleInput.value)" [ngClass]="{'is-invalid': isEmailError}" />
                                <div *ngIf="isEmailError" class="invalid-feedback m-1 d-block">
                                    Please provide a valid email address.
                                </div>
                        </div>
                        <div>
                            <p class="mb-2">Your email will be used to notify you of:</p>
                        </div>
                        <ul>
                            <li> Additions and removals to the library</li>
                            <li>Aggregate information on your patients' use of recommended apps </li>
                        </ul>
                        <div>
                            <p class="mb-2">Your email will not be:</p>
                        </div>
                        <ul>
                            <li> Shared with third parties </li>
                            <li>Used to market additional products or services to you</li>
                        </ul>

                        <div>
                            <p class="mb-2">
                                AppScript is a service provided by IQVIA. You should review the document below to
                                understand the following in connection with your use of the service:
                            </p>
                            <div class="pl-3">
                                <p class="mb-0">A) How your personal information will be used in connection with the use
                                    of this program</p>
                                <p>B) Your responsibilities in providing your patients' information to the service</p>
                            </div>
                        </div>

                        <div class="tosInfo2"> By clicking <b>Accept</b>, you agree to our <a class="tos_link"
                                (click)="openTOSModal()">Terms of Service</a></div>
                        <app-tos #tos></app-tos>
                    </div>
                    <div class="text-center">
                        <button type="submit" id="emis-tos-submit" (click) = "onSubmit(titleInput.value)" class="btn btn-primary btn-emisBtn">Accept</button>
                    </div>
                </div>
            </div>
    </div>
</modal>