import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  userRole: string = "";
  isTokenExpired: boolean = false;
  countryCode: string = "";
  config: any;
  isLoggedIn!: boolean;
  showError: boolean;
  errorMessage: string;
  showSuccess: boolean;
  successMessage: string;
  constructor(private http: HttpClient, private configService: ConfigService) {
  }

  getToken() {
    return sessionStorage.getItem("accessToken");
  }

  setToken(token, expires_in) {
    if (expires_in) {
      const now = new Date();
      expires_in = now.getTime() + (expires_in * 1000)
    }

    const tokenObj = {
      value: token,
      expiry: expires_in
    }

    sessionStorage.setItem("accessToken", JSON.stringify(tokenObj));
  }

  removeToken() {
    sessionStorage.removeItem("accessToken");
  }

  setRefreshToken(token) {
    sessionStorage.setItem('refreshToken', token);
  }

  getRefreshToken() {
    return sessionStorage.getItem('refreshToken');
  }

  resetGlobal() {
    this.userRole = "";
    this.isLoggedIn = false;
    this.showErrorMessage(false, "");
    sessionStorage.clear();

  }

  IsJsonString(str: string) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  getTokenWithExpiry() {
    const token = this.getToken();
    if (!token) {
      return null;
    }
    if (this.IsJsonString(token)) {
      const accesstoken = JSON.parse(token);
      const now = new Date();
      // compare the expiry time of the accesstoken with the current time

      if (accesstoken.expiry && now.getTime() > accesstoken.expiry) {
        // If the item is expired, delete the item from storage
        // and return null
        this.resetGlobal();
        this.showErrorMessage(true, "Your session has expired, please login again.");
        this.isTokenExpired = true;
        return null;
      }
      this.isTokenExpired = false;
      return accesstoken;
    } else {
      sessionStorage.clear();
    }
  }

  showErrorMessage(show, errorMessage) {
    this.showError = show;
    this.errorMessage = errorMessage;
  }

  showSuccessMessage(show, successMessage) {
    this.showSuccess = show;
    this.successMessage = successMessage;
  }

  refreshToken() {
    let requestHeaders: HttpHeaders = new HttpHeaders();
    requestHeaders = requestHeaders.set('Content-Type', 'application/x-www-form-urlencoded');
    let requestBody = `grant_type=refresh_token&refresh_token=${this.getRefreshToken()}&client_id=PrescriberMobile`;
    return this.http.post(this.configService.getAuthUrl() + 'connect/token', requestBody, { headers: requestHeaders }).pipe(
      tap((data: any) => {
        if (data) {
          this.setToken(data.access_token, data.expires_in);
          this.setRefreshToken(data.refresh_token);
        }
      })
    )
  }

}



