import { Component, OnDestroy, AfterViewInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { AboutModalComponent } from './shared/components/about-modal/about-modal.component';
import { ChangePasswordComponent } from './shared/components/change-password/change-password.component';
import { selectChangePasswordModal, selectInfoModal } from './store/selectors/app-data.selectors';
import { InfoModalComponent } from './shared/components/info-modal/info-modal.component';
import { AppDataService } from './core/services/app-data.service';
import { selectUserData } from './store/selectors/user-data.selectors';
import { partnerAPI } from './store/actions/app-data.actions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit, OnDestroy {

  @ViewChild('aboutModal') aboutModal: AboutModalComponent;
  @ViewChild('changePassword') changePassword: ChangePasswordComponent;
  @ViewChild('infoModal') infoModal: InfoModalComponent;

  isLogin!: boolean;
  isRegister!: boolean;
  isFooter!: boolean;
  isLearnMore!: boolean;
  isEcrfFrame!: boolean;
  isPartnerApi!: boolean;
  isSso: boolean;
  isSSOLogin :boolean;
  patientPrivacyPolicy: boolean;
  partnerApiCalled: boolean = false;
  private readonly destroy$ = new Subject();

  constructor(private appdataservice: AppDataService, private router: Router, private store: Store) {
    this.router.events.pipe(filter((event: any) => event instanceof NavigationEnd)).pipe(
      takeUntil(this.destroy$)
    ).subscribe((event: any) => {
      this.isLogin = (event.url === '/login');
      this.isSSOLogin = (event.url === '/login' && this.isSso);
      this.isRegister = (event.url === '/register' || event.urlAfterRedirects === '/register');
      this.isFooter = (event.url === '/footer' || event.urlAfterRedirects === '/footer');
      this.isLearnMore = (event.url === '/learnmore' || event.urlAfterRedirects === '/learnmore');
      this.isEcrfFrame = (event.url === '/questions' || event.urlAfterRedirects === '/questions');
      this.isPartnerApi = (event.url === '/PartnerApiInstruction' || event.urlAfterRedirects === '/PartnerApiInstruction');
      this.patientPrivacyPolicy = event.url.indexOf('/Policies') >= 0;
    });

    this.store.select(selectChangePasswordModal).pipe(
      takeUntil(this.destroy$)
    ).subscribe(changePassword => {
      if (changePassword) {
        this.changePassword.changePasswordModel();
      }
    });

    this.checkPartnerApi();

  }

  checkPartnerApi() {
    this.appdataservice.isEmis().pipe(
      takeUntil(this.destroy$),
      filter(emis => emis),
      switchMap(emis => {
        return this.store.select(selectUserData).pipe(
          takeUntil(this.destroy$),
          map(userData => {
            return { emis, userData };
          })
        )
      })
    ).subscribe(({ emis, userData }) => {
      if (emis && userData && !this.partnerApiCalled) {
        this.partnerApiCalled = true;
        let patientInfo = this.appdataservice.getPatientInfo();
        if (userData && patientInfo?.requestId) {
          let params = { "requestId": patientInfo.requestId, "userId": userData.id }
          this.appdataservice.getFilebackPartnerApi(params).subscribe(res => {
            this.store.dispatch(partnerAPI({ partnerApiEnabled: true }));
          }, error => {
            this.store.dispatch(partnerAPI({ partnerApiEnabled: false }));
          })
        }
      }
    });
  }

  ngAfterViewInit(): void {
    this.store.select(selectInfoModal).pipe(
      takeUntil(this.destroy$)
    ).subscribe(infoModal => {
      if (infoModal) {
        this.infoModal.openInfoModal();
      }
    });

    this.appdataservice.isSSO().pipe(
      takeUntil(this.destroy$)
    ).subscribe(data => {
      if (data) {
        this.isSso = data;
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
