import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class LoginResolver implements Resolve<boolean> {
  constructor(private router: Router, private authService: AuthService) { }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.authService.getTokenWithExpiry()) {
      this.router.navigateByUrl('dashboard');
      return true;
    }
    return true;
  }
}
