import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { AppDataActions } from '../../store/actions/app-data.actions';
import { getAppData, selectEmis, selectPasswordExpiry, selectSmartlaunch } from '../../store/selectors/app-data.selectors';
import { ConfigService } from './config.service';
import { selectUserData } from '../../store/selectors/user-data.selectors';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppDataService {
  countrycode: string;
  patientInfo;
  resourceType: string;
  loaderLabel: string;
  isNewUserAcceptedTos:boolean=false;
  constructor(private http: HttpClient, private configureService: ConfigService, private store: Store) {
    this.countrycode = sessionStorage.getItem('countrycode');
  }

  getBuildVersion() {
    return this.http.get(this.configureService.appconfig.apiurl + 'CommonData/getBuildVersion');
  }

  getTos() {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    return this.http.get(this.configureService.appconfig.apiurl + 'PrivacyPolicy/ProviderTerms?countrycode=' + this.countrycode, { headers, responseType: 'text' });
  }

  getPrivacyPolicy() {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    return this.http.get(this.configureService.appconfig.apiurl + 'PrivacyPolicy/PatientPrivacyPolicy?countrycode=' + this.countrycode, { headers, responseType: 'text' });
  }

  getFilebackPartnerApi(params) {
    const headers = new HttpHeaders().set('facility', 'EMIS');
    return this.http.post(this.configureService.appconfig.apiurl + 'emispartner/ispartnerapienabled', params, { headers, responseType: 'text' });
  }

  emisAcceptedTermsofService(params) {
    const headers = new HttpHeaders().set('facility', 'EMIS');
    return this.http.get(this.configureService.appconfig.homeUrl + '/home/EMISSignon' + "?email=" + params.email + "&requestid=" + params.requestId, { headers });
  }

  newUserTermsofService() {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    return this.http.put(this.configureService.appconfig.apiurl + '/user/terms-of-service', headers, { responseType: 'text' });
  }

  getEMRSessionIdentifier(sessionId): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');//Make it as /user/GetEMRToken?sessionID
    return this.http.get(this.configureService.appconfig.apiurl + '/user/GetEMRToken?sessionID=' + sessionId, { headers, responseType: 'text' });
  }

  setEmisFlow() {
    this.store.dispatch(AppDataActions.setEmis());
  }

  setSmartLaunchFlow() {
    this.store.dispatch(AppDataActions.setSmartLaunch());
  }

  setSsoFlow() {
    this.store.dispatch(AppDataActions.setSso());
  }

  getPatientInfo() {
    return this.patientInfo;
  }

  getResourceType() {
    return this.resourceType;
  }

  setResourceType(type) {
    this.resourceType = type;
  }

  setPatientInfo(data) {
    this.patientInfo = { ...data };
  }



  isIntegration() {
    return this.store.select(getAppData).pipe(
      map(data => data.emis || data.smartLaunch)
    );
  }

  isSSO() {
    return this.store.select(getAppData).pipe(
      map(data => data.sso)
    );
  }

  isPatient() {
    return this.store.select(selectUserData).pipe(
      map(data => data?.roles && data.roles.length && data.roles.indexOf('Patient') >= 0)
    );
  }

  isEmis() {
    return this.store.select(selectEmis);
  }

  isSmartlaunch() {
    return this.store.select(selectSmartlaunch);
  }

  getAppData() {
    return this.store.select(getAppData);
  }

  getLoaderLabel() {
    return this.loaderLabel;
  }

  setLoaderLabel(text) {
    this.loaderLabel = text;
  }

  getPasswordExpiry() {
    return this.store.select(selectPasswordExpiry);
  }

}
