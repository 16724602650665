<ng-template #modal>
  <div class="modal-header"  *ngIf="modalConfig.hideheader === undefined || !modalConfig.hideheader()">
    <div class="modal-title">{{ modalConfig.modalTitle }}</div>
    <div (click)="close()" class="modal-close" ngbAutofocus>
      <i class="fas fa-times modal-close"></i>
    </div>
  </div>
  <div class="modal-body">
    <ng-content></ng-content>
    <div class="text-center" *ngIf="modalConfig.hideCloseButton === undefined || !modalConfig.hideCloseButton()">
      <button type="button" class="btn btn-outline-primary closeTos" (click)="close()"
        [disabled]="modalConfig.disableCloseButton !== undefined && modalConfig.disableCloseButton()">
        {{ modalConfig.closeButtonLabel }}
      </button>
    </div>
  </div>
  <!-- <div class="modal-footer">
      <button type="button"
              class="btn btn-outline-secondary"
              (click)="dismiss()"
              *ngIf="modalConfig.hideDismissButton === undefined || !modalConfig.hideDismissButton()"
              [disabled]="modalConfig.disableDismissButton !== undefined && modalConfig.disableDismissButton()">
        {{ modalConfig.dismissButtonLabel }}
      </button>
      <button type="button"
              class="btn btn-outline-primary"
              (click)="close()"
              *ngIf="modalConfig.hideCloseButton === undefined || !modalConfig.hideCloseButton()"
              [disabled]="modalConfig.disableCloseButton !== undefined && modalConfig.disableCloseButton()">
        {{ modalConfig.closeButtonLabel }}
      </button>
    </div> -->
</ng-template>
