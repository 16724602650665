import { Component, OnInit } from '@angular/core';
import { HeaderItems } from '../../shared/models/header-items';
import { HeaderHelperService } from '../../shared/services/header-helper.service';

@Component({
  selector: 'app-learnmore-header',
  templateUrl: './learnmore-header.component.html'
})
export class LearnmoreHeaderComponent {

  headerItems: HeaderItems = {
    logo: true
  };
  constructor(public headerHelperService: HeaderHelperService) {
  }

}
