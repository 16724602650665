import { Component, OnInit } from '@angular/core';
import { HeaderItems } from '../../shared/models/header-items';
import { HeaderHelperService } from '../../shared/services/header-helper.service';

@Component({
  selector: 'app-register-header',
  templateUrl: './register-header.component.html',
  styleUrls: ['./register-header.component.scss']
})
export class RegisterHeaderComponent {

  headerItems: HeaderItems = {
    logo: true,
    help: true,
    login: true,
    menuBar: true
  };
  constructor(public headerHelperService: HeaderHelperService) {
  }

}
