import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DevicesState } from '../reducers/devices.reducer';

export const selectDevicesState = createFeatureSelector<DevicesState>('devices');

export const selectFeaturedDevices = createSelector(selectDevicesState, state => state.featuredDevices);

export const selectFavoriteDevices = createSelector(selectDevicesState, state => state.favoriteDevices);

export const selectRemainingIds = createSelector(selectDevicesState, state => state.remainingIds);

export const selectSelectedIds = createSelector(selectDevicesState, state => state.selectedIds);

export const selectRecommendedDevices = createSelector(selectDevicesState, state => state.recommendedDevices);

