import { AfterViewInit, Component, ComponentRef, OnDestroy, Renderer2 } from '@angular/core';
import { ChoachMarkService } from '../../../core/services/choach-mark.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-coach-marks',
  templateUrl: './coach-marks.component.html',
  styleUrls: ['./coach-marks.component.scss']
})
export class CoachMarksComponent implements AfterViewInit, OnDestroy {

  remove;
  removeResizeListener;

  constructor(private rd: Renderer2) { }

  ngAfterViewInit(): void {
    this.placeCoachImages();
    this.removeResizeListener = this.rd.listen('window', 'resize', (event) => {
      this.placeCoachImages();
    });
  }

  placeCoachImages() {
    document.body.setAttribute('class', 'scroll-lock');

    let subHeaders = this.rd.selectRootElement('body', true).getElementsByClassName('sub-header-item');
    if (subHeaders.length) {
      this.addSwitchMark(subHeaders);
    }

    let tiles = this.rd.selectRootElement('div.tiles-container', true).getElementsByClassName('tile');
    if (tiles.length > 2) {
      this.addCoachMarks(tiles[0], tiles[1], tiles[2]);
    } else if (tiles.length > 1) {
      this.addCoachMarks(tiles[0], tiles[1], tiles[1]);
    } else if (tiles.length) {
      this.addCoachMarks(tiles[0], tiles[0], tiles[0]);
    }
  }

  addCoachMarks(checkMarkTile, getMakrTile, shareMarkTile) {
    let tileBody = checkMarkTile.getElementsByClassName('card-body-md');
    this.addCheckMark(tileBody[0]);

    let tileGet = getMakrTile.getElementsByClassName('getbtn');
    this.addGetMark(tileGet[0]);

    let tileShare = shareMarkTile.getElementsByClassName('sharebtn');
    this.addShareMark(tileShare[0]);
  }

  addSwitchMark(subHeaders) {
    let domRect = subHeaders[0].getBoundingClientRect();
    let switchImg = this.rd.selectRootElement('.switch');
    const switchImgStyle = {
      top: domRect.top + 'px',
      left: domRect.left + 'px'
    };
    Object.keys(switchImgStyle).forEach(newStyle => {
      this.rd.setStyle(
        switchImg, `${newStyle}`, switchImgStyle[newStyle]
      );
    });
  }

  addCheckMark(tileBody) {
    let domRectCM = tileBody.getBoundingClientRect();
    let checkMarkImg = this.rd.selectRootElement('.check');
    const checkMarkStyle = {
      top: domRectCM.top + 'px',
      left: domRectCM.left - 200 + 'px'
    };
    Object.keys(checkMarkStyle).forEach(newStyle => {
      this.rd.setStyle(
        checkMarkImg, `${newStyle}`, checkMarkStyle[newStyle]
      );
    });
  }

  addGetMark(tileGet) {
    let domGM = tileGet.getBoundingClientRect();
    let getMarkImg = this.rd.selectRootElement('.get');
    const getMarkStyle = {
      top: domGM.top - 12 + 'px',
      left: domGM.left - 3 + 'px'
    };
    Object.keys(getMarkStyle).forEach(newStyle => {
      this.rd.setStyle(
        getMarkImg, `${newStyle}`, getMarkStyle[newStyle]
      );
    });
  }

  addShareMark(tileShare) {
    let domRectSM = tileShare.getBoundingClientRect();
    let shareMarkImg = this.rd.selectRootElement('.share');
    const shareMarkStyle = {
      top: domRectSM.top - 148 + 'px',
      left: domRectSM.left + 'px'
    };
    Object.keys(shareMarkStyle).forEach(newStyle => {
      this.rd.setStyle(
        shareMarkImg, `${newStyle}`, shareMarkStyle[newStyle]
      );
    });
  }

  hideCoachMark() {
    this.remove();
    this.rd.removeClass(this.rd.selectRootElement('body', true), 'scroll-lock');
  }

  ngOnDestroy(): void {
    this.removeResizeListener();
  }

}
