import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, map, mergeMap, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { DashboardHelperService } from '../../shared/services/dashboard-helper.service';
import { DevicesService } from '../../shared/services/devices.service';
import { DevicesActions, selectedIds } from '../actions/devices.actions';
import { selectFavoriteApps } from '../selectors/apps.selectors';
import { selectFavoriteDevices, selectFeaturedDevices, selectRemainingIds, selectSelectedIds } from '../selectors/devices.selectors';
import { userFacilityData } from '../selectors/user-data.selectors';
import { AppDataService } from '../../core/services/app-data.service';

@Injectable()
export class DevicesEffects {

  count;
  ids;

  loadFeaturedDevices$ = createEffect(
    () => this.actions$.pipe(
      ofType(DevicesActions.loadFeaturedDevices),
      mergeMap((action) => {
        return this.devicesService.getFeaturedDevices(this.appDataService.getPatientInfo()?.patientFHIRID).pipe(
          map((featuredDevices: any) => {
            return DevicesActions.featuredDevicesLoaded({ featuredDevices });
          })
        )
      })
    )
  );

  loadFavoriteDevices$ = createEffect(
    () => this.actions$.pipe(
      ofType(DevicesActions.loadFavoriteDevices),
      mergeMap((action) => {
        return this.devicesService.getFavoriteDevices(this.appDataService.getPatientInfo()?.patientFHIRID).pipe(
          map((favoriteDevices: any) => {
            return DevicesActions.favoriteDevicesLoaded({ favoriteDevices });
          })
        )
      })
    )
  );

  loadRecommendedDevices$ = createEffect(
    () => this.actions$.pipe(
      ofType(DevicesActions.loadRecommendedDevices),
      withLatestFrom(this.store.select(selectSelectedIds)),
      /* withLatestFrom(this.store.select(selectFavoriteDevices)),
      filter(devices => !devices[1]), */
      switchMap((data) => {
        this.count = data[0].count;
        return this.store.select(userFacilityData).pipe(
          tap(data => {
            this.ids = this.dashboardHelperService.generateCatIds(data.deviceCategories);
          }),
          mergeMap((action) => {
            if (data[1]) {
              return this.devicesService.getRecommendedCategories(data[1], this.appDataService.getPatientInfo()?.patientFHIRID).pipe(
                map(recommendedDevices => {
                  return this.dashboardHelperService.generateRecomAppsGroup(data[1], recommendedDevices);
                }),
                mergeMap((recommendedDevicesGroup: any) => [
                  DevicesActions.recommendedDevicesLoaded({ recommendedDevicesGroup })
                ])
              )
            } else {
              let { ids, idsArray } = this.dashboardHelperService.getRandomIds(this.ids, this.count);
              this.ids = idsArray;
              this.store.dispatch(selectedIds({ selectedIds: ids }));
              return this.devicesService.getRecommendedCategories(ids, this.appDataService.getPatientInfo()?.patientFHIRID).pipe(
                map(recommendedDevices => {
                  return this.dashboardHelperService.generateRecomAppsGroup(ids, recommendedDevices);
                }),
                mergeMap((recommendedDevicesGroup: any) => [
                  DevicesActions.recommendedDevicesLoaded({ recommendedDevicesGroup }),
                  DevicesActions.remainingIds({ remainingIds: [...this.ids] })
                ])
              )
            }

          })
        )
      })
    )
  );

  loadMoreRecommendedDevices$ = createEffect(
    () => this.actions$.pipe(
      ofType(DevicesActions.loadMoreRecommendedDevices),
      mergeMap((action) => {
        this.count = action.count;
        return this.store.select(selectRemainingIds).pipe(
          tap(data => {
            this.ids = [...data];
          }),
          filter(ids => ids.length > 0),
          take(1),
          mergeMap(action => {
            let { ids, idsArray } = this.dashboardHelperService.getRandomIds(this.ids, this.count);
            this.ids = idsArray;
            this.store.dispatch(selectedIds({ selectedIds: ids }));
            return this.devicesService.getRecommendedCategories(ids, this.appDataService.getPatientInfo()?.patientFHIRID).pipe(
              map(recommendedDevices => {
                return this.dashboardHelperService.generateRecomAppsGroup(ids, recommendedDevices);
              }),
              mergeMap((recommendedDevicesGroup: any) => [
                DevicesActions.moreRecommendedDevicesLoaded({ moreRecommendedDevicesGroup: recommendedDevicesGroup }),
                DevicesActions.remainingIds({ remainingIds: [...this.ids] })
              ])
            )
          })
        )
      })
    )
  );

  constructor(private actions$: Actions, private devicesService: DevicesService, private store: Store,
    private dashboardHelperService: DashboardHelperService, private appDataService: AppDataService) { }

}
