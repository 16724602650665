import { Component, OnInit } from '@angular/core';
import { AppDataService } from '../../core/services/app-data.service';
import { ActivatedRoute, Route } from '@angular/router';

@Component({
  selector: 'app-patient-privacy',
  templateUrl: './patient-privacy.component.html',
  styleUrls: ['./patient-privacy.component.scss']
})
export class PatientPrivacyComponent implements OnInit {

  privacypolicyData: any;
  constructor(private appservice: AppDataService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      sessionStorage.setItem("countrycode",params.countrycode)
    });
    this.appservice.getPrivacyPolicy().subscribe(data => {
      if (data) {
        this.privacypolicyData = data;
      }
    });
  }

}
