import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { UtilsService } from '../../core/utils/utils.service';
import { UnsubscribeService } from '../../core/services/unsubscribe.service';

@Component({
  selector: 'app-unsubscribe',
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.scss']
})
export class UnsubscribeComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject;
  id: string;
  email: string;
  subscribed: boolean = false;
  userType: string;

  constructor(private route: ActivatedRoute, private utilsService: UtilsService, private unsubscribeService: UnsubscribeService) {
    this.id = this.route.snapshot.paramMap.get('id');
    this.userType = this.route.snapshot.paramMap.get('userType');
    this.email = this.route.snapshot.queryParamMap.get('email') || '';
    let countrycode = this.route.snapshot.queryParamMap.get('countryCode');
    if (countrycode) {
      sessionStorage.setItem('countrycode', countrycode);
    }
    let encodemail = decodeURIComponent(this.email);
    let checkDot = this.utilsService.replaceAll(encodemail, ' dot ', '.');
    this.email = this.utilsService.replaceAll(checkDot, ' ', '+') || this.utilsService.replaceAll(checkDot, '%20', '+') || this.utilsService.replaceAll(checkDot, '%2B', '+');
    this.unsubscribe();
  }

  ngOnInit(): void {
  }

  unsubscribe() {
    this.unsubscribeService.unsubscribe(true, this.id, (this.userType === 'prescriber')).pipe(
      takeUntil(this.destroy$)
    ).subscribe(data => {
    });
  }

  subscribe() {
    this.unsubscribeService.unsubscribe(false, this.id, (this.userType === 'prescriber')).pipe(
      takeUntil(this.destroy$)
    ).subscribe(data => {
      this.subscribed = true;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
