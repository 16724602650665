import { ApplicationRef, ComponentFactoryResolver, ComponentRef, EmbeddedViewRef, Injectable, Injector, Renderer2, RendererFactory2 } from '@angular/core';
import { CoachMarksComponent } from '../../shared/components/coach-marks/coach-marks.component';

@Injectable({
  providedIn: 'root'
})
export class ChoachMarkService {
  private rd: Renderer2;
  private componentRefs: ComponentRef<any>[] = [];

  constructor(private compFactoryResolver: ComponentFactoryResolver, private rendererFactory: RendererFactory2, private injector: Injector,
    private appRef: ApplicationRef) {
    this.rd = rendererFactory.createRenderer(null, null);
  }

  showCoachMarks() {
    const componentFactory = this.compFactoryResolver.resolveComponentFactory(CoachMarksComponent);

    const componentRef = componentFactory.create(this.injector);
    componentRef.instance.remove = this.removeCoachMarks.bind(this);

    this.appRef.attachView(componentRef.hostView);

    const componentNativeEl = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

    this.rd.appendChild(document.body, componentNativeEl);
    this.componentRefs.push(componentRef);
  }

  removeCoachMarks() {
    const componentRef = this.componentRefs.pop();
    this.appRef.detachView(componentRef.hostView);
    componentRef.destroy();
    this.componentRefs = this.componentRefs.filter((ref) => ref !== componentRef);
  }
}
