import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '../../core/services/config.service';
import { Platform } from '../models/tile-data';

@Injectable({
  providedIn: 'root'
})
export class AppsService {

  private appsUrl = 'apps';

  constructor(private http: HttpClient, private configService: ConfigService) { }

  getFeaturedApps(appPlatform, patientId?) {
    let params = new HttpParams();
    params = params.set('platform', appPlatform ? appPlatform : Platform[0]);
    if (patientId) {
      params = params.append('patientId', patientId);
    }
    return this.http.get(this.configService.appconfig.apiurl + this.configService.getFacilityUrl() + this.appsUrl + '/featured', {
      params
    });
  }

  getFavoriteApps(appPlatform, patientId?) {
    let params = new HttpParams();
    params = params.set('platform', appPlatform ? appPlatform : Platform[0]);
    if (patientId) {
      params = params.append('patientId', patientId);
    }
    return this.http.get(this.configService.appconfig.apiurl + this.configService.getFacilityUrl() + this.appsUrl + '/favorites', {
      params
    });
  }

  getRecommendedCategories(appPlatform, ids, patientId?) {
    let params = new HttpParams().set('platform', appPlatform ? appPlatform : Platform[0]);

    ids.forEach(id => {
      params = params.append('categoryId', id.id.toString())
    })
    params = params.set('platform', appPlatform ? appPlatform : Platform[0]);
    if (patientId) {
      params = params.append('patientId', patientId);
    }
    return this.http.get(this.configService.appconfig.apiurl + this.configService.getFacilityUrl() + this.appsUrl + '/recommendedCategories', {
      params: params
    });
  }

  getCdstAppDetails(appId) {
    return this.http.get(this.configService.appconfig.apiurl + this.configService.getFacilityUrl() + 'cdstapp', {
      params: {
        id: appId
      }
    });
  }

  getCdstApps() {
    return this.http.get(this.configService.appconfig.apiurl + this.configService.getFacilityUrl() + '/cdstapp/apps');
  }
}
