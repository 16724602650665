import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { AppDataActions, partnerAPI } from '../../store/actions/app-data.actions';
import { GlobalConstants } from '../constants/global.constants';
import { AppDataService } from '../services/app-data.service';
import { AuthService } from '../services/auth.service';
import { catchError, map, tap } from 'rxjs/operators';
import { CryptoEncryptionService } from '../services/crypto-encryption.service';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class IntegrationGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService, private store: Store, private appDataService: AppDataService, private cryptoEncryptionService: CryptoEncryptionService) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (route.routeConfig.path === GlobalConstants.integrationPaths.emis) {
      this.authService.setToken(route.queryParams['token'], null);
      this.appDataService.setEmisFlow();
      sessionStorage.setItem('countrycode', 'gb');
      this.patientInfo(route.queryParams);


      //PartnerAPi for fileback
      //Below Code for PartnerAPi for fileback is handled in user-facility-data-resolver
      
      // return this.appDataService.getFilebackPartnerApi(route.queryParams.requestId).pipe(
      //   map(() => {
      //     this.router.navigateByUrl('dashboard');
      //     this.store.dispatch(partnerAPI({ partnerApiEnabled: true }));
      //     return false;
      //   }),
      //   catchError(() => {
      //     this.router.navigateByUrl('dashboard');
      //     this.store.dispatch(partnerAPI({ partnerApiEnabled: false }));
      //     return of(false);
      //   }))



    }
    if (route.routeConfig.path === GlobalConstants.integrationPaths.smartLaunch) {
      this.appDataService.setSmartLaunchFlow();
      sessionStorage.setItem('countrycode', 'us');
      return this.appDataService.getEMRSessionIdentifier(route.queryParams['sessionId']).pipe(tap((response) => {
        let decryptedTokenValue = this.cryptoEncryptionService.decrypt(decodeURIComponent(response));
        let converetedQueryParams = this.convertKeyValueFromQueryString(decryptedTokenValue);
        this.authService.setToken(converetedQueryParams['token'], null);
        this.patientInfo(converetedQueryParams);
        this.router.navigateByUrl('dashboard');
        return false;
      }));

    }
    if (route.routeConfig.path === GlobalConstants.integrationPaths.sso) {
      this.authService.setToken(route.queryParams['t'], null);
      sessionStorage.setItem('countrycode', route.queryParams['countryCode']);
      this.appDataService.setSsoFlow();
      this.patientInfo(route.queryParams);
      if (route.queryParams['returnUrl']) {
        let detailUrl;
        let url = route.queryParams['returnUrl'].split('/');
        if (url && url[6]) {
          detailUrl = url[3] + '/' + url[4] + '/' + url[5] + '/' + url[6];
        } else {
          detailUrl = url[3] + '/' + url[4] + '/' + url[5];
        } this.router.navigateByUrl(detailUrl);
        return false;
      }
    }
    if (route.routeConfig.path === GlobalConstants.integrationPaths.acceptedtos) {
      sessionStorage.setItem('countrycode', 'gb');
      return true;
    }
    this.router.navigateByUrl('dashboard');
    return false;
  }

  patientInfo(queryParams) {
    let info = {
      patientID: queryParams['fhirPatientID'],
      patientName: queryParams['patientName'],
      patientFHIRID: queryParams['patientID'],
      email: queryParams['email'],
      phone: queryParams['phone'],
      fhirProviderID: queryParams['fhirProviderID'],
      emrEndpoint: queryParams['serviceUri'],
      requestId: queryParams['requestId'],
    }
    this.appDataService.setPatientInfo(info);
  }

  convertKeyValueFromQueryString(queryString: string) {
    const params = new HttpParams({ fromString: queryString });
    const keyValue: { [key: string]: string } = {};
      params.keys().forEach(key => {
        keyValue[key] = params.get(key) as string;
      });
    return keyValue;
  }
}
