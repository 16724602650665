import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.authService.getTokenWithExpiry()) {
      return this.authService.refreshToken().pipe(
        map(data => {
          if (data.access_token) {
            return true;
          } else {
            this.router.navigateByUrl('/login');
            return false;
          }
        }),
        catchError(() => {
          this.router.navigateByUrl('/login');
          return of(false);
        })
      )
    }
    return true;
  }
}
