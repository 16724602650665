import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import { AppDataActions } from '../../../store/actions/app-data.actions';
import { buildVersion } from '../../../store/selectors/app-data.selectors';
import { ModalConfig } from '../../models/modal.config';
import { ModalComponent } from '../modal/modal.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-about-modal',
  templateUrl: './about-modal.component.html',
  styleUrls: ['./about-modal.component.scss']
})
export class AboutModalComponent implements OnInit {

  @ViewChild('aboutAppscript') private modalContent: ModalComponent;
  @ViewChild('tos') private tosContent;
  @ViewChild('privacypolicy') private privacyContent;

  modalConfig: ModalConfig = {
    modalTitle: 'About AppScript',
    hideCloseButton: () => {
      return true
    }
  }
  buildversion: string;
  year: number;

  constructor(private store: Store, private router: Router, private ngbActiveModal: NgbActiveModal) {
    this.year = new Date().getFullYear();
  }

  ngOnInit(): void {
  }

  aboutModal() {
    this.store.select(buildVersion).pipe(
      tap(data => {
        if (!data) {
          this.store.dispatch(AppDataActions.loadBuildVersion())
        }
      })
    ).subscribe(buildVersion => {
      this.buildversion = buildVersion;
    });
    this.modalContent.open("aboutappclass");
  }

  onClose() {
    this.ngbActiveModal.close();
  }

  termsofservice() {
    this.tosContent.open();
  }

  privacyPolicy() {
    this.privacyContent.open();
  }

}
