import { Pipe, PipeTransform } from '@angular/core';
import { GlobalConstants } from '../../core/constants/global.constants'; 
import { ConfigService } from '../../core/services/config.service';

@Pipe({
  name: 'cdnurl'
})
export class CdnurlPipe implements PipeTransform {


  constructor(private configService: ConfigService){}
  transform(url: string): string {
    return  this.configService.getCdnUrl() + url;
  }

}
