import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ContentState } from '../reducers/content.reducer';

export const selectContentState = createFeatureSelector<ContentState>('content');

export const selectFeaturedContent = createSelector(selectContentState, state => state.featuredContent);

export const selectFavoriteContent = createSelector(selectContentState, state => state.favoriteContent);

export const selectRemainingIds = createSelector(selectContentState, state => state.remainingIds);

export const selectSelectedIds = createSelector(selectContentState, state => state.selectedIds);

export const selectRecommendedContent = createSelector(selectContentState, state => state.recommendedContent);

