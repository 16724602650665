import { HttpClient, HttpHeaders, } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiMethod } from '../models/apiconts';
import { UserDetails } from '../models/user-details';
import { ConfigService } from './config.service';  

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  userRole: string = "";
  isTokenExpired: boolean = false;
  showErrorMessage: string = "";
  methods!: ApiMethod;
  userdetails!: UserDetails;
  apiconfig: any; 
  password: string = ""; 
  userName: string = "";


  constructor(private http: HttpClient, private configureService: ConfigService) {
  }

  register(userdetails: UserDetails) {
    return this.http.post(this.configureService.appconfig.apiurl + "/account/register", userdetails);
  }

  login(userdetails: UserDetails) {
    return this.http.post(this.configureService.appconfig.apiurl + "/account/prescriberlogin", userdetails);
  }

  logout(loginUserName?: any) {
    const body = loginUserName ? { userName: loginUserName } : {};
    return this.http.post(this.configureService.appconfig.apiurl + "/account/logout", body);
  }
}
