import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, map, mergeMap, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { ContentService } from '../../shared/services/content.service';
import { DashboardHelperService } from '../../shared/services/dashboard-helper.service';
import { ContentActions, selectedIds } from '../actions/content.actions';
import { selectFavoriteContent, selectFeaturedContent, selectRemainingIds, selectSelectedIds } from '../selectors/content.selectors';
import { userFacilityData } from '../selectors/user-data.selectors';
import { AppDataService } from '../../core/services/app-data.service';



@Injectable()
export class ContentEffects {

  count;
  ids;

  loadFeaturedContent$ = createEffect(
    () => this.actions$.pipe(
      ofType(ContentActions.loadFeaturedContent),
      mergeMap((action) => {
        return this.contentService.getFeaturedContent(this.appDataService.getPatientInfo()?.patientFHIRID).pipe(
          map((featuredContent: any) => {
            return ContentActions.featuredContentLoaded({ featuredContent });
          })
        )
      })
    )
  );

  loadFavoriteContent$ = createEffect(
    () => this.actions$.pipe(
      ofType(ContentActions.loadFavoriteContent),
      mergeMap((action) => {
        return this.contentService.getFavoriteContent(this.appDataService.getPatientInfo()?.patientFHIRID).pipe(
          map((favoriteContent: any) => {
            return ContentActions.favoriteContentLoaded({ favoriteContent });
          })
        )
      })
    )
  );

  loadRecommendedContent$ = createEffect(
    () => this.actions$.pipe(
      ofType(ContentActions.loadRecommendedContent),
      withLatestFrom(this.store.select(selectSelectedIds)),
      /* withLatestFrom(this.store.select(selectFavoriteContent)),
      filter(content => !content[1]), */
      switchMap((data) => {
        this.count = data[0].count;
        return this.store.select(userFacilityData).pipe(
          tap(data => {
            this.ids = this.dashboardHelperService.generateCatIds(data.contentCategories);
          }),
          mergeMap((action) => {
            if (data[1]) {
              return this.contentService.getRecommendedCategories(data[1], this.appDataService.getPatientInfo()?.patientFHIRID).pipe(
                map(recommendedContent => {
                  return this.dashboardHelperService.generateRecomAppsGroup(data[1], recommendedContent);
                }),
                mergeMap((recommendedContentGroup: any) => [
                  ContentActions.recommendedContentLoaded({ recommendedContentGroup })
                ])
              )
            } else {
              let { ids, idsArray } = this.dashboardHelperService.getRandomIds(this.ids, this.count);
              this.ids = idsArray;
              this.store.dispatch(selectedIds({ selectedIds: ids }));
              return this.contentService.getRecommendedCategories(ids, this.appDataService.getPatientInfo()?.patientFHIRID).pipe(
                map(recommendedContent => {
                  return this.dashboardHelperService.generateRecomAppsGroup(ids, recommendedContent);
                }),
                mergeMap((recommendedContentGroup: any) => [
                  ContentActions.recommendedContentLoaded({ recommendedContentGroup }),
                  ContentActions.remainingIds({ remainingIds: [...this.ids] })
                ])
              )
            }

          })
        )
      })
    )
  );

  loadMoreRecommendedContent$ = createEffect(
    () => this.actions$.pipe(
      ofType(ContentActions.loadMoreRecommendedContent),
      mergeMap((action) => {
        this.count = action.count;
        return this.store.select(selectRemainingIds).pipe(
          tap(data => {
            this.ids = [...data];
          }),
          filter(ids => ids.length > 0),
          take(1),
          mergeMap(action => {
            let { ids, idsArray } = this.dashboardHelperService.getRandomIds(this.ids, this.count);
            this.ids = idsArray;
            this.store.dispatch(selectedIds({ selectedIds: ids }));
            return this.contentService.getRecommendedCategories(ids, this.appDataService.getPatientInfo()?.patientFHIRID).pipe(
              map(recommendedContent => {
                return this.dashboardHelperService.generateRecomAppsGroup(ids, recommendedContent);
              }),
              mergeMap((recommendedContentGroup: any) => [
                ContentActions.moreRecommendedContentLoaded({ moreRecommendedContentGroup: recommendedContentGroup }),
                ContentActions.remainingIds({ remainingIds: [...this.ids] })
              ])
            )
          })
        )
      })
    )
  );

  constructor(private actions$: Actions, private contentService: ContentService, private store: Store,
    private dashboardHelperService: DashboardHelperService, private appDataService: AppDataService) { }

}
