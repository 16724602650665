import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ModalComponent } from '../modal/modal.component';
import { ModalConfig } from '../../models/modal.config';
import { Store } from '@ngrx/store';
import { showInfoModal } from '../../../store/actions/app-data.actions';
import { Router } from '@angular/router'
import { ChoachMarkService } from '../../../core/services/choach-mark.service';

@Component({
  selector: 'app-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.scss']
})
export class InfoModalComponent implements OnInit, OnDestroy {

  @ViewChild('infoModal') private welcomemodelContent: ModalComponent

  constructor(private store: Store, private coachMarkService: ChoachMarkService, private router: Router) { }

  public modalConfig: ModalConfig = {
    modalTitle: 'Welcome',
    hideCloseButton: () => {
      return true
    }
  }
  ngOnInit(): void {
  }
  openInfoModal() {
    this.welcomemodelContent.open("info")
  }
  onClose() {
    this.store.dispatch(showInfoModal({ InfoModal: false }));
    this.welcomemodelContent.close();
  }
  learnmore(learnmore: string): void {
    this.onClose();
    this.router.navigate([`${learnmore}`]);
  }


  showCoachMarks() {
    this.coachMarkService.showCoachMarks();
    this.onClose();
  }

  ngOnDestroy(): void {
    this.onClose();
  }
}
