import { Action, createReducer, on } from '@ngrx/store';
import { DevicesActions } from '../actions/devices.actions';


export const devicesFeatureKey = 'devices';

export interface DevicesState {
  featuredDevices,
  favoriteDevices,
  remainingIds,
  selectedIds,
  recommendedDevices
}

export const devicesInitialState: DevicesState = {
  featuredDevices: null,
  favoriteDevices: null,
  remainingIds: null,
  selectedIds: null,
  recommendedDevices: null
};


export const devicesReducer = createReducer(
  devicesInitialState,
  on(DevicesActions.featuredDevicesLoaded, (state, action) => {
    return {
      ...state,
      featuredDevices: action.featuredDevices
    }
  }),
  on(DevicesActions.favoriteDevicesLoaded, (state, action) => {
    return {
      ...state,
      favoriteDevices: action.favoriteDevices
    }
  }),
  on(DevicesActions.remainingIds, (state, action) => {
    return {
      ...state,
      remainingIds: action.remainingIds
    }
  }),
  on(DevicesActions.selectedIds, (state, action) => {
    return {
      ...state,
      selectedIds: state.selectedIds ? [...state.selectedIds, ...action.selectedIds] : [...action.selectedIds]
    }
  }),
  on(DevicesActions.recommendedDevicesLoaded, (state, action) => {
    return {
      ...state,
      recommendedDevices: [...action.recommendedDevicesGroup]
    }
  }),
  on(DevicesActions.moreRecommendedDevicesLoaded, (state, action) => {
    return {
      ...state,
      recommendedDevices: action.moreRecommendedDevicesGroup ? [...state.recommendedDevices, ...action.moreRecommendedDevicesGroup] : [...state.recommendedDevices]
    }
  }),
  on(DevicesActions.addFavorite, (state, action) => {
    return {
      ...state,
      favoriteDevices: [...state.favoriteDevices, action.device]
    }
  }),
  on(DevicesActions.removeFavorite, (state, action) => {
    return {
      ...state,
      favoriteDevices: state.favoriteDevices.filter(device => device.id !== action.device.id)
    }
  }),
  on(DevicesActions.resetDevices, (state, action) => {
    return {
      ...state,
      featuredDevices: null,
      favoriteDevices: null,
      recommendedDevices: null
    }
  })
);

