import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { HeaderItems, HeaderActions } from '../../models/header-items';
import { AuthService } from '../../../core/services/auth.service';
import { AppDataService } from '../../../core/services/app-data.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ConfigService } from '../../../core/services/config.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit {
  @Input() data!: HeaderItems;
  @Input() isFullScreen!: HeaderItems;
  @Input() bagTotalCount: HeaderItems;
  @Input() showSearch: boolean = false;
  @Input() logoUrl: string;   //'https://appscriptcdndev.blob.core.windows.net/prescriber/assets/images/US_PRESCRIPTION.png';
  @Output() userAction: EventEmitter<string> = new EventEmitter();
  @ViewChild('userToggle') userToggle;
  @ViewChild('userPopup') userPopup;
  @ViewChild('bagToggle') bagToggle;
  @ViewChild('bagContainer') bagContainer;

  showUserPopup: boolean = false;
  showUserPopupMob: boolean = false;
  headerActions = HeaderActions;
  builVersion: number;
  showBag: boolean = false;

  constructor(private configService: ConfigService, private appDataService: AppDataService, private renderer: Renderer2, private cdr: ChangeDetectorRef, private authservice: AuthService) {
    this.renderer.listen('body', 'click', (e: Event) => {
      if (this.userPopup && e.target !== this.userToggle.nativeElement && e.target !== this.userPopup.nativeElement) {
        this.showUserPopup = false;
        this.cdr.markForCheck();
      }
      if (this.bagContainer && e.target !== this.bagToggle.nativeElement && e.target !== this.bagContainer.nativeElement) {
        this.showBag = false;
        this.cdr.markForCheck();
      }
    });

    if(!this.logoUrl){
      this.logoUrl = this.configService.getCdnUrl() + '/assets/images/US_PRESCRIPTION.png';
    }
  }

  ngOnInit(): void {
    this.showSearch = this.data.searchOpen;
    this.logoUrl = this.logoUrl+"?" + Math.floor(Math.random() * 100) + 1;//To refresh the new logo we have added math.random.
  }

  toggleUserPopup() {
    this.showUserPopup = !this.showUserPopup;
  }

  toggleUserPopupMob() {
    this.showUserPopupMob = !this.showUserPopupMob;
  }

  toggleSearch() {
    this.showSearch = !this.showSearch;
  }

  toggleBag() {
    if (this.bagTotalCount) {
      this.showBag = !this.showBag;
    } else {
      this.showBag = false;
    }
  }
}

