import { Component, OnInit, ViewChild } from '@angular/core';
import { AppDataService } from '../../../core/services/app-data.service';
import { HeaderItems } from '../../models/header-items';
import { ModalConfig } from '../../models/modal.config';
import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

  @ViewChild('privacypolicy') private privacypolicyContent: ModalComponent;
  privacypolicyData: any;
  constructor(private appservice: AppDataService) { }

  public modalConfig: ModalConfig = {
    modalTitle: 'Privacy Policy',
    closeButtonLabel: 'Close',
    hideDismissButton: () => {
      return true;
    },
    onClose: () => {
      return true
    },
  }
  ngOnInit(): void {
  }

  open() {
    this.appservice.getPrivacyPolicy().subscribe(data => {
      if (data) {
        this.privacypolicyData = data;
        this.privacypolicyContent.open("tosclass");
      }
    });
  }
}