export interface TileData {
  id: number,
  labelForPrescribe: string,
  title: string,
  hasIosApp: boolean,
  hasAndroidApp: boolean,
  hasWebApp: boolean,
  image: string,
  isStudy?: boolean,
  tag: string,
  studyType?: string,
  studyLabel?: string,
  score?: number,
  customLabel?: string,
  otsukastudy?: boolean,
  oldPrice?: string,
  isDevice?: boolean,
  isContent: boolean,
  isVideo?: boolean,
  isPdf?: boolean,
  isWebsite?: boolean,
  isApp?: boolean,
  categories?: Array<string>,
  isCustomApp?: boolean,
  inviteCode: any
  platform: number,
  resourceId: any,
  url: string,
  resourceType: string,
  price: string,
  priceType: number,
  searchCategoryId: any,
  pipelineInfo?: string,
  pipelineInfoV2?: string,
  studyId?: string,
  relevanceId?: string,
  availablePipelinesCode?: string,
  relevantDataCheckVisitCode?: string,
  studyStatus?: string,
  lastVisit?: {
    date: string,
    format: string,
    prepend: string
  },
  studyComplete?: boolean,
  completedVisits?: Array<number>,
  edcDisplayMethod?: number,
  prescribed?: boolean,
  labelForHistory: string,
  recentPrescriptionDate: string,
  statusTmpl?: string,
  locked?: string,
  isCdstApp?: boolean,
  warningAcceptance?: boolean,
  templateType?: string,
  medicalGuidelines?: string,
  source?: string,
  visitCode?: string
}

export enum TileType {
  'apps',
  'devices',
  'content'
}

export enum patientTile {
  'get',
  'share'
}

export enum Platform {
  'all',
  'ios',
  'android',
  'web',
  'study'
}

export enum PriceType {
  All,
  Free,
  'Free+',
  Paid
}

export enum StudyType {
  'eCRF',
  'ePRO',
  'Web',
  'HRS',
  'Connect'
}

export enum ContentType {
  video = 1,
  pdf,
  website,
  other
}
