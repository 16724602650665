import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbTypeaheadConfig } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Observable, of, OperatorFunction, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { UtilsService } from '../../../core/utils/utils.service';
import { FilterActions } from '../../../store/actions/filter.actions';
import { SearchActions, setSearcQuery } from '../../../store/actions/search.actions';
import { selectSearchConfig } from '../../../store/selectors/search.selectors';
import { userFacilityData } from '../../../store/selectors/user-data.selectors';
import { Platform } from '../../models/tile-data';
import { SearchService } from '../../services/search.service';

@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchBoxComponent implements OnDestroy {
  type;
  types = [];
  selectedItemType = {
    category: 'category',
    app: 'apps',
    webapp: 'apps',
    device: 'devices',
    content: 'content'
  };
  filterStateKey = {
    apps: 'appFilter',
    devices: 'deviceFilter',
    content: 'contentFilter'
  };
  searchConfigActions = {
    apps: SearchActions.appSearchConfig,
    devices: SearchActions.deviceSearchConfig,
    content: SearchActions.contentSearchConfig
  }
  typeChanged: boolean = false;
  platform = Platform;
  categories = {};
  @Output() close: EventEmitter<string> = new EventEmitter();
  private readonly destroy$ = new Subject();
  searchText;

  constructor(private searchService: SearchService, private utilService: UtilsService, config: NgbTypeaheadConfig,
    private router: Router, private store: Store, private cdr: ChangeDetectorRef) {
    config.focusFirst = false;
    config.editable = false;

    this.store.select(selectSearchConfig).pipe(
      takeUntil(this.destroy$)
    ).subscribe(searchConfig => {
      this.type = {
        key: searchConfig.type,
        value: searchConfig.title,
        categoryType: searchConfig.categoryType
      }
      this.searchText = searchConfig.searchQuery
      this.cdr.markForCheck();
    });

    this.store.select(userFacilityData).pipe(
      takeUntil(this.destroy$)
    ).subscribe(userFacilityData => {
      this.categories['apps'] = userFacilityData.appCategories;
      this.categories['devices'] = userFacilityData.deviceCategories;
      this.categories['content'] = userFacilityData.contentCategories;
      if (userFacilityData.hasApps) {
        this.types.push({
          key: 'apps',
          value: 'Apps',
          categoryType: 'Apps Categories > '
        });
      }
      if (userFacilityData.hasDevices) {
        this.types.push({
          key: 'devices',
          value: 'Devices',
          categoryType: 'Devices Categories > '
        });
      }
      if (userFacilityData.hasContent) {
        this.types.push({
          key: 'content',
          value: 'Digital Content',
          categoryType: 'Content Categories > '
        });
      }
      if (!this.type) {
        this.type = this.types[0];
      }
    });
  }
  search: OperatorFunction<string, readonly Object[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      tap(text => this.searchText = text),
      filter(text => !!text),
      switchMap(term =>

        this.searchService.search(term, this.type.key, this.utilService.generateCategoryTree(this.categories[this.type.key])).pipe(
          map(data => {
            let r = this.utilService.groupBy([...data[0], ...data[1]], 'type');
            return this.formatedList(r);
          }),
          catchError(() => {
            return of([]);
          }))
      )
    )

  formatter = (x: { name: string }) => {
    return x.name;
  };

  formatedList(data) {
    let result = [];
    if (data.category && data.category.length > 0) {
      result.push({ name: 'CATEGORIES', label: true });
      result.push(...data.category);
    }
    if (data.app && data.app.length > 0) {
      result.push({ name: 'APPS', label: true });
      result.push(...data.app);
    }
    if (data.webapp && data.webapp.length > 0) {
      result.push({ name: 'Web Apps', label: true });
      result.push(...data.webapp);
    }
    if (data.device && data.device.length > 0) {
      result.push({ name: 'Devices', label: true });
      result.push(...data.device);
    }
    if (data.content && data.content.length > 0) {
      result.push({ name: 'Digital Content', label: true });
      result.push(...data.content);
    }
    return result;
  }

  selectType(type) {
    if (this.type.key !== type.key) {
      this.typeChanged = true;
    }
    this.type = type;
  }

  selectedItem(event) {

    const item = event.item;
    if (this.typeChanged) {
      this.store.dispatch(this.searchConfigActions[this.type.key]());
      this.typeChanged = false;
    }
    if (item.type === this.selectedItemType.category) {
      this.store.dispatch(FilterActions.updateCategory({ category: { categoryId: item.idTree, type: this.filterStateKey[this.type.key] } }));
      this.router.navigate(['search']);
    } else {
      if (this.type.key === 'apps') {
        this.router.navigate([`/detail/${this.selectedItemType[item.type]}/${item.resourceId}/${item.platform}`]);
      } else {
        this.router.navigate([`/detail/${this.selectedItemType[item.type]}/${item.resourceId}`]);
      }
    }
  }

  onEnter(event) {
    const item = event.keyCode;
    if (item === 13) {
      this.store.dispatch(setSearcQuery({ searchQuery: event.target?.value }));
      this.router.navigate(['search']);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
