<div class="unsubscribe d-flex flex-column justify-content-center align-items-center m-auto">
  <div class="mb-4">
    <img [src]="'/assets/images/AppScript_logo_login_Tablet.png' | cdnurl"/>
  </div>
  <div class="mb-3">
    <img *ngIf="subscribed" [src]="'/assets/images/envelope-open-text_thankyou.png' | cdnurl" />
    <img *ngIf="!subscribed" [src]="'/assets/images/envelope-open-text_sorry.png' | cdnurl" />
  </div>
  <div class="mb-4 text-center message">
    {{subscribed ? 'Thank you for resubscribing!' : 'We’re sorry to see you go…' }}
  </div>
  <div class="mb-4 text-center sent">
    {{email ? email + ' has' : 'You have'}} {{subscribed ? 'been successfully resubscribed to AppScript.' : 'been successfully unsubscribed from AppScript.'}}
  </div>
  <div class="mt-4 text-center sent" *ngIf="!subscribed">
    If you clicked by mistake feel free to <a class="link decoration-none" (click)="subscribe()">Resubscribe here</a>
  </div>
  <div class="mt-4">
    <img [src]="'/assets/images/AppScript_IQVIA_logo_login_WEB.Tablet.png' | cdnurl" />
  </div>
</div>
