<div>
  <div class="modal-header">
    <div class="modal-title">Important Notice</div>
    <div class="modal-close">
      <i class="fas fa-times modal-close" (click)="close()"></i>
    </div>
  </div>
  <div class="p-4 first-run-warning">
    <p>
      Please acknowledge the message below:
    </p>
    <div class="p-4 mark">
      <p>
        CarePoint Apps within AppScript provide information identified with rules-based logic, to illuminate care gaps or risk
        factors
        supported by information whose relevance to a clinical decision is well understood.
      </p>
      <p>
        Information presented is based on underlying clinical documentation and established clinical guidelines. Missing or
        incorrect data within underlying documentation can lead to the presentation of incorrect information.
      </p>
      <p>
        This tool must not be relied upon for clinical decision making, and should only be used to supplement the independent
        clinical judgment of the treating healthcare professional.
      </p>
    </div>
    <p class="mt-2">
      By ticking the box below and clicking “Accept”, you acknowledge the message and agree to our <a class="link" (click)="termsofservice()">Terms of Service</a> and
      <a class="link" (click)="privaypolicy()">Privacy Policy</a>.
    </p>
    <div>
      <form [formGroup]="acceptForm">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="accept" name="accept" formControlName="accept">
          <label class="form-check-label text-left terms-privacy" for="accept">
            I acknowledge the message above and agree to the Terms of Service and Privacy Policy.
          </label>
        </div>
      </form>

    </div>
    <div class="d-flex justify-content-center mt-2">
        <button class="btn btn-outline-primary me-3 wide" (click)="close()">Decline</button>
        <button class="btn btn-primary wide" (click)="accept()" [disabled]="!acceptForm.value.accept">Accept</button>
    </div>
  </div>
  <app-tos #tos></app-tos>
  <app-privacy-policy #privacypolicy></app-privacy-policy>
</div>
