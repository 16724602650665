import { createAction, props } from '@ngrx/store';

export const loadFeaturedDevices = createAction(
  '[Dashboard Devices] Load Featured Devices'
);

export const featuredDevicesLoaded = createAction(
  '[Dashboard Devices] Featured Devices Loaded',
  props<{ featuredDevices: Array<any> }>()
);

export const loadFavoriteDevices = createAction(
  '[Dashboard Devices] Load Favorite Devices'
);

export const favoriteDevicesLoaded = createAction(
  '[Dashboard Devices] Favorite Devices Loaded',
  props<{ favoriteDevices: Array<any> }>()
);

export const remainingIds = createAction(
  '[Dashboard Devices] Remaining Ids',
  props<{ remainingIds: Array<any> }>()
);

export const selectedIds = createAction(
  '[Dashboard Devices] Selected Ids',
  props<{ selectedIds: Array<any> }>()
);

export const loadRecommendedDevices = createAction(
  '[Dashboard Devices] Load Recommended Devices',
  props<{ count: number }>()
);

export const loadMoreRecommendedDevices = createAction(
  '[Dashboard Devices] Load More Recommended Devices',
  props<{ count: number }>()
);

export const recommendedDevicesLoaded = createAction(
  '[Dashboard Devices] Recommended Devices Loaded',
  props<{ recommendedDevicesGroup: Array<any> }>()
);

export const moreRecommendedDevicesLoaded = createAction(
  '[Dashboard Devices] More Recommended Devices Loaded',
  props<{ moreRecommendedDevicesGroup: Array<any> }>()
);

export const addFavorite = createAction(
  '[Device Details] Add to Favorite',
  props<{ device: any }>()
);

export const removeFavorite = createAction(
  '[Device Details] Remove Favorite',
  props<{ device: any }>()
);

export const resetDevices = createAction(
  '[Prescribe] Reset Devices'
);

export const DevicesActions = {
  loadFeaturedDevices, featuredDevicesLoaded, loadFavoriteDevices, favoriteDevicesLoaded, remainingIds, selectedIds,
  loadRecommendedDevices, loadMoreRecommendedDevices, recommendedDevicesLoaded, moreRecommendedDevicesLoaded,
  addFavorite, removeFavorite, resetDevices
}




