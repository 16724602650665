import { Component, EventEmitter, Injectable, Input, OnInit, Output, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbModal, NgbModalConfig, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalConfig } from '../../models/modal.config';

@Component({
  selector: 'modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  styles: [`
    .modal-content {
      border-radius: 16px;
      border: 2px solid #2D445D;
    }
    .modal-header {
      background: #2D445D;
      color: white;
      height: 60px;
      align-items: center;
      border-radius: 16px 16px 0px 0px;
      margin: -2px;
      border: 2px solid #2D445D;
    }
    .modal-title{
      font-size: 20px;
    }
    .modal-close {
      color: white;
      cursor: pointer;
      font-size: 18px !important;
      opacity: 1;
      margin-left: auto;
    }
  `]
})
@Injectable()
export class ModalComponent implements OnInit {
  @Input() modalConfig: ModalConfig;
  @Output() closed: EventEmitter<void> = new EventEmitter();
  @ViewChild('modal') private modalContent: TemplateRef<ModalComponent>;
  private modalRef: NgbModalRef;

  constructor(private modalService: NgbModal, config: NgbModalConfig) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit(): void {
  }

  open(modalclass): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.modalRef = this.modalService.open(this.modalContent, { windowClass: modalclass })
      this.modalRef.result.then(resolve => {
        this.closed.emit();
      }, resolve)
    })
  }

  async close(): Promise<void> {
    if (this.modalConfig.shouldClose === undefined || (await this.modalConfig.shouldClose())) {
      const result = this.modalConfig.onClose === undefined || (await this.modalConfig.onClose())
      this.modalRef.close(result)
    }
  }

  // async dismiss(): Promise<void> {
  //   if (this.modalConfig.shouldDismiss === undefined || (await this.modalConfig.shouldDismiss())) {
  //     const result = this.modalConfig.onDismiss === undefined || (await this.modalConfig.onDismiss())
  //     this.modalRef.dismiss(result)
  //   }
  // }
}
