<div class="coach">
  <div class="close-overlay d-flex flex-column align-items-center">
    <i class="fas fa-times-circle float-right" role="button" (click)="hideCoachMark()"></i>
    <div>CLOSE</div>
  </div>
  <img class="coach-img switch" [src]="'/assets/images/Switch_Marks.png' | cdnurl" />
  <img class="coach-img check" [src]="'/assets/images/Check_Marks.png' | cdnurl" />
  <img class="coach-img get" [src]="'/assets/images/Get_Marks.png' | cdnurl"  />
  <img class="coach-img share" [src]="'/assets/images/Share_Marks.png' | cdnurl"  />
</div>
