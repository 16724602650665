import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserDataService } from '../../../core/services/user-data.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-first-run-modal',
  templateUrl: './first-run-modal.component.html',
  styleUrls: ['./first-run-modal.component.scss']
})
export class FirstRunModalComponent implements OnInit, OnDestroy {
  acceptForm;
  appData;
  private readonly destroy$ = new Subject();
  @ViewChild('tos') private tosContent;
  @ViewChild('privacypolicy') private privacyContent;

  constructor(private ngbActiveModal: NgbActiveModal, private fb: FormBuilder,
    private userDataService: UserDataService, private router: Router) { }

  ngOnInit(): void {
    this.acceptForm = this.fb.group({
      accept: [false]
    });
  }

  accept() {
    this.userDataService.acceptTos(this.appData.resourceId).pipe(
      takeUntil(this.destroy$)
    ).subscribe(data => {
      this.router.navigate(['/cpApps'], { state: { appData: this.appData } });
      this.close();
    });
  }

  close() {
    this.ngbActiveModal.close();
  }

  termsofservice() {
    this.tosContent.open();
  }
  privaypolicy() {
    this.privacyContent.open();
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
