import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, map, mergeMap, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { AppsService } from '../../shared/services/apps.service';
import { DashboardHelperService } from '../../shared/services/dashboard-helper.service';
import { AppsActions, selectedIds } from '../actions/apps.actions';
import { selectFavoriteApps, selectFeaturedApps, selectRecommendedApps, selectRemainingIds, selectSelectedIds } from '../selectors/apps.selectors';
import { userFacilityData } from '../selectors/user-data.selectors';
import { AppDataService } from '../../core/services/app-data.service';



@Injectable()
export class AppsEffects {

  count;
  ids;

  loadFeaturedApps$ = createEffect(
    () => this.actions$.pipe(
      ofType(AppsActions.loadFeaturedApps),
      mergeMap((action) => {
        return this.appsService.getFeaturedApps('', this.appDataService.getPatientInfo()?.patientFHIRID).pipe(
          map((featuredApps: any) => {
            return AppsActions.featuredAppsLoaded({ featuredApps });
          })
        )
      })
    )
  );

  loadFavoriteApps$ = createEffect(
    () => this.actions$.pipe(
      ofType(AppsActions.loadFavoriteApps),
      mergeMap((action) => {
        return this.appsService.getFavoriteApps('', this.appDataService.getPatientInfo()?.patientFHIRID).pipe(
          map((favoriteApps: any) => {
            return AppsActions.favoriteAppsLoaded({ favoriteApps });
          })
        )
      })
    )
  );

  loadRecommendedApps$ = createEffect(
    () => this.actions$.pipe(
      ofType(AppsActions.loadRecommendedApps),
      withLatestFrom(this.store.select(selectSelectedIds)),
      /* withLatestFrom(this.store.select(selectRecommendedApps)), */
      /* filter(apps => !apps[1]), */
      switchMap((data) => {
        this.count = data[0].count;
        return this.store.select(userFacilityData).pipe(
          tap(data => {
            this.ids = this.dashboardHelperService.generateCatIds(data && data.appCategories);
          }),
          mergeMap((action) => {
            if (data[1]) {
              return this.appsService.getRecommendedCategories('', data[1], this.appDataService.getPatientInfo()?.patientFHIRID).pipe(
                map(recommendedApps => {
                  return this.dashboardHelperService.generateRecomAppsGroup(data[1], recommendedApps);
                }),
                mergeMap((recommendedAppsGroup: any) => [
                  AppsActions.recommendedAppsLoaded({ recommendedAppsGroup })
                ])
              )
            } else {
              let { ids, idsArray } = this.dashboardHelperService.getRandomIds(this.ids, this.count);
              this.ids = idsArray;
              this.store.dispatch(selectedIds({ selectedIds: ids }));
              return this.appsService.getRecommendedCategories('', ids, this.appDataService.getPatientInfo()?.patientFHIRID).pipe(
                map(recommendedApps => {
                  return this.dashboardHelperService.generateRecomAppsGroup(ids, recommendedApps);
                }),
                mergeMap((recommendedAppsGroup: any) => [
                  AppsActions.recommendedAppsLoaded({ recommendedAppsGroup }),
                  AppsActions.remainingIds({ remainingIds: [...this.ids] })
                ])
              )
            }

          })
        )
      })
    )
  );

  loadMoreRecommendedApps$ = createEffect(
    () => this.actions$.pipe(
      ofType(AppsActions.loadMoreRecommendedApps),
      mergeMap((action) => {
        this.count = action.count;
        return this.store.select(selectRemainingIds).pipe(
          tap(data => {
            this.ids = [...data];
          }),
          filter(ids => ids.length > 0),
          take(1),
          mergeMap(action => {
            let { ids, idsArray } = this.dashboardHelperService.getRandomIds(this.ids, this.count);
            this.ids = idsArray;
            this.store.dispatch(selectedIds({ selectedIds: ids }));
            return this.appsService.getRecommendedCategories('', ids, this.appDataService.getPatientInfo()?.patientFHIRID).pipe(
              map(recommendedApps => {
                return this.dashboardHelperService.generateRecomAppsGroup(ids, recommendedApps);
              }),
              mergeMap((recommendedAppsGroup: any) => [
                AppsActions.moreRecommendedAppsLoaded({ moreRecommendedAppsGroup: recommendedAppsGroup }),
                AppsActions.remainingIds({ remainingIds: [...this.ids] })
              ])
            )
          })
        )
      })
    )
  );

  constructor(private actions$: Actions, private appsService: AppsService, private store: Store,
    private dashboardHelperService: DashboardHelperService, private appDataService: AppDataService) { }

}
