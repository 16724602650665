import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { AppDataService } from '../../../core/services/app-data.service';
import { AuthService } from '../../../core/services/auth.service';
import { HeaderItems } from '../../models/header-items';
import { ModalConfig } from '../../models/modal.config';
import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'app-tos',
  templateUrl: './tos.component.html',
  styleUrls: ['./tos.component.scss']
})
export class TosComponent implements OnInit {

  @ViewChild('tos') private tosContent: ModalComponent;
  @ViewChild('container') container: ElementRef;
  private apiTermsofService = "https://qaapi.appscript.net/api/v2/PrivacyPolicy/ProviderTerms?countrycode=ae";
  tosdata: any;
  constructor(private appservice: AppDataService) { }

  public modalConfig: ModalConfig = {
    modalTitle: 'Terms of Service',
    closeButtonLabel: 'Close',
    hideDismissButton: () => {
      return true;
    },
    onClose: () => {
      return true
    },
  }
  ngOnInit(): void {
  }

  open() {
    this.appservice.getTos().subscribe(data => {
      if (data) {
        this.tosdata = data;
        this.tosContent.open("tosclass");
      }
    });
  }
}
