import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { AuthService } from '../../core/services/auth.service';
import { LoginService } from '../../core/services/login.service';
import { showChangePasswordModal, showInfoModal } from '../../store/actions/app-data.actions';
import { PrescribeActions } from '../../store/actions/prescribe.actions';
import { selectTotlaCount } from '../../store/selectors/prescribe.selectors';
import { selectLogoUrl } from '../../store/selectors/user-data.selectors';
import { HeaderActions } from '../models/header-items';
import { environment } from 'projects/prescriber/src/environments/environment';
import { AppDataService } from '../../core/services/app-data.service';
import { setInitialState } from '../../store/actions/filter.actions';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AboutModalComponent } from '../components/about-modal/about-modal.component';

@Injectable({
  providedIn: 'root'
})
export class HeaderHelperService {
  buildversion;
  private rd: Renderer2;
  private readonly sendToPateint$ = new Subject();
  private readonly prescribe$ = new Subject();

  constructor(private appdataService: AppDataService, private loginService: LoginService, private authService: AuthService, private router: Router,
    private store: Store, private rendererFactory: RendererFactory2, private modalService: NgbModal) {
    this.rd = rendererFactory.createRenderer(null, null);
  }

  handleUserAction(event) {
    if (HeaderActions[event] && this[HeaderActions[event]]) {
      this[HeaderActions[event]]();
    }
  }

  logIn() {
    this.router.navigate(['/login']);
    let countryCode = sessionStorage.getItem('countrycode');
    (<any>window).gtag('config', environment.ga, {
      'page_title': 'Login',
      'page_path': countryCode + '/login'
    });
  }

  logOut() {
    this.loginService.logout().subscribe(response => {
      this.authService.resetGlobal();
      sessionStorage.clear();
      this.router.navigate(['/login']);
      (<any>window).gtag('config', environment.ga, {
        'page_title': 'Logout',
        'page_path': '/logout'
      });
    });
  }

  navigateToHistory() {
    this.router.navigate(['/patients']);
    (<any>window).gtag('config', environment.ga, {
      'page_title': 'Patients',
      'page_path': '/patients/list'
    });
  }

  navigateToSearch() {
    this.router.navigate(['/search']);
  }

  navigateToHome() {
    this.router.navigate(['/dashboard']);
  }

  aboutModal() {
    const mediaQuery = window.matchMedia('(max-width: 769px)');
    if (mediaQuery.matches) {
      this.router.navigate(['/footer']);
    } else {
      let modalRef = this.modalService.open(AboutModalComponent, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
      setTimeout(() => {
        modalRef.componentInstance.aboutModal();
      }, 0);
    }
  }

  infoModal() {
    let type = this.appdataService.getResourceType() ? this.appdataService.getResourceType() : "apps";
    this.router.navigate(['/dashboard/' + type]);
    this.store.dispatch(setInitialState());
    this.store.dispatch(showInfoModal({ InfoModal: true }));
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  getBagTotal() {
    return this.store.select(selectTotlaCount);
  }
  changePasswordModel() {
    this.store.dispatch(showChangePasswordModal({ changePasswordModal: true }));
  }
  navigateToPrescribe() {
    this.store.dispatch(PrescribeActions.navigateToPrescribe());
  }
  getLogoUrl() {
    return this.store.select(selectLogoUrl);
  }

  sendToPatient() {
    this.sendToPateint$.next();
  }

  sendActionTrigger() {
    return this.sendToPateint$.asObservable();
  }

  prescribe() {
    this.prescribe$.next();
  }

  prescribeActionTrigger() {
    return this.prescribe$.asObservable();
  }

  postToEcrfFrame() {
    let ecrfFrame = this.rd.selectRootElement('iframe');
    ecrfFrame.contentWindow.postMessage('{"event": "answer-requested"}', '*');
  }
}
