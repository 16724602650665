import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[copy-clipboard]'
})
export class CopyClipboardDirective {
  @Input('copy-clipboard') payload: string;
  @Output('copied') copied: EventEmitter<string> = new EventEmitter<string>();

  @HostListener('click', ['$event'])
  public onClick(event: MouseEvent): void {

    event.preventDefault();
    if (!this.payload)
      return;

    // let listener = (e: ClipboardEvent) => {
    //   let clipboard = e.clipboardData || window['clipboardData'];
    //   clipboard.setData('text', this.payload.toString());
    //   e.preventDefault();      
    // };
 
      let textarea = document.createElement('textarea');
      document.body.appendChild(textarea);
      textarea.value = this.payload;
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);
      this.copied.emit(this.payload);


    //if (!navigator.clipboard) {
      // document.addEventListener('copy', listener, false);
      // document.execCommand('copy');
      // document.removeEventListener('copy', listener, false);
    // } else {
    //   navigator.clipboard.writeText(this.payload).then(() => this.copied.emit(this.payload));
    // }
  }

}
