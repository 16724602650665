import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-copy-code',
  templateUrl: './copy-code.component.html',
  styleUrls: ['./copy-code.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CopyCodeComponent implements OnInit {
  @Input() code: any;
  @Input() btnText: string;
  @Input() editable: boolean = false;
  copied: boolean;
  constructor() { }

  ngOnInit(): void {
  }

}
