import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from 'projects/prescriber/src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class CryptoEncryptionService {
 key: string;
  


  constructor() {
    this.key =   environment.encryptionKey;
  }

  /**
   * Encrypts the given plain text using AES encryption algorithm.
   * 
   * @param plainText - The plain text to be encrypted.
   * @returns The encrypted text in Base64 format.
   */
  encrypt(plainText: string): string {
    if(!plainText) {
      return null;
    }
    // Convert the key to a WordArray object
    const keyWordArray = CryptoJS.enc.Utf8.parse(this.key);
    // Use the first 16 characters of the key as the IV (initialization vector)
    const ivWordArray = CryptoJS.enc.Utf8.parse(this.key.substring(0, 16));

    // Encrypt the plain text using AES encryption with CBC mode and PKCS7 padding
    const encrypted = CryptoJS.AES.encrypt(
      plainText,
      keyWordArray,
      { iv: ivWordArray, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }
    );

    // Return the encrypted text in Base64 format
    return encrypted.toString();
  }

  /**
 * Decrypts the given encrypted text using AES encryption algorithm.
 * 
 * @param encryptedText - The encrypted text in Base64 format.
 * @returns The decrypted plain text.
 */
  decrypt(encryptedText): string {
    if(!encryptedText) {
      return null;
    }
    // Convert the key to a WordArray object
    const keyWordArray = CryptoJS.enc.Utf8.parse(this.key);
    // Use the first 16 characters of the key as the IV (initialization vector)
    const ivWordArray = CryptoJS.enc.Utf8.parse(this.key.substring(0, 16));
    // Convert the encrypted text from Base64 to a WordArray object
    const encryptedWordArray = CryptoJS.enc.Base64.parse(encryptedText);


    // Decrypt the encrypted text using AES decryption with CBC mode and PKCS7 padding
    const decrypted = CryptoJS.AES.decrypt(
      { ciphertext: encryptedWordArray },
      keyWordArray,
      { iv: ivWordArray, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }
    );

    //We can use below method to decrypt the text if we dont want to convert the encrypted text to base64
    // var decrypted = CryptoJS.AES.decrypt(encryptedText, keyWordArray, {
    //   iv: ivWordArray,
    //   mode: CryptoJS.mode.CBC,
    //   padding: CryptoJS.pad.Pkcs7
    // });

    // Convert the decrypted WordArray object to a UTF-8 string
    return CryptoJS.enc.Utf8.stringify(decrypted); // Return the decrypted text
  }
}