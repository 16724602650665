<div class="container">
   <div class="row mt-3">  
      <div class="col-lg-9">
        <p>Introducing IQVIA's Digital Health Library on AppScript®, a platform designed especially for IQVIANs by IQVIANs.</p>
        <p>In 2013, the IQVIA Institute noted growing evidence that certain apps had a positive impact on health behaviors. The surge in the volume of health apps made it challenging to find the good ones.</p>
        <p>Your AppScript Digital Health Library makes it easy!</p>
      </div>
      <div class="col-lg-3">
        <img class="chart" [src]="'/assets/images/donut-chart.jpg' | cdnurl" >
      </div>
   </div>
</div>

<section class="custom-bg">
   <div class="container">
   <div class="row mt-3">
     <div class="col-lg-2 col-md-5 col-6">
        <img class="track" [src]="'/assets/images/AppScript_Track.jpg' | cdnurl">
     </div>
     <div class="col-lg-10 col-md-7 col-6 pt-2 learnmore ">
        <p>Apps are reviewed through a rigorous and comprehensive process across 6 categories and over 70 objective metrics to reach a proprietary quality score,
            namely the AppScript Score.</p>
        <p>There are currently 20+ apps in the digital health library available at no cost to IQVIA employees.</p>
        <p></p>
     </div>
   </div>
   </div>
 </section>  


<div class="container">
   <div class="row mt-4">
    <div class="col-lg-9">
        <p>Depending on your home country, the platform may include three sections - apps, devices, and content - where you can review and download apps, purchase devices, and gain health knowledge.</p>
        <p>We invite you to explore AppScript to find personalized tools to support your health goals.</p>
    </div>
    <div class="col-lg-3">
        <img class="preview" [src]="'/assets/images/AppScript_preview_right.png' | cdnurl" >
    </div>

   </div>
</div>   


   
