import { createAction, props } from '@ngrx/store';

export const addToBag = createAction(
  '[Dashboard/Prescribe] Add to Bag',
  props<{ item: any }>()
);

export const clearBag = createAction(
  '[Dashboard] Clear Bag'
);

export const removeItem = createAction(
  '[Bag/Prescribe] Remove Item from Bag',
  props<{ id: any }>()
);

export const navigateToPrescribe = createAction(
  '[Bag/Header] Navigate to Header'
);

export const setPrescribeLabel = createAction(
  '[Prescribe] Set the Label',
  props<{ label: string }>()
);

export const PrescribeActions = { addToBag, clearBag, removeItem, navigateToPrescribe, setPrescribeLabel };



