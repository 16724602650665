import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppDataService } from '../services/app-data.service';

@Injectable({
  providedIn: 'root'
})
export class HistoryGuard implements CanActivate {
  constructor(private appDataService: AppDataService, private router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let patientInfo = this.appDataService.getPatientInfo();
    const scorePath = route['_routerState'].url;
    return this.appDataService.getAppData().pipe(
      map(data => {
        if (scorePath!="/patients/scoreDetails" && (data.emis || data.smartLaunch) && patientInfo.patientFHIRID) {
          this.router.navigate(['patient', patientInfo.patientFHIRID]);
          return false;
        } else {
          return true;
        }
      })
    );
  }

}
