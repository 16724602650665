<modal #infoModal [modalConfig]="modalConfig" (closed)="onClose()">
    <div class="col-md-12 popup-overlay active" tabindex="-1" role="dialog">
        <div class="popup-content active" role="document">
            <div class="info-content">
              <h1>Welcome to IQVIA's</h1>
              <h3>Digital Health Library on AppScript <span class="registered">®</span></h3>
              <p>Introducing the IQVIA Digital Health Library on AppScript <span class="registered_content">®</span>
                , a platform designed especially for IQVIANs by IQVIANs. Discover high-quality, curated health apps to assist you on your health journey.
              </p>
              <div>
              <div class="form-group col-md-12" align="center">
              <button type="button" id="info-learnmore" class="col-md-4 col-xs-4 col-sm-6 col-xs-12 info-learnmore" (click)="learnmore('learnmore')">Learn More</button>
              <button type="button" id="info-getstarted" class="col-md-4 col-xs-4 col-sm-6 col-xs-12 info-getstarted" data-dismiss="modal" (click)="showCoachMarks()">Get started</button>
              </div>
              </div>
          </div>
        </div>
      </div>
</modal>

