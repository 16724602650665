import { ActionReducer, INIT, UPDATE } from "@ngrx/store";
import { appsInitialState } from "./apps.reducer";
import { contentInitialState } from "./content.reducer";
import { devicesInitialState } from "./devices.reducer";
import { State } from "./index";
import { userInitialState } from "./user.reducer";

export const hydrationMetaReducer = (
  reducer: ActionReducer<State>
): ActionReducer<State> => {
  return (state, action) => {
    if (action.type == '[login/Logout] Reset') {
      state = {} as State;
      sessionStorage.removeItem('state');
      return reducer(state, action);
    }
    if (action.type === INIT || action.type === UPDATE) {
      const storageValue = sessionStorage.getItem('state');
      if (storageValue) {
        try {
          return JSON.parse(storageValue);
        } catch {
          sessionStorage.removeItem('state');
        }
      }
    }
    const nextState = reducer(state, action);
    updateSessionState({ ...nextState });
    return nextState;
  };
};

const updateSessionState = (state) => {
  let sessionState = { ...state };
  sessionState.apps = appsInitialState;
  sessionState.devices = devicesInitialState;
  sessionState.content = contentInitialState;
  sessionState.user = userInitialState;
  sessionStorage.setItem('state', JSON.stringify(sessionState));
};
