import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppsState } from '../reducers/apps.reducer';

export const selectAppsState = createFeatureSelector<AppsState>('apps');

export const selectFeaturedApps = createSelector(selectAppsState, state => state.featuredApps);

export const selectFavoriteApps = createSelector(selectAppsState, state => state.favoriteApps);

export const selectRemainingIds = createSelector(selectAppsState, state => state.remainingIds);

export const selectSelectedIds = createSelector(selectAppsState, state => state.selectedIds);

export const selectRecommendedApps = createSelector(selectAppsState, state => state.recommendedApps);

