import {
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer
} from '@ngrx/store';
import { environment } from '../../../environments/environment';
import { AppDataReducer } from './app-data.reducer';
import { appsReducer } from './apps.reducer';
import { contentReducer } from './content.reducer';
import { devicesReducer } from './devices.reducer';
import { filterReducer } from './filter.reducer';
import { hydrationMetaReducer } from './hydration.reducer';
import { prescribeReducer } from './prescribe.reducer';
import { searchReducer } from './search.reducer';
import { userReducer } from './user.reducer';


export interface State {
  user,
  filter,
  appData,
  search,
  apps,
  devices,
  content,
  prescribe
}

export const reducers: ActionReducerMap<State> = {
  user: userReducer,
  filter: filterReducer,
  appData: AppDataReducer,
  search: searchReducer,
  apps: appsReducer,
  devices: devicesReducer,
  content: contentReducer,
  prescribe: prescribeReducer
};


export const metaReducers: MetaReducer<State>[] = !environment.production ? [hydrationMetaReducer] : [hydrationMetaReducer];
