import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { changePasswordDetails } from '../../../core/models/user-details';
import { AuthService } from '../../../core/services/auth.service';
import { ForgotpasswordService } from '../../../core/services/forgotpassword.service';
import { ToastService } from '../../services/toast.service';
import { setPasswordExpiry, showChangePasswordModal } from '../../../store/actions/app-data.actions';
import { ModalConfig } from '../../models/modal.config';
import { ConfirmPasswordValidator } from '../../validators/confirm-password.validator';
import { createPasswordStrengthValidator } from '../../validators/password-strength.validator';
import { ModalComponent } from '../modal/modal.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';



@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit, OnDestroy {

  @ViewChild('changePassword') private changePasswordContent: ModalComponent;
  @ViewChild("successchangepassword") changetPasswordToast;
  changePasswordForm!: FormGroup;
  submitted: boolean;
  showPswrdStrength: boolean = false;
  changemodel:changePasswordDetails;
  userid: string[] = [];
  private readonly destroy$ = new Subject;
  constructor(private store: Store, private formbuilder: FormBuilder,
    private authService: AuthService, private changeService: ForgotpasswordService,
    private toastService: ToastService) {
      this.changemodel = {
        confirmPassword: "",
        newPassword: "",
        oldPassword: ""
      }

  }
  public modalConfig: ModalConfig = {
    modalTitle: 'Change Password',
    closeButtonLabel: 'Cancel',
    hideCloseButton: () => {
      return true;
    },
  };


  ngOnInit(): void {
    this.userid = ["contact@imshealth.com"];
    this.changePasswordForm = this.formbuilder.group({
      currentPassword: ["", [Validators.required, Validators.minLength(8)]],
      newPassword: ["", [Validators.required, createPasswordStrengthValidator(this.userid)]],
      confirmPassword: ["", Validators.required]
    }, {
      validator: ConfirmPasswordValidator("newPassword", "confirmPassword")
    });
  }


  get f() { return this.changePasswordForm.controls; }
  get ErrorMessage() {
    return this.authService.errorMessage;
  }

  get ShowErrorMessage() {
    return this.authService.showError;
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid

    if (this.changePasswordForm.invalid) {
      return;
    }
    this.changemodel.oldPassword=this.changePasswordForm.value.currentPassword;
    this.changemodel.newPassword=this.changePasswordForm.value.newPassword;
    this.changemodel.confirmPassword=this.changePasswordForm.value.confirmPassword;
    this.changeService.changepassword(this.changemodel).pipe(
      takeUntil(this.destroy$)
    ).subscribe(response => {
      this.toastService.show(this.changetPasswordToast, { type: 'success', delay: 5000 });
      this.store.dispatch(setPasswordExpiry({ passwordExpiry: '90' }));
      this.onClosePassword();
    }, error => {
      if (error && error.description1) {
        this.authService.showErrorMessage(true, error.description);
      } else if (error && error.message) {
        this.authService.showErrorMessage(true, error.message);
      }
      else {
        this.authService.showErrorMessage(true, "An error occured during reset Password. Please contact the Administrator.");
      }

    });
  }

  changePasswordModel() {
    this.authService.showErrorMessage(false, "")
    this.changePasswordContent.open("changepasswordclass");

  }

  onClosePassword() {
    this.store.dispatch(showChangePasswordModal({ changePasswordModal: false }));
    this.changePasswordContent.close();
    this.authService.showErrorMessage(false, "")
    this.changePasswordForm.reset();
    this.submitted = false;
  }

  ngOnDestroy(): void {
    this.store.dispatch(showChangePasswordModal({ changePasswordModal: false }));
    this.destroy$.next();
    this.destroy$.complete();
  }
}
