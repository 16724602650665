import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class UserDataService {
  getExternalLink: any;
  constructor(private http: HttpClient, private configureService: ConfigService) { }

  getUserData() {
    return this.http.get(this.configureService.appconfig.apiurl + 'user/UserData');
  }

  getUserFacilityData(facilityId: string, patientID?) {
    let params = new HttpParams();
    params = params.set('facilityId', facilityId);
    if (patientID) {
      params = params.append('patientID', patientID);
    }
    return this.http.get(this.configureService.appconfig.apiurl + 'user/UserFacilityData', {
      params
    });
  }

  getUserAuditData(trackresource) {
    return this.http.post(this.configureService.appconfig.apiurl + 'user/TrackUserActivity', trackresource);
  }

  acceptTos(appId) {
    return this.http.put(this.configureService.appconfig.apiurl + 'user/terms-of-service-tools', null, {
      params: {
        cdstAppID: appId,
        accept: 'true'
      }
    });
  }

}
