<div class="password-tooltip p-3">
  <div>
    <div class="prescribe-label pb-2">
      <strong>Password must meet the following requirements:</strong>
    </div>
    <div class="p-y-1" [ngClass]="errors?.upperCase ? 'invalid': 'valid'">      
      <span class="fas" [ngClass]="errors?.upperCase ? 'fa-times-circle': 'fa-check-circle'"></span>
      <span class="pl-3">At least one upper case letter</span>
    </div>
    <div class="p-y-1" [ngClass]="errors?.lowerCase ? 'invalid': 'valid'">      
      <span class="fas" [ngClass]="errors?.lowerCase ? 'fa-times-circle': 'fa-check-circle'"></span>
      <span class="pl-3">At least one lower case letter</span>
    </div>
    <div class="p-y-1" [ngClass]="errors?.numeric ? 'invalid': 'valid'">
      <span class="fas" [ngClass]="errors?.numeric ? 'fa-times-circle': 'fa-check-circle'"></span>
      <span class="pl-3">At least one number</span>
    </div>
    <div class="p-y-1" [ngClass]="errors?.specialChar ? 'invalid': 'valid'">
      <span class="fas" [ngClass]="errors?.specialChar ? 'fa-times-circle': 'fa-check-circle'"></span>
      <span class="pl-3">At least one special character:- () . & @ $ ? ' # , / " +</span>
    </div>
    <div class="p-y-1" [ngClass]="errors?.containUserName ? 'invalid': 'valid'">
      <span class="fas" [ngClass]="errors?.containUserName ? 'fa-times-circle': 'fa-check-circle'"></span>
      <span class="pl-3">Not contain any part of a user's name or User ID</span>
    </div>
    <div class="p-y-1" [ngClass]="errors?.commonWord ? 'invalid': 'valid'">
      <span class="fas" [ngClass]="errors?.commonWord ? 'fa-times-circle': 'fa-check-circle'"></span>
      <span class="pl-3">Not contain a common word longer than three characters</span>
    </div>
    <div class="p-y-1" [ngClass]="errors?.minlength ? 'invalid': 'valid'">
      <span class="fas" [ngClass]="errors?.minlength ? 'fa-times-circle': 'fa-check-circle'"></span>
      <span class="pl-3">Be at least 8 characters</span>
    </div>
  </div>
</div>
